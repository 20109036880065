import React from 'react';

import { useAppSelector } from 'hooks';

import classes from './profile-cover-image.module.scss';

export const ProfileCoverImage = () => {
  const {
    profileData,
    userChanges,
    userData,
    // showPreview
  } = useAppSelector((state) => state.profileStore);
  const user = Object.assign(
    { ...profileData },
    // showPreview &&
    { ...userData, ...userChanges }
  );

  return (
    <>
      {user.cover_image ? (
        <img src={user.cover_image} className={classes.cover} alt='' />
      ) : (
        <div className={classes.cover} />
      )}
    </>
  );
};
