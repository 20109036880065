import React from 'react';
import { TwitterShareButton } from 'react-share';

import { CopyIcon, Instagram, Twitter, ShareIcon } from 'components/icons';
import { utils } from 'libs';

import classes from './share.module.scss';

interface IProps {
  text?: string;
  open: boolean;
  setOpen: () => void;
  className?: string;
}

export const Share = ({ text, open, setOpen, className }: IProps) => {
  const url = window.location;
  const options = [
    {
      label: (
        <TwitterShareButton
          url={window.location.toString()}
          title={'Check out this NFT by'}
          via='BabyBNBTiger'
        >
          Twitter
        </TwitterShareButton>
      ),
      icon: <Twitter />,
    },
    {
      label: 'Instagram',
      icon: <Instagram />,
      onClick: () => (window.location.href = 'https://www.instagram.com/'),
    },
    {
      label: 'Copy Link',
      icon: <CopyIcon />,
      onClick: () => {
        utils.copyToClipboard(url.toString());
        setOpen();
      },
    },
  ];

  return (
    <div className={`${classes.share__wrap} ${className}`}>
      <button onClick={setOpen} className={classes.share__button}>
        <ShareIcon /> {text}
      </button>
      {open && (
        <div className={classes.share__dropdown}>
          {options.map(({ icon, label, onClick }, i) => (
            <div
              className={classes.share__dropdown_item}
              onClick={onClick}
              key={i}
            >
              {icon}
              {label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
