import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from 'hooks';
import { Tabs, Loader, Info, ProductCard, Share } from 'components/ui';
import { INft, ProfileNftTypes, getProfileNft } from 'store/nft-list';
import { clearProfileData } from 'store/profile';

import { tabs } from './config';
import classes from './profile-content.module.scss';
import { setDraftMeta } from 'store/nft-manager';

interface IPropsMeta {
  IPFSHash: string | null;
  fullPath: string | null;
  name?: string;
  genre?: string;
  genreId?: number;
  description?: string;
}

export const ProfileContent = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();
  const [isReport, setIsReport] = useState(false);
  const [isShare, setIsShare] = useState(false);
  const { profileData, address, isLoading } = useAppSelector(
    (state) => state.profileStore
  );
  const { profileNftList, isLoading: isNftListLoading } = useAppSelector(
    (state) => state.nftListStore
  );
  const [activeStatus, setActiveStatus] = useState<any>(null);
  const isOwner = params.address === address;
  const { IPFSHash, step } = useAppSelector((state) => state.NFTManagerStore);

  useEffect(() => {
    return () => {
      dispatch(clearProfileData());
    };
  }, []);

  useEffect(() => {
    if (step.index !== 3) {
      return;
    }

    if (IPFSHash) navigate(`/create-nft?step=${step.index}`);
  }, [IPFSHash]);

  if (!profileData || (!address && isLoading)) {
    return null;
  }
  const handleChange = (value: ProfileNftTypes) => {
    const find = tabs.find(({ state }) => state === value);

    if (!find || isLoading) {
      return;
    }

    setActiveStatus(find);

    dispatch(
      getProfileNft({
        key: value,
        author: profileData?.wallet,
      })
    );
  };

  const handleAction = (url: string) => {
    navigate(url);
  };

  const handlerAddDescription = (obj: IPropsMeta) => {
    dispatch(
      setDraftMeta({
        ...obj,
        step: {
          index: 3,
          title: 'Add description',
          btnLabel: 'Mint NFT',
        },
      })
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.tabs_wrapper}>
        <Tabs
          disabled={isLoading}
          items={tabs}
          onChange={handleChange}
          isOwner={isOwner}
          isCreator={profileData.is_creator}
        />
        <Share
          setOpen={() => {
            setIsShare(!isShare);
            setIsReport(false);
          }}
          open={isShare}
          text='Share'
          className={classes.share}
        />
      </div>
      <Loader loading={isNftListLoading}>
        {profileNftList.length > 0 && !isLoading && (
          <div className={classes.list}>
            <div className={classes.products}>
              {profileNftList.map((nft: INft, index) => (
                <div className={classes.card} key={index}>
                  <ProductCard
                    id={nft.id}
                    canListOrOpen={nft.canListOrOpen}
                    author={{
                      username: nft.author.username,
                      image: nft.author.image,
                    }}
                    nftId={nft.nftId}
                    isOwner={nft.is_owner}
                    isCreator={profileData.is_creator}
                    isMinted={nft.is_mark_minted}
                    isOpenBox={nft.isOpenBox}
                    image={nft.image}
                    IPFSHash={nft.IPFSHash}
                    name={nft.name}
                    price={nft.price}
                    status={nft.status}
                    withActions={true}
                    onHandleAction={handleAction}
                    auction={nft.auction}
                    genre={nft.genre}
                    genreId={nft.metadata ? nft.metadata.genre : null}
                    description={nft.description}
                    onHandlerAddDescription={handlerAddDescription}
                    buttonsBottom
                    withStatus
                  />
                </div>
              ))}
            </div>
          </div>
        )}
        {!profileNftList.length && !isLoading && !isNftListLoading && (
          <div className={classes.empty}>
            <Info
              title={activeStatus?.emptyTitle}
              text={activeStatus?.emptyText}
              buttonLink={activeStatus?.buttonLink}
              buttonText={activeStatus?.buttonText}
              learnMore={activeStatus?.learnMore}
            />
          </div>
        )}
      </Loader>
    </div>
  );
};
