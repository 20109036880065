import { Currency } from './../currency/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { utils } from 'libs';

import { IState, IAuctionItem } from './interface';
import { getAuctions, getDelayedAuction, getUpcomingAuction } from './actions';

const initialState: IState = {
  auctions: [],
  count: 0,
  isLoading: false,
  upcomingAuction: [],
  delayedAuction: [],
};

export const auction = createSlice({
  name: 'auctionStore',
  initialState,
  reducers: {
    clearState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAuctions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAuctions.fulfilled, (state, action: PayloadAction<any>) => {
        const { results = [] } = action.payload;
        state.count = action.payload.count;

        state.isLoading = false;
        state.auctions = results
          ?.sort(
            (a: IAuctionItem, b: IAuctionItem) => +b.time_end! - +a.time_end!
          )
          ?.reduce((acc: any, item: IAuctionItem) => {
            acc.push({
              name: item.nft.name,
              image: item.nft.image ? item.nft.image.compressed : null,
              metadata: item.nft.metadata,
              description: item.nft.description,
              genre: item.nft.genre,
              nftId: item.nft.nft_id,
              id: item.nft.id,
              status: item.nft.status,
              price: item.nft.price,
              author: {
                image: utils.getImage(item.nft.author.image),
                username: item.nft.author.username,
                wallet: item.nft.author.wallet,
              },
              owner: {
                image: utils.getImage(item.nft.owner.image),
                username: item.nft.owner.username,
                wallet: item.nft.owner.wallet,
              },
              auction: {
                status: item.is_active,
                currency: item.currency,
                isMyLastBet: item.my_bid
                  ? item.my_bid?.bidder.wallet === item.max_bid?.bidder.wallet
                  : false,
                endTimestamp: item.time_end,
                myLastBet: Number(item.my_bid?.amount || 0),
                lastBet: Number(item.max_bid?.amount || 0),
                auctionOwner: item.auction_owner,
                isLastAuctionForNFt: item?.is_last_for_nft,
              },
            });

            return acc;
          }, []);
      })
      .addCase(getAuctions.rejected, (state) => {
        state.isLoading = false;
      })

      //upcoming auc
      .addCase(getUpcomingAuction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getUpcomingAuction.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;

          state.upcomingAuction = action.payload.reduce(
            (acc: any, item: IAuctionItem) => {
              acc.push({
                name: item.nft.name,
                image: item.nft.image ? item.nft.image.compressed : null,
                metadata: item.nft.metadata,
                description: item.nft.description,
                genre: item.nft.genre,
                nftId: item.nft.nft_id,
                id: item.nft.id,
                status: item.nft.status,
                price: item.nft.price,
                nft: item.nft,
                author: {
                  image: utils.getImage(item.nft.author.image),
                  username: item.nft.author.username,
                  wallet: item.nft.author.wallet,
                },
                owner: {
                  image: utils.getImage(item.nft.owner.image),
                  username: item.nft.owner.username,
                  wallet: item.nft.owner.wallet,
                },
                auction: {
                  status: item.is_active,
                  currency: item.currency,
                  isMyLastBet: item.my_bid
                    ? item.my_bid?.bidder.wallet === item.max_bid?.bidder.wallet
                    : false,
                  endTimestamp: item.time_end,
                  myLastBet: item.my_bid?.amount,
                  lastBet: item.max_bid?.amount,
                  auctionOwner: item.auction_owner,
                },
              });

              return acc;
            },
            []
          );
        }
      )
      .addCase(getUpcomingAuction.rejected, (state) => {
        state.isLoading = false;
      })

      //upcoming auc
      .addCase(getDelayedAuction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getDelayedAuction.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.delayedAuction = action.payload;
        }
      )
      .addCase(getDelayedAuction.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { clearState } = auction.actions;
