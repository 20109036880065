import React, { useState } from 'react';
import cn from 'classnames';

import { useAppDispatch, useAppSelector, useMedia } from 'hooks';
import { utils, moment } from 'libs';
import { Copy, ReportModal } from 'components/containers';
import {
  Button,
  Share,
  Loader,
  FollowingButton,
  FollowsInfo,
} from 'components/ui';

import { IProps } from './interface';

import classes from './profile-card.module.scss';
import { ArtIcon, CollectionsIcon } from 'components/icons';
import { setOpenConnector } from 'store/profile';
import { getImage } from 'libs/utils/utils';

export const ProfileCard = (props: IProps) => {
  const { isMobile, isDesktop } = useMedia();
  const [isReport, setIsReport] = useState(false);
  const [isShare, setIsShare] = useState(false);
  const [openReport, setOpenReport] = useState(false);
  const { address } = useAppSelector((state) => state.profileStore);
  const dispatch = useAppDispatch();

  const handlerSubscribe = () => {
    return !address
      ? dispatch(setOpenConnector(true))
      : props.onHandleSubscribe({
          action: props.isFollowing
            ? 'delete'
            : !props.isFollowing
            ? 'add'
            : null,
          authorId: props.authorId,
          wallet: props.authorWallet,
        });
  };

  const ActionButton = () => {
    return props.isOwner ? (
      <Button
        label={'Edit profile'}
        variant={'outlined'}
        size={'small'}
        type={'primary'}
        onClick={props.onClickEdit}
        // disabled={props.isEditDisabled}
        disabled={false}
      />
    ) : (
      <FollowingButton
        onClick={handlerSubscribe}
        isFollow={props.isFollowing}
        // disabled={props.subscribeProcess}
        disabled={false}
      />
    );
  };

  const Desktop = () => {
    return (
      <div className={classes.card} id='yyy'>
        <Loader loading={props.loading}>
          {!props.loading && (
            <>
              <div className={classes.image__wrapper}>
                {props.image ? (
                  <img
                    src={props.image}
                    alt='card-image'
                    className={classes.image}
                  />
                ) : (
                  <img
                    src={getImage('')}
                    alt='card-image'
                    className={classes.img_plug}
                  />
                )}
              </div>

              <Copy
                text={utils.shorter(props.address)}
                copyText={props.address}
              />

              {props.name && props.isOwner && (
                <div className={classes.name}>{props.name}</div>
              )}

              {props.userName && props.isOwner && (
                <div className={classes.nick_name}>@{props.userName}</div>
              )}

              <div className={classes.status_wrapper}>
                <div className={classes.status}>
                  {props.isCreator ? (
                    <>
                      <ArtIcon />
                      <div className={classes.nick_name}>Author</div>
                    </>
                  ) : (
                    <>
                      <CollectionsIcon />
                      <div className={classes.nick_name}>Сollector</div>
                    </>
                  )}
                </div>

                {/* {!props.isCreator && props.isOwner && (
                  <button
                    onClick={props.onBecomeAnArtist}
                    className={classes.become_artist}
                  >
                    Become an artist
                  </button>
                )} */}
              </div>

              <div className={classes.divider} />

              <FollowsInfo
                followers={props.followers}
                following={props.following}
                isCanClick={props.isOwner}
                onOpenModal={props.onOpenModalFoloows}
              />
            </>
          )}
        </Loader>
      </div>
    );
  };

  const Mobile = () => {
    return (
      <div className={classes.card__mobile}>
        <Loader loading={props.loading}>
          {!props.loading && (
            <>
              <div className={classes.share}>
                <Share
                  setOpen={() => {
                    setIsShare(!isShare);
                    setIsReport(false);
                  }}
                  open={isShare}
                  text='Share'
                />
              </div>
              <div className={classes.card__img}>
                <img
                  src={getImage(props.image)}
                  alt='card-image'
                  className={classes.image}
                />
              </div>
              <Copy
                text={utils.shorter(props.address)}
                copyText={props.address}
              />

              {props.name && props.isOwner && (
                <div className={classes.name}>{props.name}</div>
              )}

              {props.userName && props.isOwner && (
                <div className={classes.nick_name}>@{props.userName}</div>
              )}

              <div className={classes.status}>
                {props.isCreator ? (
                  <>
                    <ArtIcon />
                    <div className={classes.nick_name}>Author</div>
                  </>
                ) : (
                  <>
                    <CollectionsIcon />
                    <div className={classes.nick_name}>Сollector</div>
                  </>
                )}
              </div>

              <br />

              <div className={classes.profile_activity}>
                <FollowsInfo
                  followers={props.followers}
                  following={props.following}
                  isCanClick={props.isOwner}
                  onOpenModal={props.onOpenModalFoloows}
                />
              </div>

              {props.isOwner && (
                <div className={classes.bio_mobile}>
                  <div className={classes.title}>Bio</div>
                  <div className={classes.description}>{props.bio}</div>
                </div>
              )}

              <div className={classes.mobile_action_wrapper}>
                {!props.isEditHidden && <ActionButton />}
              </div>

              {props.isOwner && (
                <div className={classes.links_mobile}>
                  <div className={classes.title}>Links</div>

                  <div className={classes.links_mobile_wrapper}>
                    {props.links.map((l) => (
                      <a
                        className={cn(classes.link, {
                          [classes.link_hidden]: !l.link,
                        })}
                        href={l.link}
                        key={l.label}
                        target='_blank'
                      >
                        <l.icon className={classes.link__icon} />
                      </a>
                    ))}
                  </div>
                </div>
              )}

              <div className={classes.divider} />

              <div className={classes.joined}>
                <div className={classes.title}>Joined</div>
                <div className={classes.date}>
                  {moment.formatted({
                    date: props.joined,
                    format: 'MMMM YYYY',
                  })}
                </div>
              </div>
            </>
          )}
        </Loader>
      </div>
    );
  };

  const Owner = () => {
    return (
      <>
        <div className={classes.actions}>
          {!props.isEditHidden && <ActionButton />}
        </div>
        {props.isOwner && (
          <>
            {
              <div className={classes.bio}>
                <div className={classes.title}>Bio</div>
                <div className={classes.description}>{props.bio}</div>
              </div>
            }
            {
              <div className={classes.links}>
                <div className={classes.title}>Links</div>
                {props.links.map((l) => (
                  <a
                    className={cn(classes.link, {
                      [classes.link_hidden]: !l.link,
                    })}
                    href={l.link}
                    key={l.label}
                    target='_blank'
                  >
                    <l.icon className={classes.link__icon} />
                    {l.label}
                  </a>
                ))}
              </div>
            }
          </>
        )}

        <div className={classes.joined}>
          <div className={classes.title}>Joined</div>
          <div className={classes.date}>
            {moment.formatted({
              date: props.joined,
              format: 'MMMM YYYY',
            })}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className={cn(classes.container, props.className)}>
      {isDesktop && <Desktop />}
      {isMobile && <Mobile />}
      {isDesktop && !props.loading && <Owner />}
      <ReportModal
        setOpen={() => setOpenReport(!openReport)}
        open={openReport}
      />
    </div>
  );
};
