import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Button, Col, Grid, ProductCard } from 'components/ui';
import { useAppDispatch, useAppSelector } from 'hooks';

import classes from './feed-list.module.scss';
import { getFeeds } from 'store/nft-list';

export const FeedList = () => {
  const navigator = useNavigate();
  const dispatch = useAppDispatch();
  const { address } = useAppSelector((state) => state.profileStore);
  const { feeds, isLoading } = useAppSelector((state) => state.nftListStore);

  useEffect(() => {
    dispatch(getFeeds({}));
  }, []);

  if (feeds.length) {
    return (
      <Grid className={classes.feed__list}>
        {feeds.map((item: any) => (
          <Col key={item.nftId}>
            <Link to={`/nft/${item.nftId}`} style={{ textDecoration: 'none' }}>
              <ProductCard
                author={item.author}
                image={item.image}
                name={item.name}
                price={item.price}
                status={item.status}
                withActions={false}
              />
            </Link>
          </Col>
        ))}
      </Grid>
    );
  }

  if (!feeds.length && !address && !isLoading) {
    return (
      <div className={classes.check__content_wrap}>
        <div className={classes.check__title}>You are not logged in!</div>
        <div className={classes.check__description}>
          In order to create a customized feed you need to connect your wallet
        </div>
      </div>
    );
  }

  return (
    <div className={classes.check__content_wrap}>
      <div className={classes.check__title}>You don’t follow anyone!</div>
      <div className={classes.check__description}>
        In order to create a customized feed you need to follow authors.
      </div>
      <Button
        label={'Explorer authors'}
        onClick={() => navigator('/authors')}
        size='small'
        type='primary'
        variant='contained'
      />
    </div>
  );
};
