import ReactPaginate, { ReactPaginateProps } from 'react-paginate';
import { Button } from '../button/button';
import { scrollToY } from 'libs';

export const Pagination = (props: ReactPaginateProps) => {
  return (
    <ReactPaginate
      activeClassName={'pagination-item active '}
      breakClassName={'pagination-item break-me '}
      breakLabel={'...'}
      containerClassName={'pagination'}
      disabledClassName={'disabled-page'}
      marginPagesDisplayed={1}
      nextClassName={'pagination-item next '}
      nextLabel={<Button label='Next' size='small' variant='outlined' />}
      pageClassName={'pagination-item pagination-page '}
      previousClassName={'pagination-item previous'}
      previousLabel={<Button label='BACK' size='small' variant='outlined' />}
      renderOnZeroPageCount={null}
      {...props}
    />
  );
};
