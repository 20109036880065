import React from 'react';

export const Upload: React.FC<React.SVGAttributes<SVGElement>> = ({
  className,
}) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <mask
        id='mask0_267_8849'
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='24'
        height='24'
      >
        <rect width='24' height='24' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_267_8849)'>
        <path
          d='M12 16C11.7167 16 11.4793 15.904 11.288 15.712C11.096 15.5207 11 15.2834 11 15V7.85005L9.125 9.72505C8.925 9.92505 8.69167 10.025 8.425 10.025C8.15833 10.025 7.91667 9.91672 7.7 9.70005C7.5 9.50005 7.40433 9.26238 7.413 8.98705C7.421 8.71238 7.51667 8.48338 7.7 8.30005L11.3 4.70005C11.4 4.60005 11.5083 4.52905 11.625 4.48705C11.7417 4.44572 11.8667 4.42505 12 4.42505C12.1333 4.42505 12.2583 4.44572 12.375 4.48705C12.4917 4.52905 12.6 4.60005 12.7 4.70005L16.3 8.30005C16.5 8.50005 16.5957 8.73738 16.587 9.01205C16.579 9.28738 16.4833 9.51672 16.3 9.70005C16.1 9.90005 15.8627 10.004 15.588 10.012C15.3127 10.0207 15.075 9.92505 14.875 9.72505L13 7.85005V15C13 15.2834 12.9043 15.5207 12.713 15.712C12.521 15.904 12.2833 16 12 16ZM6 20C5.45 20 4.97933 19.8044 4.588 19.413C4.196 19.021 4 18.55 4 18V16C4 15.7167 4.09567 15.479 4.287 15.287C4.479 15.0957 4.71667 15 5 15C5.28333 15 5.521 15.0957 5.713 15.287C5.90433 15.479 6 15.7167 6 16V18H18V16C18 15.7167 18.096 15.479 18.288 15.287C18.4793 15.0957 18.7167 15 19 15C19.2833 15 19.5207 15.0957 19.712 15.287C19.904 15.479 20 15.7167 20 16V18C20 18.55 19.8043 19.021 19.413 19.413C19.021 19.8044 18.55 20 18 20H6Z'
          fill='#D3D2D4'
        />
      </g>
    </svg>
  );
};
