import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Modal,
  ModalNote
} from 'components/ui';
import { useAppDispatch, useAppSelector } from 'hooks';
import { setCongratulations } from 'store/profile';

export const ShowCongratulations = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    address,
    showCongratulations,
    isRequestBecomeAuthor
  } = useAppSelector((state) => state.profileStore);

  const onHandleCloseModal = () => {
    dispatch(setCongratulations(false));
  };

  const title = (): string => {
    return isRequestBecomeAuthor ? 'Success!' : 'Congratulations!';
  };

  const text = (): string => {
    return isRequestBecomeAuthor ? 
      'Your application has been sent for consideration, you will receive a notification by email.' 
      : 
      'Your profile has been successfully updated.';
  };
  
  return(
    <Modal opened={ showCongratulations } outClick={() => onHandleCloseModal()}>
      <ModalNote
        onClick={() => {
          navigate(`/profile/${ address }`);
          onHandleCloseModal();
        }}
        onClose={() => onHandleCloseModal()}
        title={title()}
        text={text()}
      />
    </Modal>
  );
}
