import * as Yup from 'yup';

export default (): Yup.ObjectSchema<any> => {
  const validationShape: any = {
    first_name: Yup.string()
      .min(2, 'Use more than 2 characters')
      .max(30, `Don’t use more than 30 characters`)
      .notOneOf(['Feed', 'NFTs', 'Bids', 'etc'], `The name must not match the title of the pages: don’t use words: Feed,  NFTs, Bids etc.)`)
      .matches(/^[a-zA-Z0-9.\s]+$/i, `Don’t use symbols or punctuation`)
      .required('Required'),
    // image: Yup.string().required('Required'),
    // cover_image: Yup.string().required('Required'),
    username: Yup.string()
      .min(2, 'Use more than 2 characters')
      .max(30, 'Don’t use more than 30 characters')
      .notOneOf(['Feed', 'NFTs', 'Bids', 'etc'], `The name must not match the title of the pages: don’t use words: Feed,  NFTs, Bids etc.)`)
      .matches(/^[a-zA-Z0-9]+$/i, `Don’t use symbols or punctuation`)
      .required('Required'),
    bio: Yup.string().max(200, 'Too Long!').nullable(),
    email: Yup.string()
      .matches(/^[-\w.]+@([A-z0-9][-A-z0-9]+\.)+[A-z]{2,7}$/, 'Wrong email')
      .email('Wrong email'),
  };

  validationShape.meta = Yup.object().shape({
    website: Yup.string().matches(
      /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
      'Not matches'
    ),
    facebook: Yup.string().matches(/^[a-zA-Z0-9()][^\s]+$/, 'Not matches'),
    istagram: Yup.string().matches(/^[a-zA-Z0-9()][^\s]+$/, 'Not matches'),
    twitter: Yup.string().matches(/^[a-zA-Z0-9()][^\s]+$/, 'Not matches'),
    discord: Yup.string().matches(/^[a-zA-Z0-9()][^\s]+$/, 'Not matches'),
    youtube: Yup.string().matches(/^[a-zA-Z0-9()][^\s]+$/, 'Not matches'),
    tiktok: Yup.string().matches(/^[a-zA-Z0-9()][^\s]+$/, 'Not matches'),
    twitch: Yup.string().matches(/^[a-zA-Z0-9()][^\s]+$/, 'Not matches'),
    snapchat: Yup.string().matches(/^[a-zA-Z0-9()][^\s]+$/, 'Not matches'),
  });

  return Yup.object().shape(validationShape);
};
