import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { NavTab } from 'components/ui';
import { LogoLightHorizontal } from 'components/icons';
import { ReportModal } from 'components/containers';
import { URLS, FOOTER_URLS } from 'config';

import classes from './footer.module.scss';

export const Footer: React.FC = () => {
  const [openReport, setOpenReport] = useState(false);

  const handlerOpenReport = () => {
    setOpenReport(true);
  };

  return (
    <footer className={classes.footer}>
      <div className={classes.footer__content}>
        <div className={classes.footer__copy}>
          Copyright 2023 BABYBNBTIGER. All rights reserved
        </div>
        <Link to={URLS.HOME_PAGE} className={classes.footer__logo}>
          <LogoLightHorizontal />
        </Link>
        <div className={classes.footer__tabs}>
          {FOOTER_URLS.map((tab) => {
            return (
              <a
                key={tab.label}
                href={tab.link}
                className={classes.tabLabel}
                target='_blank'
              >
                {tab.icon && tab.icon}
                {tab.label}
              </a>
            );
          })}
        </div>
      </div>

      <ReportModal
        setOpen={() => setOpenReport(!openReport)}
        open={openReport}
      />
    </footer>
  );
};
