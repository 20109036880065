import React from 'react';

import styles from './genre.module.scss';

export interface GenreProps {
    name: string;
    image: string;
}

export const Genre = ({ name, image }: GenreProps) => {
  const url = new URL(image);

  return(
    <div className={styles.GenreWrapper} style={{ backgroundImage: `url(${ url })` }}>
      <div className={styles.Genre}>
        <div className={styles.Name}>{name}</div>
      </div>
    </div>
  );
}
