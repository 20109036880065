import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_METHODS, API_DOMAIN, NftStatuses } from 'config';
import { INCREASE_COEFFICIENT_MULTIPLIER } from 'constants/constants';

import { INft } from './interface';

export const nftsApi = createApi({
  reducerPath: 'nftsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_DOMAIN,
    credentials: 'include',
  }),
  endpoints: (build) => ({
    getNftsByStatus: build.query<INft[], string>({
      query: (query) => ({
        url: `${API_METHODS.GET_NFTS}/?status=${query}`,
        method: 'GET',
      }),
      transformResponse: (data: any) => {
        const result: any = [];

        for (const {
          name,
          image,
          description,
          genre,
          nft_id,
          price,
          status,
          author,
          owner,
          auction,
        } of data) {
          result.push({
            name,
            image: image ? image.image : null,
            metadata: image ? image.metadata : null,
            description,
            genre,
            nftId: nft_id,
            status,
            price,
            author: {
              image: author.image,
              username: author.username,
              wallet: author.wallet,
            },
            owner: {
              image: owner.image,
              username: owner.username,
              wallet: owner.wallet,
            },
            auction: null,
          });
        }

        return result;
      },
    }),
    getNft: build.query<any, string>({
      query: (id: any) => ({
        url: `${API_METHODS.GET_NFT}${id}/`,
        method: 'GET',
      }),
      transformResponse: (data: any) => {
        const {
          name,
          image,
          description,
          genre,
          nft_id,
          price,
          status,
          author,
          owner,
          auction,
          can_list_or_open,
          is_open_box,
        } = data;
        const object: any = {
          name,
          image: image ? image.image : null,
          metadata: image ? image.metadata : null,
          description,
          genre,
          nftId: nft_id,
          status,
          price,
          canListOrOpen: can_list_or_open,
          isOpenBox: is_open_box,
          author: {
            image: author.image,
            username: author.username,
            wallet: author.wallet,
          },
          owner: {
            image: owner.image,
            username: owner.username,
            wallet: owner.wallet,
          },
          auction: null,
        };

        if (auction) {
          const {
            max_bid,
            my_bid,
            time_end,
            is_active,
            increase_coefficient,
            auction_owner,
          } = auction;

          object.auction = {
            // auctionOwner: auction_owner,
            status: is_active,
            isMyLastBet:
              max_bid && my_bid
                ? max_bid.bidder.wallet === my_bid.bidder.wallet
                : false,
            endTimestamp: time_end,
            myLastBet: my_bid ? my_bid.amount : null,
            lastBet: max_bid ? max_bid.amount : null,
            currency: auction.currency,
            increaseCoefficient:
              increase_coefficient / INCREASE_COEFFICIENT_MULTIPLIER,
          };
        }

        return object;
      },
    }),
  }),
});

export const { useGetNftQuery, useGetNftsByStatusQuery } = nftsApi;
