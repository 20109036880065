import React, { useEffect, useRef, useState } from 'react';
import { CloseIcon } from 'components/icons';
import { Button, Input, ModalPortal } from 'components/ui';
import { sendReport } from 'store/profile';
import { useAppDispatch } from 'hooks';
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik';

import classes from './report-modal.module.scss';
import ValidationSchema from './schema';

type Props = {
  setOpen: () => void;
  open: boolean;
};

export const ReportModal = ({ open, setOpen }: Props) => {
  const messageRef = useRef<HTMLTextAreaElement>(null);
  const [textareaValue, setTextareaValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [textareaCount, setTextareaCount] = useState<any>(0);
  const dispatch = useAppDispatch();
  const [isMessageHasSend, setIsMessageHasSend] = useState<boolean>(false);

  const data = {
    email: '',
  };

  const onHandleChange = async (params: any) => {
    if (params.email) {
      setInputValue(params.email);
    }
  };

  useEffect(() => {
    setTextareaCount(messageRef.current?.value.length);
  }, [textareaValue]);

  if (!open) return <></>;

  const handleSubmit = () => {
    if (!inputValue || !textareaValue) return;

    dispatch(sendReport({ email: inputValue, message: textareaValue }));
    setTextareaValue('');
    setInputValue('');
    setIsMessageHasSend(true);
  };

  const handleClose = () => {
    setTextareaValue('');
    setInputValue('');
    setIsMessageHasSend(false);
    setOpen();
  };

  return (
    <ModalPortal>
      <div className={classes.report__modal} onClick={handleClose}>
        <div
          className={classes.report_modal_form}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={classes.modal__close} onClick={handleClose}>
            <CloseIcon className={classes.close__icon} />
          </div>

          {isMessageHasSend ? (
            <div className={classes.form__info}>
              <div className={classes.form__info_success}>
                <h1 className={classes.form__title}>
                  Success! Expect a response to your email
                </h1>

                <Button
                  label='Close'
                  size='small'
                  type='primary'
                  variant='contained'
                  onClick={handleClose}
                />
              </div>
            </div>
          ) : (
            <Formik
              initialValues={data}
              onSubmit={handleSubmit}
              validationSchema={() => ValidationSchema()}
            >
              {({ errors, touched }: FormikProps<any>) => {
                return (
                  <Form
                    onChange={({ target }: any) => {
                      onHandleChange({ [target.name]: target.value });
                    }}
                  >
                    <div className={classes.form__info}>
                      <h1 className={classes.form__title}>
                        Write to support service
                      </h1>
                      <div className={classes.form__description}>
                        If you have any questions or problems send a message to
                        our support service. You’ll get an answer to your email.
                      </div>
                    </div>
                    <div className={classes.form__controls}>
                      <Field name='email'>
                        {({ field, meta: { touched, error } }: FieldProps) => (
                          <Input
                            type='email'
                            label='Email'
                            errorMessage={touched && error ? error : undefined}
                            placeHolder='Enter your email'
                            {...field}
                          />
                        )}
                      </Field>

                      <label htmlFor='message'>
                        Message
                        <textarea
                          placeholder='Enter your question or describe your problem'
                          id='message'
                          maxLength={2200}
                          ref={messageRef}
                          value={textareaValue}
                          onChange={(e) => setTextareaValue(e.target.value)}
                        />
                        <div className={classes.textarea__count}>
                          {textareaCount}/2200
                        </div>
                      </label>

                      <Button
                        label='Send'
                        size='small'
                        type='primary'
                        variant='contained'
                        isSubmit
                      />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          )}
        </div>
      </div>
    </ModalPortal>
  );
};
