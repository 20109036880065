import React from 'react';

import { SearchIcon } from 'components/icons';

import styles from './search.module.scss';

export interface SearchProps {
  value: string;
  onChange: (text: string) => void;
}

export const Search = ({ value, onChange }: SearchProps) => {
  const onHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <div className={styles.SearchContainer}>
      <SearchIcon className={styles.SearchIcon} />
      <div className={styles.searchWrapper}>
        <input
          value={value}
          onChange={onHandleChange}
          className={styles.SearchInput}
          placeholder='Search'
        />
      </div>
    </div>
  );
};
