import React from 'react';
import cn from 'classnames';

import { utils } from 'libs';

import classes from './process-profile.module.scss';
import { defaultImagePath } from 'libs/utils/utils';

interface IProps {
  image: string | null | undefined;
  username: string | null | undefined;
  address: string | null | undefined;
  className?: string;
}

export const ProcessProfile = ({
  image,
  username,
  address,
  className,
}: IProps) => {
  return (
    <div className={cn(classes.container, className)}>
      <>
        <div className={classes.title}>Connected to</div>
        <div className={classes.profile}>
          <img src={image || defaultImagePath} alt={username || ''} />
          {utils.shorter(address!, 10)}
        </div>
      </>
    </div>
  );
};
