import React from 'react';
import cn from 'classnames';

import { InfoIcon, CancelIcon } from 'components/icons';

import classes from './info-text.module.scss';

interface IProps {
  text: string;
  type?: string;
  className?: string;
}

export const InfoText = ({ text, className = '', type = 'info' }: IProps) => {
  return (
    <div
      className={cn(classes.text, className, type === 'error' ? 'error' : '')}
    >
      {type === 'info' && <InfoIcon />}
      {type === 'error' && <CancelIcon />}
      <span className={type === 'error' ? 'error' : ''}>{text}</span>
    </div>
  );
};
