import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IState } from './interface';
import { changeReservePrice } from './actions';
import { DEFAULT_TX_ERROR_TEXT, transactionErrors } from 'config';

const initialState: IState = {
  agreement: true,
  amount: null,
  coefficient: null,
  step: null,
  error: null,
  txHash: null,
  isLoading: false,
  isCompleted: false,
};

export const changeReservePriceSlice = createSlice({
  name: 'changeReservePrice',
  initialState,
  reducers: {
    clearState: () => initialState,
    setStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    setTxHash: (state, action: PayloadAction<string>) => {
      state.txHash = action.payload;
    },
    setAmount: (state, action: PayloadAction<string>) => {
      state.amount = action.payload;
    },
    setCoefficient: (state, action: PayloadAction<string>) => {
      state.coefficient = action.payload;
    },
    setAgreement: (state, action: PayloadAction<boolean>) => {
      state.agreement = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(changeReservePrice.pending, (state) => {
        state.step = 1;
      })
      .addCase(
        changeReservePrice.fulfilled,
        (state, action: PayloadAction<any>) => {
          if (action.payload.error) {
            state.isCompleted = false;
            Object.keys(transactionErrors).forEach((i) => {
              if (!action.payload.error.includes(i)) {
                return (state.error = DEFAULT_TX_ERROR_TEXT);
              } else {
                return (state.error =
                  transactionErrors[i as keyof typeof transactionErrors]);
              }
            });
          } else {
            state.isCompleted = true;
            state.step = 3;
          }
        }
      );
  },
});

export const {
  clearState,
  setStep,
  setTxHash,
  setCoefficient,
  setAmount,
  setAgreement,
} = changeReservePriceSlice.actions;
