import React from 'react';

export const User: React.FC<React.SVGAttributes<SVGElement>> = (props) => {
  return (
    <svg
      width='17'
      height='17'
      viewBox='0 0 17 17'
      fill='#221E1E'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M8.01172 8.5376C6.91172 8.5376 5.97005 8.14593 5.18672 7.3626C4.40339 6.57926 4.01172 5.6376 4.01172 4.5376C4.01172 3.4376 4.40339 2.49593 5.18672 1.7126C5.97005 0.929264 6.91172 0.537598 8.01172 0.537598C9.11172 0.537598 10.0534 0.929264 10.8367 1.7126C11.6201 2.49593 12.0117 3.4376 12.0117 4.5376C12.0117 5.6376 11.6201 6.57926 10.8367 7.3626C10.0534 8.14593 9.11172 8.5376 8.01172 8.5376ZM2.01172 16.5376C1.46172 16.5376 0.991052 16.3419 0.599719 15.9506C0.207719 15.5586 0.0117188 15.0876 0.0117188 14.5376V13.7376C0.0117188 13.1709 0.157719 12.6499 0.449719 12.1746C0.741052 11.6999 1.12839 11.3376 1.61172 11.0876C2.64505 10.5709 3.69505 10.1833 4.76172 9.9246C5.82839 9.6666 6.91172 9.5376 8.01172 9.5376C9.11172 9.5376 10.1951 9.6666 11.2617 9.9246C12.3284 10.1833 13.3784 10.5709 14.4117 11.0876C14.8951 11.3376 15.2824 11.6999 15.5737 12.1746C15.8657 12.6499 16.0117 13.1709 16.0117 13.7376V14.5376C16.0117 15.0876 15.8161 15.5586 15.4247 15.9506C15.0327 16.3419 14.5617 16.5376 14.0117 16.5376H2.01172Z'
        fill='inherit'
      />
    </svg>
  );
};
