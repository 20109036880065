import * as Yup from 'yup';

export default (): Yup.ObjectSchema<any> => {
  const validationShape: any = {
    first_name: Yup.string()
      .min(2, 'Use more than 2 characters')
      .max(30, 'Don’t use more than 30 characters')
      .required('Required'),  
    cover_letter: Yup.string()
      .min(20, 'Use more than 20 characters')
      .max(200, 'Too Long!')
      .required('Required'),
      // .nullable(),
    email: Yup.string()
      .matches(/^[-\w.]+@([A-z0-9][-A-z0-9]+\.)+[A-z]{2,7}$/, 'Wrong email')
      .required('Required'),
    cloud: Yup.string()
      .matches(
        /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
        'Not matches'
      )
      .required('Required'),
  };

  validationShape.meta = Yup.object().shape({
    website: Yup.string().matches(
      /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
      'Not matches'
    )
    .required('Required'),
  });

  return Yup.object().shape(validationShape);
};
