import React from 'react';
import { Link } from 'react-router-dom';

import { INDEX_URLS, GET_TARGET_PAGE } from 'config';
import { FollowingButton } from 'components/ui';
import { utils } from 'libs';

import classes from './author-card.module.scss';

import { IProps } from './author-card.interface';
// TODO - если на беке не будет дефолтной - то сюда бы ее вставить
import defaultImage from './../../../assets/img/img.png';
import { useAppSelector } from 'hooks';
import { ConnectButton } from 'components/containers';
// TODO
import { address } from 'mocks/user-info';

export const AuthorCard = (params: IProps) => {
  const { /* address */ } = useAppSelector((state) => state.profileStore);

  return (
    <div className={classes.container}>
      <Link to={GET_TARGET_PAGE(INDEX_URLS.PROFILE, params.wallet)}>
        <div className={classes.card}>
          <div className={classes.image}>
            <img
              src={utils.getImage(params.image) || defaultImage}
              alt={'card-image'}
            />
          </div>
          <div className={classes.name}>{params.name || 'Anonym'}</div>
          <div className={classes.nickName}>@{params.nickName || 'Anonym'}</div>
          <div className={classes.description}>{params.description}</div>
        </div>
      </Link>
      <div className={classes.actions}>
        <div className={classes.followers}>
          <div className={classes.value}>{params.followers.length}</div>
          <div className={classes.title}>followers</div>
        </div>
        {address ? (
          <FollowingButton
            disabled={params.subscribeProcess}
            isFollow={params.isFollowing}
            onClick={() => {
              params.onHandleSubscribe({
                action: params.isFollowing
                  ? 'delete'
                  : !params.isFollowing
                  ? 'add'
                  : null,
                authorId: params.authorId,
                wallet: params.wallet,
              });
            }}
          />
        ) : (
          <ConnectButton size='small' full={false} />
        )}
      </div>
    </div>
  );
};
