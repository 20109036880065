import React, { useState, useEffect } from 'react';

import { FollowsItem, Loader, ModalPortal, Tabs } from 'components/ui';
import { CloseIcon } from 'components/icons';
import { IFollower, getFollowers, getFollowing, setOpen } from 'store/follows';
import { IFollowingData } from 'components/ui/author-card/author-card.interface';
import { subscribe, unSubscribe } from 'store/authors';
import { useAppDispatch, useAppSelector } from 'hooks';

import classes from './follows-modal.module.scss';
import { tabs } from './config';

export const FollowsModal = () => {
  const [users, setUsers] = useState<any>([]);
  const [activeTab, setActiveTab] = useState<string>('following');
  const dispatch = useAppDispatch();
  const {
    following,
    followers,
    isFollowersLoading,
    isFollowingLoading,
    isOpen,
  } = useAppSelector((state) => state.follows);
  const { userData } = useAppSelector((state) => state.profileStore);

  useEffect(() => {
    if (!userData) return;

    dispatch(getFollowers(userData.id));
    dispatch(getFollowing(userData.id));
  }, [userData]);

  const addStateIsFollowerUser = (usersArray: IFollower[]) => {
    return usersArray.map((user: IFollower) => {
      const even = (element: any) => element.id === user.id;

      return { ...user, isFolow: following.some(even) };
    });
  };

  const handleChange = (value: string) => {
    if (value === 'following') {
      setUsers(addStateIsFollowerUser(following));
      setActiveTab('following');
    }

    if (value === 'followers') {
      setUsers(addStateIsFollowerUser(followers));
      setActiveTab('followers');
    }
  };

  const handleClose = () => {
    dispatch(setOpen(false));
  };

  useEffect(() => {
    if (!isFollowingLoading && !isFollowingLoading) {
      const users = activeTab === 'following' ? following : followers;
      setUsers(addStateIsFollowerUser(users));
    }
  }, [isFollowingLoading, isFollowingLoading]);

  const handleSubscribe = async ({
    action,
    authorId,
    wallet,
  }: IFollowingData) => {
    if (!userData.id || !action || !authorId) {
      return;
    }

    if (action === 'add') {
      dispatch(
        subscribe({
          follower: userData.id,
          author: authorId,
          wallet,
        })
      );
    }

    if (action === 'delete') {
      dispatch(
        unSubscribe({
          follower: userData.id,
          author: authorId,
          wallet,
        })
      );
    }
  };

  if (!isOpen) return <></>;

  return (
    <ModalPortal>
      <div className={classes.FollowsModal__modal} onClick={handleClose}>
        <div
          className={classes.FollowsModal__form}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={classes.FollowsModal__close} onClick={handleClose}>
            <CloseIcon />
          </div>

          <div className={classes.FollowsModal__header}>
            <Tabs
              disabled={false}
              items={tabs}
              onChange={handleChange}
              isOwner={true}
              isCreator={true}
            />
          </div>

          <div className={classes.FollowsModal__divider} />

          <div className={classes.FollowsModal__body}>
            <Loader loading={isFollowersLoading || isFollowingLoading}>
              {users.map((item: any) => (
                <FollowsItem
                  authorId={item.id}
                  key={item.id}
                  wallet={item.wallet}
                  image={item.image}
                  first_name={item.first_name}
                  username={item.username}
                  onHandleSubscribe={handleSubscribe}
                  isFollowing={item.isFolow}
                />
              ))}
            </Loader>
          </div>
        </div>
      </div>
    </ModalPortal>
  );
};
