export const CREATE_NFT_STEPS = {
  uploadImage: {
    index: 1,
    title: 'Upload file',
    btnLabel: 'Share data',
  },
  loadToIPFS: {
    index: 2,
    title: 'Load to IPFS',
    btnLabel: 'Add description',
  },
  addMetaData: {
    index: 3,
    title: 'Add description',
    btnLabel: 'Mint NFT',
  },
  mint: {
    index: 4,
    title: 'Mint NFT',
    btnLabel: 'Set a price',
  },
  setPrice: {
    index: 5,
    title: 'Set a reserve price in ETH',
    btnLabel: 'List NFT',
  },
  listing: {
    index: 6,
    title: 'List NFT',
    btnLabel: '',
  },
};

export const CLAIM_ETH_STEPS = {
  settleAuction: {
    index: 1,
    title: 'Settle auction',
  },
  confirmTransaction: {
    index: 2,
    title: 'Transaction confirmation',
  },
};

export const BACK_STEP = {
  btnLabel: 'Back',
};

export interface ICreateNFTStep {
  index: number;
  title: string;
  btnLabel?: string;
}
