import { useState, useEffect } from 'react';

export const useMedia = () => {
  const [ isDesktop, setIsDesktop ] = useState(false);
  const [ isMobile, setIsMobile ] = useState(false);

  useEffect(() => {
    const mediaDesk = window.matchMedia('(min-width: 769px)');
    const mediaMob = window.matchMedia('(max-width: 768px)');

    if (mediaDesk.matches !== isDesktop) {
      setIsDesktop(mediaDesk.matches);
    } else if (mediaMob.matches !== isMobile) {
      setIsMobile(mediaMob.matches);
    }
    const listenerDesk = () => setIsDesktop(mediaDesk.matches);
    window.addEventListener('resize', listenerDesk);
    const listenerMob = () => setIsMobile(mediaMob.matches);
    window.addEventListener('resize', listenerMob);

    return () => {
      window.removeEventListener('resize', listenerDesk);
      window.removeEventListener('resize', listenerMob);
    };
  }, [isDesktop, isMobile]);

  return { isDesktop, isMobile };
};
