import cn from 'classnames';
import { capitalizeFirstLetter } from 'helpers';
import cl from './nft-label.module.scss';

type Props = {
  type: 'legendary' | 'platinum' | 'gold' | 'rare';
};

export const NftLabel = ({ type }: Props) => (
  <div className={cn(cl.label, cl[type])}>
    {capitalizeFirstLetter(type || '')}
  </div>
);
