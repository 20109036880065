import React, { Suspense, useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';

import { PageLoader } from 'components/ui';

export const Router = ({ routes }: { routes: any[] }): JSX.Element => {
  const pages = useMemo(() => {
    return routes.map(({ path, element: Component, ...props }) => (
      <Route
        key={path}
        path={path}
        {...props}
        element={
          <Suspense fallback={<PageLoader />}>
            <Component />
          </Suspense>
        }
      />
    ));
  }, [routes]);

  return <Routes>{pages}</Routes>;
};
