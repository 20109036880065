import React from 'react';

import { TIconWrapper } from './declarations';

export const MetamaskIcon: React.FC<TIconWrapper> = ({ size = 32, className }) => {
  return(
    <svg
      width={ size }
      height={ size }
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={ className }
    >
      <path d='M29.5687 2.66675L17.36 11.7004L19.6303 6.37656L29.5687 2.66675Z' fill='#E17726'/>
      <path d='M2.46191 2.67749L12.3738 6.3773L14.5295 11.7708L2.46191 2.67749Z' fill='#E27625'/>
      <path d='M24.6534 21.8784L30.0493 21.9811L28.1635 28.3876L21.5792 26.5747L24.6534 21.8784Z' fill='#E27625'/>
      <path d='M7.34685 21.8784L10.4095 26.5747L3.8362 28.3877L1.96179 21.9811L7.34685 21.8784Z' fill='#E27625'/>
      <path d='M14.2384 10.397L14.459 17.5193L7.86047 17.2191L9.73733 14.3874L9.76111 14.3602L14.2384 10.397Z'
            fill='#E27625'/>
      <path d='M17.6971 10.3174L22.2428 14.3603L22.2664 14.3874L24.1434 17.2191L17.5462 17.5192L17.6971 10.3174Z'
            fill='#E27625'/>
      <path d='M10.6045 21.8992L14.2075 24.7065L10.0221 26.7272L10.6045 21.8992Z' fill='#E27625'/>
      <path d='M21.3996 21.8987L21.97 26.7272L17.7963 24.7062L21.3996 21.8987Z' fill='#E27625'/>
      <path d='M17.8879 24.4419L22.1232 26.4927L18.1836 28.365L18.2245 27.1275L17.8879 24.4419Z' fill='#D5BFB2'/>
      <path d='M14.1107 24.4429L13.7874 27.1074L13.814 28.3636L9.86511 26.4928L14.1107 24.4429Z' fill='#D5BFB2'/>
      <path d='M12.889 18.4487L13.9958 20.7747L10.2277 19.6708L12.889 18.4487Z' fill='#233447'/>
      <path d='M19.1112 18.449L21.785 19.671L18.0046 20.7745L19.1112 18.449Z' fill='#233447'/>
      <path d='M10.8908 21.8755L10.2818 26.8813L7.01721 21.985L10.8908 21.8755Z' fill='#CC6228'/>
      <path d='M21.111 21.8755L24.9847 21.985L21.7079 26.8815L21.111 21.8755Z' fill='#CC6228'/>
      <path d='M24.2381 16.936L21.419 19.8092L19.2454 18.8159L18.2048 21.0036L17.5226 17.2416L24.2381 16.936Z'
            fill='#CC6228'/>
      <path d='M7.76044 16.936L14.4773 17.2417L13.795 21.0036L12.7542 18.8162L10.5921 19.8093L7.76044 16.936Z'
            fill='#CC6228'/>
      <path d='M7.57288 16.3464L10.7624 19.583L10.8729 22.7783L7.57288 16.3464Z' fill='#E27525'/>
      <path d='M24.4323 16.3406L21.1263 22.7838L21.2508 19.5829L24.4323 16.3406Z' fill='#E27525'/>
      <path
        d='M14.3236 16.5435L14.452 17.3515L14.7692 19.3642L14.5653 25.5466L13.6011 20.5802L13.6008 20.5289L14.3236 16.5435Z'
        fill='#E27525'/>
      <path
        d='M17.6774 16.5322L18.4021 20.5289L18.4018 20.5802L17.4352 25.5591L17.397 24.3137L17.2462 19.3277L17.6774 16.5322Z'
        fill='#E27525'/>
      <path d='M21.5351 19.4543L21.4273 22.2305L18.0625 24.852L17.3823 24.3714L18.1447 20.4442L21.5351 19.4543Z'
            fill='#F5841F'/>
      <path d='M10.4778 19.4543L13.8565 20.4442L14.6189 24.3714L13.9387 24.852L10.5738 22.2301L10.4778 19.4543Z'
            fill='#F5841F'/>
      <path
        d='M9.22333 25.8796L13.5281 27.9193L13.5099 27.0483L13.87 26.7321H18.1306L18.5038 27.0472L18.4762 27.9175L22.7538 25.8848L20.6723 27.6048L18.1555 29.3334H13.8355L11.3204 27.5977L9.22333 25.8796Z'
        fill='#C0AC9D'/>
      <path
        d='M17.5787 24.1704L18.1873 24.6003L18.5439 27.446L18.0278 27.0102H13.9738L13.4674 27.4548L13.8124 24.6005L14.4212 24.1704H17.5787Z'
        fill='#161616'/>
      <path
        d='M28.7618 2.91675L30.2274 7.31319L29.3121 11.7585L29.9638 12.2612L29.0819 12.9341L29.7448 13.446L28.8671 14.2452L29.4059 14.6355L27.976 16.3055L22.111 14.5978L22.0602 14.5705L17.8338 11.0053L28.7618 2.91675Z'
        fill='#763E1A'/>
      <path
        d='M3.23975 2.91675L14.1679 11.0053L9.94139 14.5705L9.8906 14.5978L4.02564 16.3055L2.59557 14.6355L3.13394 14.2456L2.25681 13.446L2.91833 12.9348L2.02319 12.2599L2.69949 11.7569L1.77423 7.31342L3.23975 2.91675Z'
        fill='#763E1A'/>
      <path
        d='M21.8255 14.2244L28.0398 16.0338L30.0587 22.2561H24.7323L21.0624 22.3023L23.7313 17.0999L21.8255 14.2244Z'
        fill='#F5841F'/>
      <path
        d='M10.1782 14.2244L8.27199 17.0999L10.9413 22.3023L7.27305 22.2561H1.95624L3.9638 16.0338L10.1782 14.2244Z'
        fill='#F5841F'/>
      <path
        d='M19.9311 6.34679L18.1928 11.0413L17.824 17.3831L17.6829 19.3709L17.6718 24.4487H14.3251L14.3142 19.3804L14.1726 17.3814L13.8036 11.0413L12.0657 6.34668L19.9311 6.34679Z'
        fill='#F5841F'/>
    </svg>
  );
}
