export const mintingSteps = [
  {
    title: 'Saving metadata',
    description:
      'Once your artwork has been loaded to IPFS, the metadata for your artwork will be saved to the IPFS.',
  },
  {
    title: 'Confirm transaction',
    description:
      'Confirm the transaction by signing your wallet as the original creator of the NFT. This will ensure that your artwork will be securely minted as an NFT on the BNB blockchain.',
  },
  {
    title: 'Your NFT is being minted...',
    description:
      'Your NFT will now be minted onto the BNB blockchain. You can navigate away from this page, as the minting process will still continue.',
  },
  {
    title: 'Your NFT has now been successfully minted!',
    description:
      "Congratulations! Your artwork has officially been minted as an NFT on the BNB blockchain. By following the next steps, you'll be able to easily set a price for your work and put it up for auction. Alternatively, you can do this later from your profile if you prefer.",
  },
];
