import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'hooks';
import { FILTER_PARAMS, CREATE_NFT_STEPS } from 'config';

import {
  UploadImage,
  LoadToIPFS,
  AddNFTMetaData,
  MintNFT,
  SetPriceNFT,
  ListNft,
} from 'components/containers';
import {
  CreateNftWrapper,
  ProductCard
} from 'components/ui';
import {
  clearState,
  setHashLoaded
} from 'store/nft-manager';

import classes from './create-nft.module.scss';

const stepsLength = Object.values(CREATE_NFT_STEPS).length;

export const CreateNFT = () => {
  const dispatch = useAppDispatch();
  const [ searchParams, setSearchParams ] = useSearchParams();
  const { step, fullPath, name } = useAppSelector((state) => state.NFTManagerStore);
  const { userData } = useAppSelector(state => state.profileStore);

  useEffect(() => {
    const _step = searchParams.get(FILTER_PARAMS.STEP)!;

    if (!_step || +_step > step.index) {
      dispatch(clearState());
      searchParams.set(
        FILTER_PARAMS.STEP,
        `${CREATE_NFT_STEPS.uploadImage.index}`
      );

      setSearchParams(searchParams);
    }
  }, [searchParams]);

  return (
    <div className={ classes.container }>
      <CreateNftWrapper
        title={ step.title || '' }
        tooltip={ `Step ${step.index}/${stepsLength}` }
      >
        { step.index === CREATE_NFT_STEPS.uploadImage.index && <UploadImage /> }
        { step.index === CREATE_NFT_STEPS.loadToIPFS.index && <LoadToIPFS /> }
        {
          step.index > CREATE_NFT_STEPS.loadToIPFS.index &&
          (
            <div className={ classes.blocks }>
              <div className={ classes.left }>
                <ProductCard
                  author={{
                    image: userData && userData.image,
                    username: userData && userData.username
                  }}
                  image={ fullPath }
                  name={ name || '' }
                  setImageLoaded={ () => dispatch(setHashLoaded()) }
                  className={ classes.productCard }
                  isOwner={ false }
                  withActions={ false }
                />                
              </div>
              <div className={ classes.right }>
                { step.index === CREATE_NFT_STEPS.addMetaData.index && <AddNFTMetaData /> }
                { step.index === CREATE_NFT_STEPS.mint.index && <MintNFT /> }
                { step.index === CREATE_NFT_STEPS.setPrice.index && <SetPriceNFT /> }
                { step.index === CREATE_NFT_STEPS.listing.index && <ListNft /> }
              </div>
            </div>
          )
        }
      </CreateNftWrapper>
    </div>
  );
}
