import React from 'react';
import cn from 'classnames';

import { Loader as LoaderPic } from 'components/icons';

import classes from './connect-modal-loader.module.scss';

interface IProps {
  children: React.ReactNode;
  loading: boolean;
}

export const ConnectModalLoader: React.FC<IProps> = ({ children, loading }) => {
  return (
    <>
      {loading && (
        <div className={cn(classes.loader__container)}>
          <LoaderPic className={cn(classes.loader__pic)} />
        </div>
      )}
      {children}
    </>
  );
};
