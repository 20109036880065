import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from 'hooks';

import { Button } from 'components/ui';
import {
  CREATE_NFT_STEPS,
  BACK_STEP,
  FILTER_PARAMS,
  ICreateNFTStep,
} from 'config';
import { setStep } from 'store/nft-manager';

import classes from './step-control.module.scss';

interface IStepProps extends ICreateNFTStep {
  disabled: boolean;
  useThis?: boolean;
}

export const StepControl: React.FC = () => {
  const dispatch = useAppDispatch();
  const [next, setNext] = useState<IStepProps | null>(null);
  const [back, setBack] = useState<IStepProps | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    file,
    description,
    name,
    genre,
    step,
    isLoading,
    IPFSHash,
    isHashLoaded,
    nftId,
    startPrice
  } = useAppSelector((state) => state.NFTManagerStore);
  
  const setBackProps = (data: any) => {
    setBack({
      ...data,
      btnLabel: BACK_STEP.btnLabel,
    });
  };

  useEffect(() => {
    if (!step) {
      return;
    }

    if (step.index === CREATE_NFT_STEPS.uploadImage.index) {
      const _next = IPFSHash
        ? CREATE_NFT_STEPS.addMetaData
        : CREATE_NFT_STEPS.loadToIPFS;

      setNext({
        ..._next,
        disabled: !IPFSHash,
        useThis: !!IPFSHash,
      });
      setBackProps({
        btnLabel: BACK_STEP.btnLabel,
        disabled: true,
      });
    }

    if (step.index === CREATE_NFT_STEPS.loadToIPFS.index) {
      setNext({
        ...CREATE_NFT_STEPS.addMetaData,
        disabled: true,
        useThis: false,
      });
      setBackProps({
        ...CREATE_NFT_STEPS.uploadImage,
        disabled: false,
      });
    }

    if (step.index === CREATE_NFT_STEPS.addMetaData.index) {
      setNext({
        ...CREATE_NFT_STEPS.mint,
        disabled: true,
        useThis: false,
      });
      setBackProps({
        ...CREATE_NFT_STEPS.uploadImage,
        disabled: false,
      });
    }

    if (step.index === CREATE_NFT_STEPS.mint.index) {
      setNext({
        ...CREATE_NFT_STEPS.setPrice,
        disabled: true,
        useThis: false,
      });
      setBack(null);
    }

    if (step.index === CREATE_NFT_STEPS.setPrice.index) {
      setNext({
        ...CREATE_NFT_STEPS.listing,
        disabled: true,
        useThis: false,
      });
      setBack(null);
    }

    if (step.index === CREATE_NFT_STEPS.listing.index) {
      setNext(null);
      setBack(null);
    }
  }, [step]);

  useEffect(() => {
    if (!next) {
      return;
    }

    if (step.index === CREATE_NFT_STEPS.uploadImage.index) {
      if (file) {
        return setNext({
          ...CREATE_NFT_STEPS.loadToIPFS,
          disabled: false,
          useThis: false,
        });
      }

      const _next = IPFSHash ? CREATE_NFT_STEPS.addMetaData : next;

      return setNext({
        ..._next,
        disabled: !file && !IPFSHash,
        useThis: !!IPFSHash,
      });
    }

    if (step.index === CREATE_NFT_STEPS.loadToIPFS.index) {
      return setNext({
        ...next,
        disabled: !IPFSHash,
      });
    }

    if (step.index === CREATE_NFT_STEPS.addMetaData.index) {
      return setNext({
        ...next,
        disabled: !(name !== null && genre !== null && description !== null && isHashLoaded === true)
      });
    }

    if (step.index === CREATE_NFT_STEPS.mint.index) {
      setNext({
        ...next,
        disabled: !nftId
      });
    }

    if (step.index === CREATE_NFT_STEPS.setPrice.index) {
      setNext({
        ...next,
        disabled: !startPrice
      });
    }
  }, [ file, IPFSHash, description, name, genre, isHashLoaded, nftId, startPrice ]);

  const onNextHandler = () => {
    if (!next) {
      return;
    }

    dispatch(setStep(next));
    searchParams.set(FILTER_PARAMS.STEP, `${next.index}`);
    setSearchParams(searchParams);
  };

  const onPrevHandler = () => {
    if (!back) {
      return;
    }

    const _back: any = Object.values(CREATE_NFT_STEPS).find(
      ({ index }) => back.index === index
    );

    if (!_back) {
      return;
    }

    dispatch(setStep(_back));

    searchParams.set(FILTER_PARAMS.STEP, `${_back.index}`);

    setSearchParams(searchParams);
  };

  return (
    <div className={classes.wrapper}>
      {back && step.index !== CREATE_NFT_STEPS.uploadImage.index ? (
        <Button
          label={back.btnLabel}
          size='small'
          type='secondary'
          variant='outlined'
          className={classes.bottomButton}
          disabled={isLoading || back.disabled}
          onClick={onPrevHandler}
        />
      ) : (
        <div />
      )}
      {next && (
        <Button
          label={next.useThis ? next.title : step.btnLabel}
          size='small'
          className={classes.bottomButton}
          onClick={onNextHandler}
          disabled={isLoading || next.disabled}
        />
      )}
    </div>
  );
};
