import React from 'react';

export const Loader: React.FC<React.SVGAttributes<SVGElement>> = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='200px'
      height='200px'
      viewBox='0 0 100 100'
      preserveAspectRatio='xMidYMid'
      {...props}
    >
      <circle
        cx='50'
        cy='50'
        r='30'
        stroke='rgba(179, 2, 227, 0.3)'
        strokeWidth='7'
        fill='none'
        className='circle_1'
      ></circle>
      <circle
        cx='50'
        cy='50'
        r='30'
        stroke='#C400FF'
        strokeWidth='8'
        fill='none'
        className='circle_2'
      >
        <animateTransform
          attributeName='transform'
          type='rotate'
          repeatCount='indefinite'
          dur='1.2195121951219512s'
          values='0 50 50;180 50 50;720 50 50'
          keyTimes='0;0.5;1'
        ></animateTransform>
        <animate
          attributeName='stroke-dasharray'
          repeatCount='indefinite'
          dur='1.2195121951219512s'
          values='143.25662500369455 45.23893421169302;130.06193585861743 58.43362335677014;143.25662500369455 45.23893421169302'
          keyTimes='0;0.5;1'
        ></animate>
      </circle>
    </svg>
  );
};
