import { useNavigate } from 'react-router-dom';

import { LeftChevron } from 'components/icons';

import classes from './back-button.module.scss';

type Props = {
  text?: string
}

export const BackButton = ({text}: Props) => {
  const navigate = useNavigate();

  return (
    <div className={classes.button__back} onClick={() => navigate(-1)}>
      <LeftChevron />
      {text ? text : 'Back'}
    </div>
  );
};
