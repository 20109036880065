import axios from 'axios';
import { API_DOMAIN } from 'config/urls';

const instance = axios.create({
  baseURL: API_DOMAIN,
  withCredentials: true,
});

export const getAxios = () => {
  return instance;
};
