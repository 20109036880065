import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { ConnectButton, NFTHistory } from 'components/containers';
import {
  Button,
  Loader,
  NFTInfo,
  NFTPlaceBid,
  NFTPreview,
} from 'components/ui';
import { useAppSelector, useAppDispatch, useContract } from 'hooks';
import { clearDetails, getNftDetails } from 'store/nft-list';
import { INDEX_URLS, GET_TARGET_PAGE, URLS, NftStatuses } from 'config';

import classes from './nft-details.module.scss';
import { getPrices } from 'store/currency';
import { Status } from 'store/nft/interface';
import { utils } from 'libs';
import { Tooltip } from 'react-tooltip';
import { addNFTToMetaMask } from 'libs/web3/web3';

export const NFTDetails = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    address,
    userData,
    isLoading: loading,
  } = useAppSelector((state) => state.profileStore);
  const { utils: etherUtils } = useContract();

  const { details, isLoading } = useAppSelector((state) => state.nftListStore);

  const { prices, isLoading: isPriceLoading } = useAppSelector(
    (state) => state.priceStore
  );

  useEffect(() => {
    dispatch(getPrices());

    if (!id) {
      return navigate(URLS.NFT_PAGE);
    }

    dispatch(getNftDetails({ id }));

    return () => {
      dispatch(clearDetails());
    };
  }, [id]);

  const onHandleAction = (action: 'claim' | 'bid' | 'unlist') => {
    if (!details) {
      return;
    }

    const url = {
      claim: INDEX_URLS.CLAIM,
      bid: INDEX_URLS.PLACE_BID,
      unlist: INDEX_URLS.UNLIST,
    };
    // const url = action === 'claim' ? INDEX_URLS.CLAIM : INDEX_URLS.PLACE_BID;

    navigate(GET_TARGET_PAGE(url[action], id!));
  };
  if (loading) {
    return <Loader loading />;
  }

  if (!details) {
    return null;
  }

  const isCreator = userData?.is_creator;
  const isAuctionOwner = details?.auction?.auctionOwner === address;

  const isAuth = !!address;
  //если нет первой ставки берем из price, потом уже из lastBet
  const showPrice = details.auction?.lastBet || details.price || 0;

  const isOwner = details.owner.wallet === address;

  //Показываем блок аукциона только если аукцион есть/был
  const isShowAuctionBlock = details?.auction && !isOwner;

  //Победитель аукциона = аукцион завершился + последняя ставка пользователя
  const isWinner =
    isAuth &&
    !details.auction?.status &&
    (details.auction?.isMyLastBet || isCreator);

  //Блок что НФТ еще не продается = нет и не было аукциона + статус ready
  const notForSaleAndWithoutAuction =
    details.status === NftStatuses.ready && !details.auction && !isOwner;

  //
  const showButtons =
    isAuth &&
    details.status === NftStatuses.ready &&
    (isOwner || details.owner.wallet === 'admin');
  // todo remove it about admin

  const auctionWasNoBids =
    details.auction &&
    (details.status === NftStatuses.claim ||
      details.status === NftStatuses.ready) &&
    !details.auction.lastBet;
  return (
    <div className={classes.nft__details_wrap}>
      <NFTPreview
        //  src={details.image}
        src={details.image}
      />
      <div className={classes.nft_content}>
        <NFTInfo {...details} />

        {isShowAuctionBlock ? (
          <NFTPlaceBid
            address={address}
            loading={isLoading || loading}
            onAction={onHandleAction}
            price={+showPrice}
            isMyBet={details.auction ? details.auction.isMyLastBet : false}
            endTimestamp={details.auction ? details.auction.endTimestamp : null}
            auctionIsLive={details.status === NftStatuses.sale}
            isOwner={isOwner}
            isAuth={isAuth}
            id={id}
            isPriceLoading={isPriceLoading}
            tigerPrice={+prices[details.auction.currency]}
            win={isWinner}
            currency={details.auction.currency}
            ended={!details.auction.status}
            ownerName={utils.shorter(details?.owner?.wallet) || ''}
            isAuctionOwner={isAuctionOwner}
            isCreator={isCreator}
            auctionWasNoBids={auctionWasNoBids}
          />
        ) : null}

        {notForSaleAndWithoutAuction ? (
          <div className={classes.notForSale}>
            <div className={classes.notForSaleText}>
              Item is currently not for sale. Auction coming soon!
            </div>
          </div>
        ) : null}

        {showButtons ? (
          <>
            {!details.canListOrOpen ? (
              <>
                <Tooltip
                  anchorSelect={`#open-box-tooltip`}
                  content='Open box inactive until all NFT sold.'
                  place='top'
                  className={classes.tooltipStyles}
                />
              </>
            ) : null}

            {!details.canListOrOpen && !isCreator ? (
              <>
                <Tooltip
                  anchorSelect={`#list-nft-tooltip`}
                  content='List NFT inactive until all NFT sold.'
                  place='top'
                  className={classes.tooltipStyles}
                />
              </>
            ) : null}

            <div className={classes.buttonRow}>
              <Button
                id={`list-nft-tooltip`}
                label={'List NFT'}
                onClick={() => {
                  if (!details.canListOrOpen && !isCreator) return;
                  navigate(GET_TARGET_PAGE(INDEX_URLS['LIST'], id!));
                }}
                size='small'
                type={'primary'}
                disabled={false}
                full
              />

              {!details.isOpenBox && (
                <>
                  <Button
                    id={`open-box-tooltip`}
                    label={'Open the box'}
                    onClick={() => {
                      if (!details.canListOrOpen) return;
                      navigate(GET_TARGET_PAGE(INDEX_URLS['OPEN_BOX'], id!));
                    }}
                    size='small'
                    type={'primary'}
                    variant={'outlined'}
                    // disabled={!details.canListOrOpen}
                    full
                  />
                </>
              )}
            </div>
            <Button
              id={`list-nft-tooltip`}
              className={classes.importNftBtn}
              label={'Import NFT'}
              onClick={() => {
                addNFTToMetaMask(`${details.nftId}`);
              }}
              size='small'
              type={'primary'}
              variant={'outlined'}
              disabled={false}
              full
            />
          </>
        ) : null}
      </div>
    </div>
  );
};
