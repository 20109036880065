import React from 'react';
import classNames from 'classnames';

import styles from './following-button.module.scss';
import { Button } from 'components/ui';

export interface FollowingButtonProps {
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  isFollow: boolean;
  disabled: boolean;
}

const UnFollowButton = ({ onClick, disabled }: any) => {
  return (
    <Button
      label=''
      className={classNames(styles.Button)}
      onClick={onClick}
      disabled={disabled}
      size='small'
      variant='outlined'
    />
  );
};

export const FollowingButton = ({
  onClick,
  isFollow,
  disabled,
}: FollowingButtonProps) => {
  return (
    <>
      {isFollow ? (
        <UnFollowButton onClick={onClick} disabled={disabled} />
      ) : (
        <Button
          label='Follow'
          size='small'
          onClick={onClick}
          disabled={disabled}
        />
      )}
    </>
  );
};
