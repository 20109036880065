import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IState } from './interface';
import { list } from './actions';
import { DEFAULT_TX_ERROR_TEXT, transactionErrors } from 'config';

const initialState: IState = {
  agreement: false,
  amount: null,
  coefficient: null,
  startDate: null,
  step: null,
  error: null,
  txHash: null,
  isLoading: false,
  isCompleted: false,
};

export const listSlice = createSlice({
  name: 'list',
  initialState,
  reducers: {
    clearState: () => initialState,
    setStep: (state: IState, action: PayloadAction<number | null>) => {
      state.step = action.payload;
    },
    setComplete: (state: IState, action: PayloadAction<boolean>) => {
      state.isCompleted = action.payload;
    },
    setTxHash: (state: IState, action: PayloadAction<string>) => {
      state.txHash = action.payload;
    },
    setAmount: (state: IState, action: PayloadAction<string>) => {
      state.amount = action.payload;
    },
    setCoefficient: (state: IState, action: PayloadAction<string>) => {
      state.coefficient = action.payload;
    },
    setAgreement: (state: IState, action: PayloadAction<boolean>) => {
      state.agreement = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(list.pending, (state: IState) => {
        state.step = 1;
      })
      .addCase(list.fulfilled, (state: IState, action: PayloadAction<any>) => {
        state.isLoading = false;

        if (action?.payload?.error) {
          state.isCompleted = false;
          state.step = 3;
          Object.keys(transactionErrors).forEach((i) => {
            if (!action.payload.error.includes(i)) {
              return (state.error = DEFAULT_TX_ERROR_TEXT);
            } else {
              return (state.error =
                transactionErrors[i as keyof typeof transactionErrors]);
            }
          });
        } else {
          state.isCompleted = true;
          state.step = 3;
        }
      });
  },
});

export const {
  clearState,
  setStep,
  setTxHash,
  setAmount,
  setCoefficient,
  setAgreement,
  setComplete,
} = listSlice.actions;
