import { configureStore } from '@reduxjs/toolkit';

import { authors } from './authors';
import { auction } from './auction';
import { bid } from './bid';
import { profile } from './profile';
import { NFTManager } from './nft-manager';
import { typesApi } from './genre';

import { nftList } from './nft-list';

import { nftsApi } from './nft';
import { ClaimManager } from 'store/claim-manager/reducer';
import { burnSlice } from './burn';
import { unlistSlice } from './unlist';
import { listSlice } from './list';
import { changeReservePriceSlice } from './change-reserve-price';
import { claimSlice } from './claim';
import { nftHistory } from './nft-history';

import { follows } from './follows';
import { priceStore } from './currency';
import { listApi } from './list/api';
import { openBoxStore } from './open-box';

export const storeConfig = configureStore({
  reducer: {
    authorsStore: authors.reducer,
    auctionStore: auction.reducer,
    bidStore: bid.reducer,
    openBoxStore: openBoxStore.reducer,
    profileStore: profile.reducer,
    NFTManagerStore: NFTManager.reducer,
    ClaimManagerStore: ClaimManager.reducer,
    nftListStore: nftList.reducer,
    [typesApi.reducerPath]: typesApi.reducer,
    [nftsApi.reducerPath]: nftsApi.reducer,
    [listApi.reducerPath]: listApi.reducer,
    burn: burnSlice.reducer,
    unlist: unlistSlice.reducer,
    listStore: listSlice.reducer,
    changeReservePrice: changeReservePriceSlice.reducer,
    claim: claimSlice.reducer,
    nftHistory: nftHistory.reducer,
    follows: follows.reducer,
    priceStore: priceStore.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      typesApi.middleware,
      nftsApi.middleware,
      listApi.middleware
    ),
});

export type RootState = ReturnType<typeof storeConfig.getState>;
export type AppDispatch = typeof storeConfig.dispatch;
