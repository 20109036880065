import React, { PropsWithChildren } from 'react';
import cn from 'classnames';

import classes from './grid.module.scss';

interface IProps {
  className?: string;
}

export const Grid: React.FC<PropsWithChildren & IProps> = ({ children, className }) => {
  return <div className={cn(classes.grid, className)}>{children}</div>;
};
