import { useEffect, useRef } from 'react';

import {
  EllipsisIcon,
  ChangePriceIcon,
  CloseIcon,
  BurnIcon,
  InfoIcon,
} from 'components/icons';
import { NftStatuses, INDEX_URLS } from 'config';

import classes from './profile-card-menu.module.scss';
import cn from 'classnames';
import { Tooltip } from 'react-tooltip';

type Props = {
  open: boolean;
  canListOrOpen: boolean;
  setOpen: (bool: boolean) => void;
  onSetAction: (url: string) => void;
  id?: string | number;
  auctionBided: boolean;
  auctionStarted?: boolean;
  status?: NftStatuses;
  isOpenBox?: boolean;
  isOwner?: boolean;
};

export const ProfileCardMenu = ({
  open,
  setOpen,
  onSetAction,
  canListOrOpen,
  id,
  auctionBided,
  auctionStarted,
  status,
  isOpenBox,
  isOwner,
}: Props) => {
  const rootEl = useRef<any>(null);
  const onClick = (e: MouseEvent) => {
    rootEl.current?.contains(e.target) || setOpen(false);
  };

  useEffect(() => {
    document.addEventListener('click', onClick);

    return () => document.removeEventListener('click', onClick);
  }, []);

  const Actions = () => {
    return (
      <div className={classes.more__dropdown}>
        {status === NftStatuses.ready && (
          <div
            className={cn(classes.more__dropdown_item, {
              [classes.more__dropdown_itemDisabled]: false,
            })}
            onClick={() => onSetAction(INDEX_URLS.BURN)}
          >
            <BurnIcon />
            Burn
          </div>
        )}

        {status === NftStatuses.sale && (
          <>
            {auctionBided ? (
              <Tooltip
                anchorSelect={`#tooltip-price-${id}`}
                content='Reserve price change is available only up to the first bid.'
                place='top'
                className={classes.tooltipStyles}
              />
            ) : null}
            <div
              className={cn(classes.more__dropdown_item, {
                [classes.more__dropdown_itemDisabled]: auctionBided,
              })}
              id={`tooltip-price-${id}`}
              onClick={() => {
                if (auctionBided) return;
                onSetAction(INDEX_URLS.CHANGE_RESERVE_PRICE);
              }}
            >
              <ChangePriceIcon />
              Change reserve
            </div>
            <div
              className={cn(classes.more__dropdown_item, {
                // [classes.more__dropdown_itemDisabled]: auctionStarted,
              })}
              onClick={() => onSetAction(INDEX_URLS.UNLIST)}
            >
              <CloseIcon />
              Unlist NFT
            </div>
          </>
        )}

        {!isOpenBox ? (
          <>
            {!canListOrOpen ? (
              <Tooltip
                anchorSelect={`#my-anchor-element-${id}`}
                content='Open box inactive until all NFT sold.'
                place='top'
                className={classes.tooltipStyles}
              />
            ) : null}

            <div
              className={cn(classes.more__dropdown_item, {
                [classes.more__dropdown_itemDisabled]: !canListOrOpen,
              })}
              id={`my-anchor-element-${id}`}
              onClick={() =>
                canListOrOpen ? onSetAction(INDEX_URLS.OPEN_BOX) : {}
              }
            >
              <ChangePriceIcon />
              Open box
            </div>
          </>
        ) : null}

        {/* {canListOrOpen && (
          <div className={classes.warning}>
            <InfoIcon />
            Open box inactive until all NFTs sold.
          </div>
        )} */}
      </div>
    );
  };

  return (
    <div
      ref={rootEl}
      className={classes.more_wrap}
      onClick={(e) => e.stopPropagation()}
    >
      <button onClick={() => setOpen(!open)} className={classes.more__button}>
        <EllipsisIcon />
      </button>
      {open && <Actions />}
    </div>
  );
};
