import React from 'react';

export const Redirect = ({ fill = '#f9f9f9' }) => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2 18C1.45 18 0.979 17.8043 0.587 17.413C0.195667 17.021 0 16.55 0 16V2C0 1.45 0.195667 0.979 0.587 0.587C0.979 0.195667 1.45 0 2 0H8C8.28333 0 8.521 0.0956666 8.713 0.287C8.90433 0.479 9 0.716667 9 1C9 1.28333 8.90433 1.52067 8.713 1.712C8.521 1.904 8.28333 2 8 2H2V16H16V10C16 9.71667 16.096 9.479 16.288 9.287C16.4793 9.09567 16.7167 9 17 9C17.2833 9 17.5207 9.09567 17.712 9.287C17.904 9.479 18 9.71667 18 10V16C18 16.55 17.8043 17.021 17.413 17.413C17.021 17.8043 16.55 18 16 18H2ZM6 12C5.81667 11.8167 5.725 11.5833 5.725 11.3C5.725 11.0167 5.81667 10.7833 6 10.6L14.6 2H12C11.7167 2 11.4793 1.904 11.288 1.712C11.096 1.52067 11 1.28333 11 1C11 0.716667 11.096 0.479 11.288 0.287C11.4793 0.0956666 11.7167 0 12 0H17C17.2833 0 17.5207 0.0956666 17.712 0.287C17.904 0.479 18 0.716667 18 1V6C18 6.28333 17.904 6.52067 17.712 6.712C17.5207 6.904 17.2833 7 17 7C16.7167 7 16.4793 6.904 16.288 6.712C16.096 6.52067 16 6.28333 16 6V3.4L7.375 12.025C7.19167 12.2083 6.96667 12.3 6.7 12.3C6.43333 12.3 6.2 12.2 6 12Z'
        fill={fill}
      />
    </svg>
  );
};
