import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { DropDown } from 'components/ui';
import { FILTER_PARAMS } from 'config';

import { getStatusFilter, filter } from './config';
import classes from './nft-filter-status.module.scss';
import cn from 'classnames';

export const NFTFilterStatus: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const [filterName, setFilterName] = useState('All');

  const onHandleClick = ({
    label,
    value,
  }: {
    label: string;
    value: string;
  }) => {
    setFilterName(label);

    if (value === filter[0].label) {
      searchParams.delete(FILTER_PARAMS.STATUS);
    } else {
      searchParams.set(FILTER_PARAMS.STATUS, value);
    }

    setSearchParams(searchParams);
  };

  const filterIsActive = () => {
    return searchParams.get(FILTER_PARAMS.STATUS);
  };

  return (
    <div
      className={cn(classes.dropdown, {
        [classes.is_active]: filterIsActive(),
      })}
    >
      <DropDown
        open={open}
        setOpen={setOpen}
        label={filterName}
        options={getStatusFilter(onHandleClick)}
        classes={classes.dropdownOptions}
        value={searchParams.get(FILTER_PARAMS.STATUS) || filter[0].label}
        closeOnClick={true}
        type='secondary'
      />
    </div>
  );
};
