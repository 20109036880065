import React from 'react';
import cn from 'classnames';

import classes from './paper.module.scss';

interface IProps {
  className?: string;
  children: React.ReactNode
}

export const Paper = ({ children, className = '' }: IProps) => {
  return(
    <div className={ cn(classes.paper, className) }>
      {
        children
      }
    </div>
  );
}
