import React, { useEffect, useState } from 'react';
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik';
import cn from 'classnames';

import { updateUserData, checkUsername, setUserChanges } from 'store/profile';
import { useAppDispatch, useAppSelector, useDebounce2 } from 'hooks';
import { API_DOMAIN, API_METHODS } from 'config';
import ValidationSchema from './schema';
import { Input, Button, Message, MessageType, TextArea } from 'components/ui';
import { VerifyTick, At, Instagram, Twitter } from 'components/icons';
import { Upload } from 'components/containers';
import { IProfileChanges } from 'store/profile/types';

import classes from './edit-profile-form.module.scss';

export const EditProfileForm: React.FC = () => {
  const ref = React.useRef<any>(null);
  const dispatch = useAppDispatch();
  const debounce = useDebounce2(500);
  const [textareaCount, setTextareaCount] = useState<any>(0);
  const {
    isLoading,
    userChanges,
    userNameIsAvailable,
    userData,
    address,
    isCheckingUsernameLoading,
  } = useAppSelector((state) => state.profileStore);
  const [textareaValue, setTextareaValue] = useState(
    userData?.bio ? userData?.bio : ''
  );
  const object = { ...userData, ...userChanges };
  const finalObject = {
    ...object,
    meta: object.meta ? object.meta : {},
  };

  const handleOnSubmit = async () => {
    if (!address) {
      return;
    }

    dispatch(updateUserData());
  };

  useEffect(() => {
    setTextareaCount(textareaValue.length);
  }, [textareaValue]);

  // const onHandlePreview = () => {
  //   if (!address) {
  //     return;
  //   }

  //   dispatch(updateShowPreview(true));
  //   navigate(`/profile/${ address }`);
  // };

  const onHandleChange = async (params: IProfileChanges) => {
    const { username, ...props } = params;

    if (typeof username === 'string' && username.length > 3) {
      await debounce();

      if (username !== userData.username) {
        return dispatch(checkUsername(username));
      }

      return dispatch(setUserChanges({ username: null }));
    }

    dispatch(setUserChanges(props));
  };

  const onHandleClear = (key: string) => {
    const form = ref.current;

    form.setFieldValue(key, '');
    dispatch(
      setUserChanges({
        [key]: null,
      })
    );
  };

  return (
    <Formik
      initialValues={finalObject}
      onSubmit={handleOnSubmit}
      validationSchema={() => ValidationSchema()}
      innerRef={ref}
    >
      {({ errors, values }: FormikProps<any>) => {
        return (
          <Form
            className='profile-form'
            onInput={({ target }: any) =>
              onHandleChange({ [target.name]: target.value })
            }
          >
            <div className={classes.Row}>
              <div className={classes.Column}>
                <h3 className={classes.Title}>Enter your details</h3>
              </div>
              <div className={classes.Column}>
                <div className={cn(classes.FieldWrap, classes.mb30)}>
                  <Field name='first_name'>
                    {({ field, meta: { touched, error } }: FieldProps) => (
                      <Input
                        {...field}
                        label='Name*'
                        placeHolder='Enter your name'
                        message={`Only latin letters, numbers and dots. Don’t use more than 30 characters`}
                        errorMessage={error ? error : undefined}
                        clearClick={() => {
                          onHandleClear('first_name');
                        }}
                      />
                    )}
                  </Field>
                </div>
                <div className={classes.FieldWrap}>
                  <div className='form__field_id'>
                    <Field name='username'>
                      {({ field, meta }: FieldProps) => (
                        <>
                          <Input
                            {...field}
                            label='Username*'
                            placeholder='Username'
                            message={`Only latin letters, numbers and dots. Don’t use more than 30 characters`}
                            errorMessage={meta.error ? meta.error : undefined}
                            leftIcon={<At />}
                            disabled={isCheckingUsernameLoading}
                            clearClick={() => {
                              onHandleClear('username');
                            }}
                          />
                          {(userChanges.username ||
                            userChanges.username === null) &&
                            !meta.error && (
                              <Message
                                type={
                                  userNameIsAvailable
                                    ? MessageType.success
                                    : MessageType.error
                                }
                                text={`Username is ${
                                  userNameIsAvailable
                                    ? 'available'
                                    : 'unavailable'
                                }`}
                              />
                            )}
                        </>
                      )}
                    </Field>
                  </div>
                </div>
              </div>
            </div>

            <div className={classes.Row}>
              <div className={classes.Column}>
                <h3 className={classes.Title}>Get email notifications</h3>
                <p className={classes.Description}>
                  Add your email address to start getting notifications about
                  your activity on KALI. Your email won’t be available to other
                  users.
                </p>
              </div>
              <div className={classes.Column}>
                <div className={classes.FieldWrap}>
                  <Field name='email'>
                    {({ field, meta: { touched, error } }: FieldProps) => (
                      <Input
                        type='email'
                        label='Email*'
                        errorMessage={error ? error : undefined}
                        placeHolder='Enter your email'
                        clearClick={() => {
                          onHandleClear('email');
                        }}
                        {...field}
                      />
                    )}
                  </Field>
                </div>
              </div>
            </div>

            <div className={classes.Row}>
              <div className={classes.Column}>
                <h3 className={classes.Title}>Add a short bio </h3>
              </div>
              <div className={classes.Column}>
                <Field name='bio'>
                  {({ field, meta: { touched, error } }: FieldProps) => (
                    <TextArea
                      {...field}
                      label='Bio*'
                      errorMessage={error ? error : undefined}
                      maxLength={200}
                      placeholder={'Enter your bio'}
                      // value={field.value}
                      valueCounter={true}
                      onChange={(e) => {
                        field.onChange(e);
                        setTextareaValue(e.target.value);
                      }}
                    />
                  )}
                </Field>
              </div>
            </div>

            <div className={classes.Row}>
              <div className={classes.Column}>
                <h3 className={classes.Title}>Upload a profile image</h3>
                <p className={classes.Description}>
                  Recommended size: 500x500px
                </p>
              </div>
              <div className={classes.Column}>
                <Upload
                  name='image'
                  maxFileSize={10485760}
                  onHandleChange={onHandleChange}
                  image={userChanges?.image || userData?.image || ''}
                  isChanged={!!userChanges.image}
                  message={`Valid formats: JPG, PNG or GIF. 10 MB max size`}
                />
              </div>
            </div>

            <div className={classes.Row}>
              <div className={classes.Column}>
                <h3 className={classes.Title}>Upload a cover image</h3>
                <p className={classes.Description}>
                  Recommended size: 1000x200px
                </p>
              </div>
              <div className={classes.Column}>
                <Upload
                  name='cover_image'
                  maxFileSize={10485760}
                  aspectType='rectangle'
                  onHandleChange={onHandleChange}
                  image={
                    userChanges?.cover_image || userData?.cover_image || ''
                  }
                  isChanged={!!userChanges.cover_image}
                  message={`Valid formats: JPG, PNG or GIF. 10 MB max size`}
                />
              </div>
            </div>

            {/* <div className={classes.Row}>
              <div className={classes.Column}>
                <h3 className={cn(classes.Title, classes.title__verify)}>
                  Verify your profile <VerifyTick />{' '}
                </h3>
                <p className={classes.Description}>
                  Show the KALI Art Gallery community that your profile is
                  authentic. It’s not a must, but helps.
                </p>
                <span className='form__span'>
                    It’s not a must, but helps.
                  </span>
              </div>
              <div className={classes.Column}>
                <div>
                  <Field name='verify__instagram'>
                    {() => (
                      <>
                        <a
                          className={classes.verify__link}
                          href={ `${ API_DOMAIN }${ API_METHODS.VERIFY_TWITTER }` }
                          target='_blank'
                        >
                          <Twitter className={classes.verify__icon} />
                          {!values.twitter && 'Verify via twitter'}
                          {values.twitter && (
                            <>
                              {values.twitter}
                              <VerifyTick
                                className={classes.verify__verifyIcon}
                              />
                            </>
                          )}
                        </a>
                        <a
                          className={classes.verify__link}
                          href={ `${ API_DOMAIN }${ API_METHODS.VERIFY_INSTAGRAM }` }
                          target='_blank'
                        >
                          <Instagram className={classes.verify__icon} />
                          {!values.instagram && 'Verify via instagram'}
                          {values.instagram && (
                            <>
                              {values.instagram}
                              <VerifyTick
                                className={classes.verify__verifyIcon}
                              />
                            </>
                          )}
                        </a>
                      </>
                    )}
                  </Field>
                </div>
              </div>
            </div> */}

            <div className={classes.Row}>
              <div className={classes.Column}>
                <h3 className={classes.Title}>
                  Add links to your social media profiles
                </h3>
                <p className={cn(classes.Description, classes.SecondType)}>
                  It’s optional, but also helps.
                </p>
              </div>
              <div className={classes.Column}>
                <div className={classes.socials}>
                  <div className={classes.FieldWrap}>
                    <Field name='meta.website'>
                      {({ field, meta }: FieldProps) => (
                        <Input
                          {...field}
                          type='text'
                          errorMessage={meta.error ? meta.error : undefined}
                          label={'Website'}
                          placeholder={'Enter URL'}
                          clearClick={() => {
                            onHandleClear('meta.website');
                          }}
                        />
                      )}
                    </Field>
                  </div>

                  <div className={classes.FieldWrap}>
                    <Field name='meta.twitter'>
                      {({ field, meta }: FieldProps) => (
                        <Input
                          {...field}
                          type='text'
                          errorMessage={
                            meta.touched && meta.error
                              ? 'Wrong format'
                              : undefined
                          }
                          placeholder='Enter your username'
                          label='Twitter'
                          leftIcon={<At />}
                          clearClick={() => {
                            onHandleClear('meta.twitter');
                          }}
                        />
                      )}
                    </Field>
                  </div>

                  <div className={classes.FieldWrap}>
                    <Field name='meta.instagram'>
                      {({ field, meta }: FieldProps) => (
                        <Input
                          {...field}
                          type='text'
                          errorMessage={
                            meta.touched && meta.error
                              ? 'Wrong format'
                              : undefined
                          }
                          label='Instagram'
                          placeholder='Enter your username'
                          leftIcon={<At />}
                          clearClick={() => {
                            onHandleClear('meta.instagram');
                          }}
                        />
                      )}
                    </Field>
                  </div>

                  <div className={classes.FieldWrap}>
                    <Field name='meta.facebook'>
                      {({ field, meta }: FieldProps) => (
                        <Input
                          {...field}
                          type='text'
                          errorMessage={
                            meta.touched && meta.error
                              ? 'Wrong format'
                              : undefined
                          }
                          label='Facebook'
                          placeholder='Enter your username'
                          leftIcon={<At />}
                          clearClick={() => {
                            onHandleClear('meta.facebook');
                          }}
                        />
                      )}
                    </Field>
                  </div>

                  <div className={classes.FieldWrap}>
                    <Field name='meta.discord'>
                      {({ field, meta }: FieldProps) => (
                        <Input
                          {...field}
                          type='text'
                          errorMessage={
                            meta.touched && meta.error
                              ? 'Wrong format'
                              : undefined
                          }
                          label='Discord'
                          placeholder='Enter your username'
                          leftIcon={<At />}
                          clearClick={() => {
                            onHandleClear('meta.discord');
                          }}
                        />
                      )}
                    </Field>
                  </div>

                  <div className={classes.FieldWrap}>
                    <Field name='meta.youtube'>
                      {({ field, meta }: FieldProps) => (
                        <Input
                          {...field}
                          type='text'
                          errorMessage={
                            meta.touched && meta.error
                              ? 'Wrong format'
                              : undefined
                          }
                          label='Youtube'
                          placeholder='Enter your username'
                          leftIcon={<At />}
                          clearClick={() => {
                            onHandleClear('meta.youtube');
                          }}
                        />
                      )}
                    </Field>
                  </div>

                  <div className={classes.FieldWrap}>
                    <Field name='meta.tiktok'>
                      {({ field, meta }: FieldProps) => (
                        <Input
                          {...field}
                          type='text'
                          errorMessage={
                            meta.touched && meta.error
                              ? 'Wrong format'
                              : undefined
                          }
                          label='TikTok'
                          placeholder='Enter your username'
                          leftIcon={<At />}
                          clearClick={() => {
                            onHandleClear('meta.tiktok');
                          }}
                        />
                      )}
                    </Field>
                  </div>

                  <div className={classes.FieldWrap}>
                    <Field name='meta.twitch'>
                      {({ field, meta }: FieldProps) => (
                        <Input
                          {...field}
                          type='text'
                          errorMessage={
                            meta.touched && meta.error
                              ? 'Wrong format'
                              : undefined
                          }
                          label='Twitch'
                          placeholder='Enter your username'
                          leftIcon={<At />}
                          clearClick={() => {
                            onHandleClear('meta.twitch');
                          }}
                        />
                      )}
                    </Field>
                  </div>

                  <div className={classes.FieldWrap}>
                    <Field name='meta.snapchat'>
                      {({ field, meta }: FieldProps) => (
                        <Input
                          {...field}
                          type='text'
                          errorMessage={
                            meta.touched && meta.error
                              ? 'Wrong format'
                              : undefined
                          }
                          label='Snapchat'
                          placeholder='Enter your username'
                          leftIcon={<At />}
                          clearClick={() => {
                            onHandleClear('meta.snapchat');
                          }}
                        />
                      )}
                    </Field>
                  </div>
                </div>
              </div>
            </div>

            <div className={cn(classes.Row, classes.buttons)}>
              {/* <div className={cn(classes.Column, classes.button_wrap)}>
                <Button
                  disabled={ isLoading || !Object.keys(userChanges).length }
                  onClick={ onHandlePreview }
                  label='Preview profile'
                  type='secondary'
                  variant='outlined'
                  className={ classes.resultButton }
                />
              </div> */}
              <Button
                disabled={isLoading || !Object.keys(userChanges).length}
                onClick={handleOnSubmit}
                label='Save Changes'
                className={classes.resultButton}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
