export const listingSteps = [
  {
    title: 'Confirm transaction',
    description: 'Once you have set a reserve price, you need to confirm two transactions with your wallet to continue. The first will allow us to move your NFT to our smartcontract, the second will list the NFT for auction on the platform.'
  },
  {
    title: 'Your NFT is being listed...',
    description: 'Once you have confirmed the transactions, your NFT has been submitted to our marketplace and will be live as soon as the transaction is processed. You can leave this page if you like.'
  },
  {
    title: 'Your NFT has been listed!',
    description: 'Congratulations! Your NFT has successfully been listed on our marketplace.'
  }
]
