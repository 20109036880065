export const BidIcon = () => {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask
        id='mask0_428_4935'
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='32'
        height='32'
      >
        <rect width='32' height='32' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_428_4935)'>
        <path
          d='M6.63301 28C6.25523 28 5.94412 27.872 5.69967 27.616C5.45523 27.3609 5.33301 27.0445 5.33301 26.6667C5.33301 26.2889 5.46056 25.9725 5.71567 25.7174C5.97167 25.4613 6.28856 25.3333 6.66634 25.3333H20.033C20.4108 25.3333 20.7219 25.4613 20.9663 25.7174C21.2108 25.9725 21.333 26.2889 21.333 26.6667C21.333 27.0445 21.2055 27.3609 20.9503 27.616C20.6943 27.872 20.3775 28 19.9997 28H6.63301ZM10.9663 19.6333L7.19967 15.8667C6.68856 15.3556 6.42723 14.7276 6.41567 13.9827C6.40501 13.2387 6.65523 12.6111 7.16634 12.1L8.13301 11.1334L15.733 18.6667L14.7663 19.6333C14.2552 20.1445 13.6219 20.4 12.8663 20.4C12.1108 20.4 11.4775 20.1445 10.9663 19.6333ZM21.333 13.0667L13.7997 5.46668L14.7663 4.50002C15.2775 3.98891 15.9055 3.73868 16.6503 3.74935C17.3943 3.76091 18.0219 4.02224 18.533 4.53335L22.2997 8.30002C22.8108 8.81113 23.0663 9.44446 23.0663 10.2C23.0663 10.9556 22.8108 11.5889 22.2997 12.1L21.333 13.0667ZM26.533 25.7333L10.0663 9.26668L11.933 7.40002L28.433 23.9C28.6775 24.1445 28.7943 24.4498 28.7837 24.816C28.7721 25.1831 28.6441 25.4889 28.3997 25.7333C28.1552 25.9778 27.8441 26.1 27.4663 26.1C27.0886 26.1 26.7775 25.9778 26.533 25.7333Z'
          fill='#fff'
        />
      </g>
    </svg>
  );
};
