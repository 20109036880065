import React from 'react';

export const YoutubeIcon = () => {
  return (
    <svg
      width='20'
      height='16'
      viewBox='0 0 20 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.244 0C10.778 0.003 12.114 0.016 13.534 0.073L14.038 0.0949998C15.467 0.162 16.895 0.278 17.604 0.475C18.549 0.741 19.291 1.515 19.542 2.497C19.942 4.057 19.992 7.099 19.998 7.836L19.999 7.988V8.162C19.992 8.899 19.942 11.942 19.542 13.501C19.288 14.486 18.545 15.261 17.604 15.523C16.895 15.72 15.467 15.836 14.038 15.903L13.534 15.926C12.114 15.982 10.778 15.996 10.244 15.998L10.009 15.999H9.754C8.624 15.992 3.898 15.941 2.394 15.523C1.45 15.257 0.707 14.483 0.456 13.501C0.0560001 11.941 0.006 8.899 0 8.162V7.836C0.006 7.099 0.0560001 4.056 0.456 2.497C0.71 1.512 1.453 0.737 2.395 0.476C3.898 0.0569998 8.625 0.006 9.755 0H10.244ZM7.999 4.5V11.5L13.999 8L7.999 4.5Z'
        fill='#F9F9F9'
      />
    </svg>
  );
};
