import { createAsyncThunk } from '@reduxjs/toolkit';

import { API_METHODS } from 'config';
import { axios } from 'libs';
import { IGetListProps } from 'store/nft-list';

const _axios = axios.getAxios();

export const getAuctions = createAsyncThunk(
  'auctionStore/getAuctions',
  async (args: IGetListProps, { rejectWithValue }) => {
    try {
      const url = `${API_METHODS.GET_ALL_AUCTION_LIST}${
        Object.keys(args).length > 0 ? `?${new URLSearchParams(args)}` : '/'
      }`;
      const { data = [] } = await _axios.get(url);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getUpcomingAuction = createAsyncThunk(
  'auctionStore/getUpcomingAuction',
  async (args, { rejectWithValue }) => {
    try {
      const { data } = await _axios.get(API_METHODS.GET_UPCOMING_AUCTION);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDelayedAuction = createAsyncThunk(
  'auctionStore/getDelayedAuction',
  async (args, { rejectWithValue }) => {
    try {
      const { data } = await _axios.get(API_METHODS.GET_DELAYED_AUCTION);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
