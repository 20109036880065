export enum Currency {
  BabyBNBTiger = 'BabyBNBTiger',
  BNB = 'BNB',
}
export interface ICurrency {
  isLoading: boolean;
  prices: TPrices;
}

export type TCurrency = Currency;

export type TPrices = {
  BabyBNBTiger: string | number;
  BNB: string | number;
};
