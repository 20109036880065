import React, { useState, useRef } from 'react';
import cn from 'classnames';

import { Loader, ImageCrop, Message, MessageType } from 'components/ui';
import {
  Close,
  ArrowUpload,
  UploadPic,
  MessageError,
  MessageNeutral,
  Upload,
} from 'components/icons';
import { fileManager } from 'libs';

import { IFileUploadProps } from './interface';
import classes from './file-upload.module.scss';

export const FileUpload: React.FC<IFileUploadProps> = (params) => {
  const {
    id,
    maxFileSize = 2097152,
    isLoading,
    image = '',
    className,
    onChange,
    onDelete,
    aspectType,
    isChanged,
    message,
    ...props
  } = params;
  const ref: any = useRef(null);
  const [cropOpened, setCropOpened] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [file, setFile] = useState<string | null>(null);

  const _onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    const files = [...e.dataTransfer.files];

    _onFinished(files[0]);
  };

  const _onChange = async ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const file = target.files?.[0];

    if (!file) {
      return;
    }

    _onFinished(file);
  };

  const _onFinished = async (file: File) => {
    if (!ref.current) {
      return;
    }

    ref.current.value = null;

    setError(null);
    setCropOpened(true);

    if (file.size > maxFileSize) {
      return onHandleError(`Invalid format: max 10 MB`);
    }

    const _file = await fileManager.compressImage({ file });

    if (!_file) {
      return onHandleError(`Upload error. Can't compress file`);
    }

    setFile(_file);
  };

  const onSuccessCrop = (file: File | Blob | null) => {
    if (!file) {
      return;
    }

    onHandleCanceled();

    if (onChange) {
      onChange(file, id);
    }
  };

  const onHandleCanceled = () => {
    setCropOpened(false);
    setFile(null);
  };

  const onHandleError = (message: string) => {
    setError(message);
    onHandleCanceled();
  };

  const handleDelete: React.MouseEventHandler<HTMLDivElement> = () => {
    if (onDelete) {
      onDelete(id);
    }
  };

  const error1 = 'asdasdasd';

  return (
    <>
      <div
        className={cn(classes.upload, className)}
        onDrop={_onDrop}
        onDragOver={(e) => e.preventDefault()}
      >
        <label className={classes.upload__label} htmlFor={id}>
          <div className={classes.upload__picture}>
            <Loader loading={isLoading} theme='dark'>
              {image ? <img src={image} alt='image' /> : <UploadPic />}
            </Loader>
          </div>
          <div className={classes.upload__text}>
            Drag and drop an image here, or click to browse.
          </div>
          {/* <Upload className={classes.upload__icon} /> */}
        </label>

        {image && isChanged && (
          <div className={classes.icon} onClick={handleDelete}>
            <Close />
          </div>
        )}

        {!image && (
          <div className={classes.icon}>
            <ArrowUpload />
          </div>
        )}

        <input
          id={id}
          ref={ref}
          className={classes.upload__input}
          type='file'
          onChange={_onChange}
          accept='.jpg, .jpeg, .png, .gif'
          {...props}
        />
      </div>
      <div style={{ width: '100%' }} className={classes.message}>
        {error ? ( 
          <Message type={MessageType.error} text={error} />
        ) : message ? (
          <Message type={MessageType.neutral} text={message} />
        ) : null}
      </div>
      <ImageCrop
        aspectType={aspectType}
        onCancel={onHandleCanceled}
        onSuccessCrop={onSuccessCrop}
        onFailedCrop={onHandleError}
        open={cropOpened}
        img={file}
      />
    </>
  );
};
