import React from 'react';

export const ArtIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.15599 4.59938C8.14951 4.0516 9.11412 3.8692 9.95069 4.09984C9.90452 4.18191 9.84004 4.28095 9.75634 4.40352C9.7303 4.44165 9.70238 4.48209 9.67324 4.52429C9.58708 4.64908 9.49031 4.78923 9.40034 4.93036C9.16621 5.29762 8.89521 5.79212 8.89258 6.3292C8.88964 6.93021 9.21538 7.45286 9.88089 7.8558C10.4263 8.18616 11.0464 8.00909 11.5322 7.76597C12.0412 7.51125 12.5795 7.09733 13.0887 6.66467C13.4298 6.37488 13.7781 6.05858 14.1073 5.75972C14.2695 5.61243 14.4271 5.46938 14.5768 5.33582C15.0493 4.91435 15.4368 4.59395 15.7239 4.4326C16.2217 4.15569 16.8992 3.99625 17.5428 4.13406C18.1604 4.26634 18.8056 4.68396 19.2613 5.68014L19.2614 5.68034C19.5478 6.30587 19.7171 7.22717 19.7203 8.33205C19.7236 9.42924 19.5629 10.6738 19.2188 11.9262C18.5277 14.4412 17.1181 16.9165 14.9008 18.3215C10.3508 21.1862 4.71685 19.693 2.78487 15.3491C1.67942 12.8574 2.51874 9.72216 4.22602 7.32848C5.07241 6.1418 6.10598 5.1783 7.15599 4.59938ZM16.7249 8.86819L16.7248 8.86806C16.5867 8.51926 16.3369 8.2723 16.0131 8.14269C15.7064 8.01988 15.3694 8.01607 15.0534 8.06784C14.4247 8.17083 13.7282 8.51804 13.1174 8.95864C12.502 9.40259 11.9224 9.9781 11.5474 10.5897C11.1819 11.1859 10.9486 11.9268 11.2552 12.6155C11.58 13.3453 12.2679 13.6143 12.9521 13.6055C13.6222 13.5968 14.3556 13.3324 14.9925 12.9269C16.2309 12.1385 17.4049 10.5869 16.7249 8.86819Z'
        stroke='#D3D2D4'
      />
    </svg>
  );
};
