import { createAsyncThunk } from '@reduxjs/toolkit';

import { getContract } from 'hooks';
import { logger } from 'libs';

import { setStep, setTxHash } from './reducer';

export const claim = createAsyncThunk(
  'claimStore/claim',
  async ({ nftId }: { nftId: string | number }, { dispatch }) => {
    const { auctionContract } = getContract();
    dispatch(setStep(2));

    try {
      const claimed = await auctionContract.claimWonNFT(nftId);
      dispatch(setStep(3));
      dispatch(setTxHash(claimed.hash));

      await claimed.wait();

      logger.info(`[ NFT IS CLAIMED ]`);

      return {};
    } catch (error: any) {
      console.log({ error });

      return {
        error: error.reason,
      };
    }
  }
);
