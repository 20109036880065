export const changeReservePriceSteps = [
  {
    title: 'Transaction confirmation',
    description:
      'Confirm this transaction to proceed. The reserve price will be updated for the auction.',
  },
  {
    title: 'The reserve price will soon be changed',
    description:
      'Your transaction has been submitted. The reserve price for your NFT will be changed as soon as the transaction is processed. It may take up to...',
  },
  {
    title: 'Reserve price changed',
    description: 'The reserve price for your NFT has been changed.',
  },
];
