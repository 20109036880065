import React, { useEffect, useRef } from 'react';
import Countdown from 'react-countdown';

import { utils } from 'libs';

interface IProps {
  endTimestamp: number | null;
  renderCompleted?: boolean;
  renderDays?: boolean;
  customNumbersCss?: string;
  customSeparatorCss?: string;
  endTimerCb?: () => void;
  fullText?: boolean;
  separator?: boolean;
}

export const Timer = ({
  endTimestamp,
  renderCompleted = false,
  renderDays = false,
  customNumbersCss,
  customSeparatorCss,
  endTimerCb,
  fullText,
  separator,
}: IProps) => {
  if (!endTimestamp) {
    return null;
  }
  const countdownRef: any = useRef(null);
  // console.log('countdownRef', countdownRef);

  const render = ({ days, hours, minutes, seconds, completed }: any) => {
    if (completed && !renderCompleted) {
      return null;
    }

    return (
      <>
        {renderDays ? (
          <span className={customNumbersCss}>
            {days}
            <span>{fullText ? 'days' : 'd'}</span>{' '}
          </span>
        ) : null}

        <span className={customNumbersCss}>
          {utils.formatTime(hours)}
          <span>{fullText ? 'hours' : 'h'}</span>{' '}
        </span>
        {separator ? <div className={customSeparatorCss}>:</div> : null}
        <span className={customNumbersCss}>
          {utils.formatTime(minutes)}
          <span>{fullText ? 'minutes' : 'm'}</span>{' '}
        </span>
        {separator ? <div className={customSeparatorCss}>:</div> : null}

        <span className={customNumbersCss}>
          {utils.formatTime(seconds)}
          <span>{fullText ? 'seconds' : 's'}</span>
        </span>
      </>
    );
  };

  return (
    <Countdown
      ref={countdownRef}
      date={new Date(0).setUTCSeconds(endTimestamp)}
      daysInHours
      renderer={render}
      onComplete={endTimerCb}
      autoStart
    />
  );
};
