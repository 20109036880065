import React, { useEffect, useState } from 'react';

export const useDebounce = (value: string, delay = 500): string => {
  const [debounced, setDebounced] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => setDebounced(value), delay);

    return () => clearTimeout(handler);
  }, [value, delay]);

  return debounced;
};

export const useDebounce2 = (delay = 300) => {
  const timer = React.useRef<NodeJS.Timeout>();

  return React.useCallback(() => {
    if (timer.current) clearTimeout(timer.current);
    return new Promise((resolve) => {
      timer.current = setTimeout(resolve, delay);
    });
  }, [delay]);
};

export const delay = (delay = 300) => {
  return new Promise(resolve => {
    setTimeout(resolve, delay);
  });
}
