import { URLS } from 'config';

export const tabs = [
  {
    label: 'Created',
    state: 'created',
    emptyTitle: 'You haven’t minted any NFT yet.',
    emptyText: 'In order to mint an NFT click on “Create NFT” in your wallet.',
    buttonLink: null,
    buttonText: 'Explore NFT',
    learnMore: URLS.FAQ_PAGE,
  },
  {
    label: 'Collected',
    state: 'collected',
    emptyTitle: 'Your collection is empty',
    emptyText:
      'Start gathering your collection by placing bids on an NFT or mint NFT yourself.',
    buttonLink: null,
    buttonText: null,
    learnMore: null,
  },
  {
    label: 'Listed',
    state: 'listed',
    emptyTitle: 'You haven’t listed anything yet.',
    emptyText: 'You can list any NFT you have created or collected.',
    buttonLink: null,
    buttonText: null,
    learnMore: null,
  },
];
