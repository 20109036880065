import React, { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';

import {
  CloseIcon,
  MetamaskIcon,
  CoinbaseIcon,
  WalletConnectIcon,
} from 'components/icons';
import { ConnectModalLoader } from 'components/ui';
import {
  utils,
  web3,
  logger,
  writeToLocalStorage,
  getFromLocalStorage,
  removeFromLocalStorage,
  tryDeactivateConnector,
  getConnection,
  ConnectionType,
  tryActivateConnector,
  switchNetwork,
  getHasMetaMaskExtensionInstalled,
} from 'libs';
import {
  LAST_CONNECT_KEY,
  CACHED_WALLET_KEY,
  NETWORK,
  SIGN_MESSAGES,
  WALLETS,
} from 'config';
import { useAppDispatch, useAppSelector, useContract } from 'hooks';
import {
  login,
  clearProfile,
  setLoading,
  setOpenConnector,
} from 'store/profile';

import classes from './connect-modal.module.scss';
import { connectMetamaskMobile, isMobile } from 'libs/utils/utils';

export interface ILastConnection {
  connector: ConnectionType;
  signature: string;
}

export const ConnectModal: React.FC = () => {
  const {
    setContracts,
    clearContracts,
    utils: contractUtils,
    units,
  } = useContract();
  const dispatch = useAppDispatch();
  const { chainId, account, provider, isActive, hooks } = useWeb3React();

  const { usePriorityConnector, usePriorityAccount } = hooks;
  const { activate, deactivate } = usePriorityConnector();

  const { isLoading, address, signature, openConnector, isMetamaskInstalled } =
    useAppSelector((state) => state.profileStore);

  useEffect(() => {
    if (address) {
      dispatch(setLoading(false));

      return;
    }

    logger.info('connecting...');

    const lastConnection: ILastConnection =
      getFromLocalStorage(LAST_CONNECT_KEY);

    if (!lastConnection) {
      onLogout(`We haven't last connection.`);
      return;
    }

    const connector = getConnection(
      ConnectionType[lastConnection.connector]
    ).connector;

    if (!connector) {
      onLogout(
        `Sign in error: we can't find wallet for reconnect: exist: ${lastConnection.connector}`
      );
      return;
    }

    connector?.activate?.();
  }, []);

  useEffect(() => {
    if (address && account && address !== account) {
      logger.info(`Account changed: from ${address} to ${account}`);

      const _lastConnect: ILastConnection =
        getFromLocalStorage(LAST_CONNECT_KEY);

      if (!_lastConnect) {
        onLogout(`We can't change account, we haven't last connection.`);
        return;
      }

      removeFromLocalStorage(LAST_CONNECT_KEY);
      writeToLocalStorage(CACHED_WALLET_KEY, _lastConnect.connector);
    } else {
      logger.info(`We get new account: ${account}`);
    }

    if (address && !account) {
      onLogout('failed connect');
    }

    if (account) {
      onLogin();
    }
  }, [account, chainId]);

  const onLogout = async (error: string) => {
    const _lastConnect: ILastConnection = getFromLocalStorage(LAST_CONNECT_KEY);
    const connector = getConnection(
      ConnectionType[_lastConnect?.connector]
    )?.connector;
    connector?.deactivate?.();

    removeFromLocalStorage(CACHED_WALLET_KEY);
    removeFromLocalStorage(LAST_CONNECT_KEY);

    clearContracts();

    dispatch(clearProfile());
    dispatch(setOpenConnector(false));
  };

  const onLogin = async () => {
    const _lastConnect: ILastConnection = getFromLocalStorage(LAST_CONNECT_KEY);

    const _connector: any = _lastConnect
      ? _lastConnect.connector
      : getFromLocalStorage(CACHED_WALLET_KEY);

    let _signature: any = _lastConnect ? _lastConnect.signature : null;
    const _signer = provider?.getSigner();
    if (!_signer) return;

    if (!_connector) {
      return onLogout(`Sign in error: we can't find wallet for connection`);
    }

    if (!_signature) {
      try {
        _signature = await _signer.signMessage(SIGN_MESSAGES.connection);
      } catch (error: any) {
        return onLogout(`Sign in error: ${error.message}`);
      }
    }

    if (!_signature) {
      return onLogout(`Sign in error: we can't sign connection`);
    }

    if (
      [undefined, 'undefined', null, false, ''].includes(account) ||
      typeof account !== 'string'
    ) {
      return onLogout(`Sign in error: we lost account`);
    }
    const _balance = await provider?.getBalance(account);

    removeFromLocalStorage(CACHED_WALLET_KEY);

    setContracts(_signer);

    await switchNetwork(NETWORK.chainId, _connector);

    dispatch(
      login({
        address: account,
        signature: _signature,
        chainId: chainId!,
        balance: +contractUtils.formatEther(_balance),
        connector: _connector,
      })
    );
  };

  const handleModalClose = (): void => {
    // if (isLoading) {
    //   return;
    // }

    dispatch(setOpenConnector(false));
  };

  const connectToWallet = async (connectionType: ConnectionType) => {
    writeToLocalStorage(CACHED_WALLET_KEY, connectionType);
    // dispatch(setLoading(true));
    await tryActivateConnector(getConnection(connectionType).connector);
  };

  if (!openConnector || address) {
    return null;
  }

  return (
    <div className={classes.ConnectModal} onClick={handleModalClose}>
      <div className={classes.Wrapper} onClick={(e) => e.stopPropagation()}>
        <ConnectModalLoader loading={false}>
          <CloseIcon className={classes.CloseIcon} onClick={handleModalClose} />
          <h4 className={classes.Title}>Connect your wallet</h4>
          <div className={classes.Description}>
            By connecting your wallet, you agree to our Terms of Service and
            Privacy Policy.
          </div>
          <div className={classes.Wallets}>
            <div
              className={classes.Wallet}
              onClick={() => {
                if (!getHasMetaMaskExtensionInstalled()) {
                  if (isMobile()) {
                    return connectMetamaskMobile();
                  } else {
                    return utils.goToMetaMask();
                  }
                }

                connectToWallet(ConnectionType.METAMASK);
              }}
            >
              MetaMask
              <MetamaskIcon />
            </div>
            <div
              className={classes.Wallet}
              onClick={() => connectToWallet(ConnectionType.COINBASE_WALLET)}
            >
              Coinbase wallet
              <CoinbaseIcon />
            </div>
            <div
              className={classes.Wallet}
              onClick={() => connectToWallet(ConnectionType.WALLET_CONNECT)}
            >
              WalletConnect
              <WalletConnectIcon />
            </div>
          </div>
        </ConnectModalLoader>
      </div>
    </div>
  );
};
