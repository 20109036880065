import React from 'react';

export const Arrow: React.FC<React.SVGAttributes<SVGElement>> = (props) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      { ...props }
    >
      <mask id='mask0_231_22663' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'>
        <rect width='24' height='24' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_231_22663)'>
        <path
          d='M12.0002 15.075C11.8502 15.075 11.7085 15.05 11.5752 15C11.4419 14.95 11.3169 14.875 11.2002 14.775L6.5752 10.15C6.35853 9.93334 6.25453 9.67067 6.2632 9.362C6.2712 9.054 6.38353 8.79167 6.6002 8.575C6.81686 8.35834 7.08353 8.25 7.4002 8.25C7.71686 8.25 7.98353 8.35834 8.2002 8.575L12.0002 12.375L15.8252 8.55C16.0419 8.33334 16.3045 8.22934 16.6132 8.238C16.9212 8.246 17.1835 8.35834 17.4002 8.575C17.6169 8.79167 17.7252 9.05834 17.7252 9.375C17.7252 9.69167 17.6169 9.95834 17.4002 10.175L12.8002 14.775C12.6835 14.875 12.5585 14.95 12.4252 15C12.2919 15.05 12.1502 15.075 12.0002 15.075Z'
          fill='#3C3636' />
      </g>
    </svg>
  );
};
