import React from 'react';

import { useContract } from 'hooks';
import { ConnectButton } from 'components/containers';
import { Button, Loader, Timer } from 'components/ui';
import { BidIcon } from 'components/icons';
import { GET_TARGET_PAGE, INDEX_URLS, NftStatuses } from 'config';

import cl from './nft-place-bid.module.scss';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { TigerUsd } from '../tiger-usd/tiger-usd';
import { formatAmount } from 'helpers';
import { TCurrency } from 'store/currency/types';

interface IProps {
  loading: boolean;
  onAction: (action: 'claim' | 'bid' | 'unlist') => void;
  address: string | null;
  price: number;
  isMyBet: boolean;
  auctionWasNoBids: boolean;
  endTimestamp: number | null;
  auctionIsLive: boolean;
  isOwner: boolean;
  isAuth: boolean;
  currency: TCurrency;
  ended?: boolean;
  win?: boolean;
  id?: string;
  isPriceLoading: boolean;
  tigerPrice: number;
  ownerName?: string;
  isCreator?: boolean;
  isAuctionOwner?: boolean;
}

export const NFTPlaceBid = ({
  loading,
  onAction,
  address,
  price,
  currency,
  isMyBet,
  endTimestamp,
  auctionIsLive,
  isOwner,
  isCreator,
  isAuth = true,
  ended,
  win,
  id,
  tigerPrice,
  isPriceLoading,
  ownerName,
  isAuctionOwner,
  auctionWasNoBids,
}: IProps) => {
  const { utils: etherUtils } = useContract();
  const navigate = useNavigate();
  const textByType = {
    win: {
      left: {
        title: 'Your bid',
      },
      right: {
        title: isCreator ? 'Auction ended!' : 'You won the auction!',
        text: isCreator
          ? `Owned by: ${ownerName}`
          : 'Congrats, you nailed it. Claim your NFT and add it to your collection.',
      },
      btn: {
        title: 'Claim NFT',
        action: () => onAction('claim'),
      },
    },
    end: {
      left: {
        title: 'sold by',
      },
      right: {
        title: 'Auction ended!',
        text: `Owned by: ${ownerName}`,
      },
      btn: null,
    },
    live: {
      left: {
        title: 'Current bid',
      },
      right: {
        title: 'Auction ended',
      },
      btn: !isAuctionOwner
        ? {
            title: 'Place a bid',
            action: () => onAction('bid'),
          }
        : null,
    },
  };

  const type = win ? 'win' : ended ? 'end' : 'live';
  const isLive = type === 'live';

  if (auctionWasNoBids) {
    return (
      <div className={cl.container}>
        <div className={cn(cl.wrapper, { [cl.wrapperNoGap]: !isAuctionOwner })}>
          <div className={cl.text}>The auction ended, there were no bids. </div>
        </div>
        {isAuctionOwner ? (
          <Button
            label={'Unlist your NFT'}
            onClick={() => onAction('unlist')}
            size='small'
            icon={<BidIcon />}
            variant='contained'
            disabled={false}
            full
          />
        ) : null}
      </div>
    );
  }

  return (
    <div className={cl.container}>
      <Loader loading={loading}>
        <>
          <div className={cl.wrapper}>
            <div>
              <div className={cl.subtitle}>{textByType[type].left.title}</div>

              <div className={cl.text}>
                {formatAmount(price, currency)}
                <br />
                {currency}
                <TigerUsd
                  isPriceLoading={isPriceLoading}
                  tigerPrice={tigerPrice}
                  value={price}
                />
              </div>
            </div>
            <div>
              <div
                className={cn(cl.subtitle, {
                  [cl.subtitleBig]: ended,
                })}
              >
                {textByType[type].right.title}
              </div>
              {isLive ? (
                <Timer
                  endTimestamp={endTimestamp}
                  customNumbersCss={cl.timer}
                />
              ) : (
                <div className={cl.text}>{textByType[type].right.text}</div>
              )}
            </div>
          </div>
          {textByType[type].btn ? (
            isAuth ? (
              <Button
                label={textByType[type].btn?.title}
                onClick={textByType[type].btn?.action}
                size='small'
                icon={<BidIcon />}
                variant='contained'
                disabled={false}
                full
              />
            ) : auctionIsLive ? (
              <ConnectButton />
            ) : null
          ) : null}
        </>
      </Loader>
    </div>
  );
};
