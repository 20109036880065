import classes from './list.module.scss';
import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'components/icons';

type Props = {
  children: React.ReactNode;
  title: string;
  path: string;
};

export const List = ({ children, title, path }: Props) => {
  return (
    <div className={classes.list}>
      <div className={classes.text}>
        <span className={classes.title}>{title}</span>
        <Link to={path} className={classes.path}>
          View all <ArrowRight />
        </Link>
      </div>
      {children}
    </div>
  );
};
