import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CREATE_NFT_STEPS, ICreateNFTStep } from 'config';
import { IFile } from 'components/containers';

import {
  INFTState,
  IMeta,
  ITxProps,
  IProcessStepProps,
  IBurningStepProps,
  IDraftMeta,
} from './interface';
import { uploadImage, addNFTToAuction, mintNFT, getNFTDraft, deleteNFTDraft } from './actions';

const initialState: INFTState = {
  step: CREATE_NFT_STEPS.uploadImage,
  IPFSHash: null,
  fullPath: null,
  isHashLoaded: false,
  isLoading: false,
  imageLoadingProgress: 0,
  file: null,
  description: null,
  name: null,
  genreId: null,
  genre: null,
  mintingStep: null,
  mintingError: false,
  txHashMint: null,
  txHashList: null,
  nftId: null,
  startPrice: null,
  balanceError: null,
  listingStep: null,
  listingError: false,
  isListed: false,
  isDeletedNFTDraft: false,
};

export const NFTManager = createSlice({
  name: 'NFTManager',
  initialState,
  reducers: {
    setFile: (state, action: PayloadAction<IFile | null>) => {
      state.file = action.payload;
    },
    setStep: (state, action: PayloadAction<ICreateNFTStep>) => {
      state.step = action.payload;
    },
    setImageProgress: (state, action: PayloadAction<number>) => {
      state.imageLoadingProgress = action.payload;
    },
    setMeta: (state, action: PayloadAction<IMeta>) => {
      const { name, genre, genreId, description } = action.payload;

      if (name !== undefined) {
        state.name = name;
      }

      if (genre !== undefined && genreId !== undefined) {
        state.genre = genre;
        state.genreId = genreId;
      }

      if (description !== undefined) {
        state.description = description;
      }
    },
    setDraftMeta: (state, action: PayloadAction<IDraftMeta>) => {
      const { IPFSHash, fullPath, step, name, genre, genreId, description } = action.payload;

      if (name !== undefined) {
        state.name = name;
      }

      if (genre !== undefined && genreId !== undefined) {
        state.genre = genre;
        state.genreId = genreId;
      }

      if (description !== undefined) {
        state.description = description;
      }

      state.IPFSHash = IPFSHash;
      state.fullPath = fullPath;
      state.step = step;
    },
    setHashLoaded: (state) => {
      state.isHashLoaded = true;
    },
    clearState: () => initialState,
    setProcessStep: (state, action: PayloadAction<IProcessStepProps>) => {
      const { mintingStep = null, listingStep = null } = action.payload;

      state.mintingStep = mintingStep;
      state.listingStep = listingStep;
    },
    setTxHash: (state, action: PayloadAction<ITxProps>) => {
      const { txHashMint = null, txHashList = null } = action.payload;

      state.txHashMint = txHashMint;
      state.txHashList = txHashList;
    },
    setNftId: (state, action: PayloadAction<string | number>) => {
      state.nftId = action.payload;
      state.mintingStep = 4;
    },
    setPrice: (state, action: PayloadAction<string | null>) => {
      state.startPrice = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadImage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(uploadImage.fulfilled, (state, action: PayloadAction<any>) => {
        if (!action.payload) {
          return;
        }

        const { id, name } = action.payload;

        state.IPFSHash = id;
        state.fullPath = name;
        state.isLoading = false;
        state.file = null;
      })
      .addCase(mintNFT.pending, (state) => {
        state.mintingStep = 1;
      })
      .addCase(mintNFT.fulfilled, (state, action: PayloadAction<any>) => {
        const { tokenId } = action.payload;

        state.nftId = tokenId;
        state.mintingStep = 4;
      })
      .addCase(mintNFT.rejected, (state, action) => {
        console.log('minting error: ', action.payload, action.error);

        state.mintingError = true;
      })
      .addCase(addNFTToAuction.pending, (state) => {
        state.listingStep = 1;
      })
      .addCase(addNFTToAuction.fulfilled, (state) => {
        state.isListed = true;
      })
      .addCase(addNFTToAuction.rejected, (state, action) => {
        console.log('auction error: ', action.payload, action.error);

        state.listingError = true;
      })
      .addCase(getNFTDraft.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getNFTDraft.fulfilled, (state, action: PayloadAction<any>) => {
        const { image} = action.payload;
        state.name = '';

        if(image.metadata !== undefined && image.metadata !== null) {
          state.name = image.metadata.hasOwnProperty('name') ? image.metadata.name : '';          
        }        

        state.fullPath = image.name;

        state.isLoading = false;
      })
      .addCase(getNFTDraft.rejected, (state, action) => {
        console.log('[getNFTDraftById] error - ', action.payload, action.error)
      })
      .addCase(deleteNFTDraft.fulfilled, (state, action: PayloadAction<any>) => {
        state.isDeletedNFTDraft = true;
      });
  },
});

export const {
  setFile,
  setImageProgress,
  setStep,
  setMeta,
  setDraftMeta,
  setHashLoaded,
  clearState,
  setTxHash,
  setPrice,
  setProcessStep,
} = NFTManager.actions;
