import { CLAIM_ETH_STEPS, ICreateNFTStep } from 'config';
import { IClaimETHInitialState } from 'store/claim-manager/interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: IClaimETHInitialState = {
  step: CLAIM_ETH_STEPS.settleAuction,
};

export const ClaimManager = createSlice({
  name: 'claimNft',
  initialState,
  reducers: {
    setClaimStep: (state, action: PayloadAction<ICreateNFTStep>) => {
      state.step = action.payload;
    },
  },
});

export const { setClaimStep } = ClaimManager.actions;
