import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  Loader,
  ProcessSteps,
  ProcessText,
  ProcessProfile,
  EtherscanLink,
  ProcessFinishedButtons
} from 'components/ui';
import {
  CREATE_NFT_STEPS,
  FILTER_PARAMS,
  listingSteps
} from 'config';
import {
  clearState,
  addNFTToAuction
} from 'store/nft-manager';
import {
  useAppSelector,
  useAppDispatch
} from 'hooks';

import classes from './list-nft.module.scss';

export const ListNft = () => {
  const dispatch = useAppDispatch();
  const [ searchParams, setSearchParams ] = useSearchParams();
  const {
    nftId,
    listingStep,
    listingError,
    txHashList,
    isListed
  } = useAppSelector((state) => state.NFTManagerStore);
  const {
    userData,
    address
  } = useAppSelector(state => state.profileStore);

  useEffect(() => {
    if (!nftId) {
      return;
    }

    dispatch(addNFTToAuction());
  }, [ nftId ]);

  useEffect(() => {
    if (listingError) {
      dispatch(clearState());
      searchParams.set(FILTER_PARAMS.STEP, `${ CREATE_NFT_STEPS.uploadImage.index }`);
      setSearchParams(searchParams);
    }
  }, [ listingError ]);

  return (
    <div className={ classes.container }>
      <ProcessSteps
        steps={ 3 }
        current={ listingStep }
      />
      <ProcessText
        current={ listingStep }
        steps={ listingSteps }
      />

      <div className={ classes.steps__info_block }>
        <div className={ classes.steps__launch }>
          {
            listingStep === 1 &&
            <ProcessProfile
              image={ userData && userData.image }
              username={ userData && userData.username }
              address={ address }
            />
          }
          {
            listingStep && listingStep > 1 &&
            <EtherscanLink txHash={ txHashList } />
          }
          {
            isListed && nftId && address &&
            (
              <ProcessFinishedButtons
                nftId={ nftId }
                address={ address }
              />
            )
          }
          <Loader loading={ !isListed }>
            <div style={{ width: 80, height: 80 }} />
          </Loader>
        </div>
      </div>
    </div>
  );
};
