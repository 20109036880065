import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { utils } from 'libs';

import { getPrices } from './actions';
import { ICurrency } from './types';

const initialState: ICurrency = {
  isLoading: false,
  prices: {
    BabyBNBTiger: '',
    BNB: '',
  },
};

export const priceStore = createSlice({
  name: 'priceStore/getPrices',
  initialState,
  reducers: {
    clearState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPrices.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPrices.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.prices = action.payload;
      })
      .addCase(getPrices.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { clearState } = priceStore.actions;
