import { createAsyncThunk } from '@reduxjs/toolkit';

import { API_METHODS, NETWORK } from 'config';
import { axios, utils, web3, logger } from 'libs';
import { getContract } from 'hooks';
import { setImageProgress, setProcessStep, setTxHash } from './reducer';
import { getLCP } from 'web-vitals';
import { Currency } from 'store/currency/types';

const _axios = axios.getAxios();

export const uploadImage = createAsyncThunk(
  'NFTManger/uploadImageToIPFS',
  async (args, { getState, dispatch }) => {
    try {
      const {
        NFTManagerStore: { file },
      }: any = getState();

      const formData = new FormData();
      const fileData: File = await utils.urlToFile(file);

      formData.append('name', fileData);

      const response = await _axios.post(
        `${API_METHODS.UPLOAD_NFT_IMG}`,
        formData,
        {
          onUploadProgress: (progressEvent: any) => {
            dispatch(
              setImageProgress(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              )
            );
          },
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      logger.info(`[ IMAGE UPLOADED TO S3 ][ image: ${response.data?.name} ]`);

      return response.data;
    } catch (error) {
      console.log(error);

      return null;
    }
  }
);

export const mintNFT = createAsyncThunk(
  'NFTManger/mintNFT',
  async (args, { getState, dispatch, rejectWithValue }) => {
    const {
      NFTManagerStore: { IPFSHash, description, name, genreId, fullPath },
    }: any = getState();
    const { nftContract } = getContract();

    if (!nftContract) {
      rejectWithValue(`We haven't nft contract`);
    }

    try {
      const { data: savedMeta = {} } = await _axios.put(
        API_METHODS.UPLOAD_NFT_IMG_METADATA + `${IPFSHash}/`,
        {
          metadata: {
            ipfs_pin_hash: fullPath,
            name,
            description,
            genre: genreId,
          },
        }
      );

      if (!Object.keys(savedMeta).length) {
        return rejectWithValue(`[ MINT ][ We can't save NFT metadata ]`);
      }

      dispatch(setProcessStep({ mintingStep: 2 }));

      const mint = await nftContract.mint(IPFSHash!.toString());

      dispatch(setTxHash({ txHashMint: mint.hash }));
      dispatch(setProcessStep({ mintingStep: 3 }));

      const { transactionHash, logs } = await mint.wait();
      const hex = logs[0].topics[3];
      const tokenId = await web3.hexToNumber(hex);
      const nft = await nftContract.tokenURI(tokenId);

      // console.log('tx: ', transactionHash);
      // console.log('hex: ', hex);
      // console.log('tokenId: ', tokenId);
      // console.log('nft: ', nft);

      if (!tokenId) {
        return rejectWithValue(`[ MINT ][ Wa can't: ${tokenId} ]`);
      }

      logger.info(`[ NFT IS MINTED ][ tokenId: ${tokenId} ]`);

      return { tokenId };
    } catch (err: any) {
      rejectWithValue(err);
    }
  }
);

export const addNFTToAuction = createAsyncThunk(
  'NFTManger/addNFTToAuction',
  async (args, { getState, rejectWithValue, dispatch }) => {
    const {
      NFTManagerStore: { nftId, startPrice },
    }: any = getState();

    const { nftContract, auctionContract, units } = getContract();

    if (!auctionContract && !nftContract) {
      rejectWithValue(`We haven't auction contract`);
    }

    try {
      console.log('start listing: ', nftId, startPrice);
      const approved = await nftContract.getApproved(nftId);

      console.log('approved: ', approved, NETWORK.auctionAddress);

      if (approved !== NETWORK.auctionAddress) {
        const _approved = await nftContract.approve(
          NETWORK.auctionAddress,
          nftId
        );
        await _approved.wait();
      }
      const createAuction = await auctionContract.createAuction(
        nftId,
        units.parseUnits(startPrice).toString()
        // currency === Currency.BabyBNBTiger
      );

      console.log('create auction: ', createAuction);

      dispatch(setTxHash({ txHashList: createAuction.hash }));
      dispatch(setProcessStep({ listingStep: 2 }));

      const { transactionHash, logs } = await createAuction.wait();
      const hex = logs[0].topics[3];
      const tokenId = await web3.hexToNumber(hex);
      const nft = await auctionContract.getAuctionData(tokenId);

      dispatch(setProcessStep({ listingStep: 3 }));

      // console.log('auction tx: ', transactionHash);
      // console.log('auction hex: ', hex);
      // console.log('auction tokenId: ', tokenId);
      // console.log('auction nft: ', nft);

      logger.info(`[ NFT IS LISTED ][ tokenId: ${tokenId} ]`);

      return null;
    } catch (err: any) {
      rejectWithValue(err);
    }
  }
);

export const getNFTDraft = createAsyncThunk(
  'NFTManger/getNFTDraft',
  async (id: string | undefined, { rejectWithValue }) => {
    try {
      const { data } = await _axios.get(`${API_METHODS.GET_DRAFT_NFTS}${id}`);

      if (!data) {
        return null;
      }

      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const deleteNFTDraft = createAsyncThunk(
  'NFTManger/deleteNFTDraft',
  async (id: string | undefined, { rejectWithValue }) => {
    try {
      const { status } = await _axios.delete(
        `${API_METHODS.DELETE_DRAFT_NFTS}${id}`,
        {
          data: {
            id,
          },
        }
      );

      if (status !== 204) {
        return null;
      }

      logger.info(`[ DRAFT NFT IS DELETED ]`);

      return {};
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);
