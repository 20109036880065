export const setGenres = (
  genres: any[],
  onSet: (e: any) => any,
  query: string | null
) => {
  const _params = query ? query.split(',') : [];

  const newGenres = genres.map(({ name }) => ({
    label: name,
    value: name?.toLowerCase(),
    onClick: () => {
      onSet({
        name,
      });
    },
    checked: _params.includes(name),
  }));

  //к сожалению так
  return [
    {
      name: 'All',
      label: 'All',
      onClick: () => {
        onSet({
          name: 'All',
        });
      },
    },
    ...newGenres,
  ];
};
