import React, { useState, useRef } from 'react';
import cn from 'classnames';

import {
  CloseIcon,
  Upload
} from 'components/icons';
import { utils } from 'libs';

import classes from './nft-file-uploader.module.scss';

export interface IFile {
  name: string;
  body: string;
  size: string | number;
  mimeType: string;
}

interface IProps {
  file: IFile | null | undefined;
  onUploaded: (file: IFile) => void;
  onError: (text: string) => void;
  onRemove: () => void;
  maxFileSize: number;
  mimeTypes: string;
}

export const NFTFileUploader = ({ file, onUploaded, mimeTypes, maxFileSize, onError, onRemove }: IProps) => {
  const ref: any = useRef(null);
  const [ drag, setDrag ] = useState(false);
  const [ preview, setPreview ] = useState<string>('');

  const onFinished = (_file: File) => {
    if (!ref.current) {
      return;
    }

    const size = maxFileSize * 1024 * 1024;
    const check = utils.isGt(_file.size, size);

    ref.current.value = null;

    if (check) {
      return onError(`Maximum file size exceeded`);
    }

    const reader = new FileReader();

    reader.readAsDataURL(_file);
    reader.onload = (e: any) => {
      const target = e.target.result;

      setPreview(target);
      onUploaded({
        name: _file.name,
        body: target,
        size: _file.size,
        mimeType: _file.type
      });
    }
  }
  
  const uploadHandler = ({ target }: any) => {
    const file = target.files[0];

    if (!file) {
      return;
    }

    setPreview('');
    onFinished(file);
  }

  const dragStartHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    setDrag(true);
  };

  const dragLeaveHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    setDrag(false);
  };

  const onDropHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    const files = [...e.dataTransfer.files];

    onFinished(files[0]);
    setDrag(false);
  };

  const LabelUpload = () => {
    return(
      <label htmlFor='file-upload' className={ classes.fileUpload }>
        <Upload />
        <span>Upload</span>
      </label>
    );
  }

  const LabelReplace = () => {
    return(
      <label htmlFor='file-upload' >
        <div className={ classes.action }>
          <Upload />
          <span>Replace</span>
        </div>
      </label>
    );
  }

  const UploaderWithoutFile = () => {
    return(
      <div
        className={ classes.wrap }
        onDragStart={ dragStartHandler }
        onDragLeave={ dragLeaveHandler }
        onDragOver={ dragStartHandler }
        onDrop={ onDropHandler }
      >
        <h4 className={ classes.title }>
          Drag and drop, or click to browse.
        </h4>
        <span className={ classes.description }>
          Drag and drop your artwork file (JPG or PNG, up to { maxFileSize } MB limit) into the upload field or&nbsp;click the "Browse" button to locate the file on your computer.
        </span>
        <div className={ classes.fileUpload }>
          <LabelUpload />
        </div>
        { drag && <div className={ classes.wrapDrag } /> }
      </div>
    );
  }

  const UploaderWithFile = () => {
    return(
      <div className={ cn(classes.wrap, classes.padding24, classes.flexRow) }>
        <div className={ classes.leftBlock }>
          <div className={ classes.imageWrapper }>
            <img src={  preview } alt={ file?.name } className={ classes.uploadImage }/>
          </div>
          <div className={ classes.textBlock }>
            <span className={ classes.fileName }>{ file?.name }</span>
          </div>

        </div>
        <div className={ classes.rightBlock }>
          <LabelReplace />
          <div
            className={ cn(classes.action, classes.redAction) }
            onClick={ onRemove }
          >
            <CloseIcon className={ cn(classes.redIcon) } />
            <span>Remove</span>
          </div>
        </div>
      </div>
    );
  }
  
  return(
    <>
      { !file ? <UploaderWithoutFile /> : <UploaderWithFile /> }
      <input
        ref={ ref }
        id='file-upload'
        type='file'
        onChange={ uploadHandler }
        style={{
          visibility: 'hidden'
        }}
        accept={ mimeTypes }
      />
    </>
  );
}
