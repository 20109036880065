import { IInitialState } from './types';
import { createSlice } from '@reduxjs/toolkit';

const initialState: IInitialState = {
  history: [],
};

export const nftHistory = createSlice({
  name: 'nftHistory',
  initialState,
  reducers: {
    setHistory: (state, action) => {
      state.history = action.payload;
    },
  },
});

export const { setHistory } = nftHistory.actions;
