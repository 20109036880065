export interface INft {
  id: number | string;
  nft_id: number;
  genre: 'Figurative' | string;
  s3_cache: IS3Cache;
  mintEventTx: string;
  author: any;
  metadata: string;
  metadata_details: string;
  status: Status;
  name: string;
  description: string;
  is_owner: boolean;
  price: string | number | null | undefined;
  auction: any;
}

export interface IGetNFTParams {
  status?: string;
  author?: string;
  search?: string;
  genre?: string;
  state: string;
}

export interface IS3Cache {
  id: number | null;
  original_image_ipfs_hash: string | null;
  original_image_s3_key: string | null;
  small_preview_s3_key: string | null;
  medium_preview_s3_key: string | null;
  original_image_s3_url: string | null;
  small_image_s3_url: string | null;
  medium_image_s3_url: string | null;
}

export enum Status {
  ready = 'ready',
  sale = 'sale',
  draft = 'draft',
  claim = 'claim',
  created = 'created',
}
