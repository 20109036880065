import { useEffect, useState } from 'react';

import { CopyIcon } from 'components/icons';
import { utils } from 'libs';

import classes from './copy.module.scss';

type Props = {
  text: string;
  copyText: string;
};

export const Copy = ({ text, copyText }: Props) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCopied(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [copied]);

  return (
    <div
      onClick={() => {
        utils.copyToClipboard(copyText);
        setCopied(true);
      }}
      className={classes.copy}
    >
      {text}
      <CopyIcon />
      {copied && <div className={classes.copy__notification}>Copied!</div>}
    </div>
  );
};
