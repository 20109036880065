import React from 'react';

import { useAppSelector } from 'hooks';

import classes from './nft-filter-search-result.module.scss';

export const NFTFilterSearchResult: React.FC = () => {
  const { isLoading, count } = useAppSelector((state) => state.nftListStore);
  const piece = count === 1 ? 'piece' : 'pieces';
  const content = isLoading ? (
    <>...</>
  ) : (
    <>
      {count} {piece} of masterpieces
    </>
  );

  return <span className={classes.panel__label}>{content}</span>;
};
