import { createAsyncThunk } from '@reduxjs/toolkit';

import { getContract } from 'hooks';
import { web3 } from 'libs';

import { setStep, setTxHash } from './reducer';
import { NETWORK } from 'config';
import { INCREASE_COEFFICIENT_MULTIPLIER } from 'constants/constants';
import { Currency, TCurrency } from 'store/currency/types';
import { formatAmountTo } from 'helpers';
import { createEmitAndSemanticDiagnosticsBuilderProgram } from 'typescript';

export const list = createAsyncThunk(
  'listStore/list',
  async (
    { nftId, currency }: { nftId: string | number; currency: TCurrency },
    { getState, dispatch }
  ) => {
    const { nftContract, auctionContract, units } = getContract();
    const {
      listStore: { amount, coefficient },
    }: any = getState();
    try {
      nftId = nftId.toString();

      console.log(
        'start listing: ',
        `
        nftId: ${nftId},
        amount: ${amount},
        units.parseUnits(amount, 9).toString(): ${units
          .parseUnits(amount, 9)
          .toString()},
        coefficient: ${coefficient},
        units.parseUnits(coefficient).toString(): ${units
          .parseUnits(coefficient)
          .toString()},
        NETWORK.auctionAddress: ${NETWORK.auctionAddress},
      `
      );
      console.log(nftContract);
      const approved = await nftContract.getApproved(nftId);
      console.log('nftContract.getApproved => ', approved);
      if (approved !== NETWORK.auctionAddress) {
        console.log('NFT CONTRACT APPROVE INIT...');
        const _approved = await nftContract.approve(
          NETWORK.auctionAddress,
          nftId
        );

        console.log('NFT CONTRACT STARTING...');
        await _approved.wait(1);
        console.log('NFT CONTRACT FINISHING...');
      }

      dispatch(setStep(1));
      console.log('CREATE AUCTION CONTRACT STARTING...');
      console.log(
        nftId,
        formatAmountTo(amount, currency),
        coefficient * INCREASE_COEFFICIENT_MULTIPLIER,
        currency === Currency.BNB
      );
      const createAuction = await auctionContract.createAuction(
        nftId,
        formatAmountTo(amount, currency),
        coefficient * INCREASE_COEFFICIENT_MULTIPLIER,
        currency === Currency.BNB
      );

      console.log('create auction: ', createAuction);

      dispatch(setTxHash(createAuction.hash));
      dispatch(setStep(2));

      const { transactionHash, logs } = await createAuction.wait();
      const hex = logs[0].topics[3];
      const tokenId = await web3.hexToNumber(hex);
      const nft = await auctionContract.getAuctionData(tokenId);

      return null;
    } catch (error: any) {
      dispatch(setStep(null));
      console.log({ error });

      return {
        error: error.reason,
      };
    }
  }
);
