import React from 'react';

import cl from './tiger-usd.module.scss';

interface IProps {
  isPriceLoading: boolean;
  tigerPrice: number;
  value: number;
}

export const TigerUsd = ({ isPriceLoading, tigerPrice, value }: IProps) => {
  const price = tigerPrice * value;
  return <div className={cl.usd}>≈{price?.toFixed(0)} $</div>;
};
