import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Button } from 'components/ui';
import { URLS } from 'config';

import classes from './info.module.scss';

interface IProps {
  title: string;
  text: string;
  buttonText?: string | null;
  buttonLink?: string | null;
  learnMore?: string | null;
}

export const Info: React.FC<IProps> = (props) => {
  const navigate = useNavigate();

  return (
    <div className={ classes.info }>
      <div className={ classes.info__title }>{ props.title }</div>
      <div className={ classes.info__text }>{ props.text }</div>
      {
        props.buttonLink &&
        (
          <Button
            label={ props.buttonText }
            onClick={() => {
              navigate(props.buttonLink!);
            }}
            size='small'
            type='primary'
            variant='contained'
          />
        )
      }
      {
        props.learnMore &&
        (
          <div className={ classes.info__text }>
            Learn more <Link to={ props.learnMore }>here</Link>.
          </div>
        )
      }
    </div>
  );
};
