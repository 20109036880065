import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { axios } from 'libs';
import { API_METHODS, API_DOMAIN } from 'config';

import {
  ValidationErrors,
  ILoginParams,
  IUpdateVerificationParams,
  IUploadImage,
  IReport,
} from './types';

const _axios = axios.getAxios();

export const updateUserData = createAsyncThunk(
  'profileStore/updateUserData',
  async (args, { getState }) => {
    try {
      const {
        profileStore: { userChanges, userData },
      }: any = getState();
      const response = await _axios.patch(
        `${API_METHODS.CUSTOMUSER_EDIT}/${userData.id}/`,
        { ...userChanges, meta: userChanges.meta ? userChanges.meta : {} }
      );

      return response.data;
    } catch (err: any) {
      console.log('update user data error: ', err);

      return null;
    }
  }
);

export const getUserByWallet = createAsyncThunk(
  'profileStore/fetchByWalletId',
  async (address: string, { rejectWithValue }) => {
    try {
      const { data } = await _axios.get(
        `${API_METHODS.CUSTOMUSER_USER}/?wallet=${address}`
      );

      if (!data) {
        return null;
      }

      return data;
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err;

      if (err.statusCode === 404) {
        return rejectWithValue(err as any);
      }

      if (!error.response) {
        throw err;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const login = createAsyncThunk(
  'profileStore/login',
  async (params: ILoginParams, { rejectWithValue }) => {
    try {
      const { address, signature } = params;
      const { data } = await _axios.post(API_METHODS.W3AUTH_LOGIN_API, {
        address,
        signature,
      });

      if (!data) {
        return null;
      }

      return {
        ...data,
        ...params,
      };
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err;

      if (err.statusCode === 404) {
        return rejectWithValue(err);
      }

      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const updateVerification = createAsyncThunk(
  'profileStore/update_verification',
  async (params: IUpdateVerificationParams, { rejectWithValue }) => {
    try {
      const { oauth_token, oauth_token_secret } = params;
      console.log({ oauth_token });

      const { data } = await _axios.get(
        `${API_METHODS.VERIFY_CHECK_TWITTER}?oauth_token=${oauth_token}&oauth_verifier=${oauth_token_secret}`,
        {
          headers: {
            'Set-Cookie': `twitter_oauth_token=${oauth_token}; twitter_oauth_token_secret=${oauth_token_secret}`,
          },
        }
      );
      if (!data) {
        return null;
      }

      return {
        data,
      };
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err;

      if (err.statusCode === 404) {
        return rejectWithValue(err);
      }

      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

// export const getSubscriptions = createAsyncThunk(
// 'profileStore/update_verification',
// async (state, { rejectWithValue, getState }) => {
//   try {
//     const { profileStore: { userData = {} }}: any = getState();

//     if (!userData.id) {
//       return null;
//     }

//     const { data = [] } = await _axios.get(`${ API_METHODS.SUBSCRIPTION }?user_id=${ userData.id }`);

//     console.log(data)

//     return data;
//   } catch (error: any) {
//     rejectWithValue(error);
//   }
// });

export const checkUsername = createAsyncThunk(
  'profileStore/checkUsername',
  async (username: string | null) => {
    if (!username) {
      return null;
    }

    try {
      const { data = {} } = await _axios.get(
        `${API_DOMAIN}${API_METHODS.CUSTOMUSER_USERS}/?search=${username}`
      );

      if (!data.result || data.result.length) {
        return null;
      }

      return username;
    } catch (error) {
      console.log('check username error: ', error);

      return null;
    }
  }
);

export const uploadProfileImg = async ({ formData }: IUploadImage) => {
  try {
    const { data = {} } = await _axios.post(
      `${API_DOMAIN}${API_METHODS.UPLOAD_IMG}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return data;
  } catch (error) {
    console.log('upload image: ', error);

    return null;
  }
};

export const sendReport = createAsyncThunk(
  'profileStore/sendReport',
  async (params: IReport, { rejectWithValue }) => {
    const { email, message } = params;
    if (!email || !message) {
      return null;
    }

    try {
      const { data = {} } = await _axios.post(
        API_METHODS.CUSTOMUSER_SEND_REPORT,
        {
          email,
          message,
        }
      );

      if (!data.created_at) {
        return null;
      }

      return {};
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err;

      if (err.statusCode === 404) {
        return rejectWithValue(err);
      }

      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const sendRequestBecomeAuthor = createAsyncThunk(
  'profileStore/sendRequestBecomeAuthor',
  async (args, { getState, rejectWithValue }) => {
    const {
      profileStore: { userChanges, userData },
    }: any = getState();

    const first_name = userChanges.first_name
      ? userChanges.first_name
      : userData.first_name;
    const email = userChanges.email ? userChanges.email : userData.email;
    const cover_letter = userChanges.cover_letter;
    const website = userChanges.meta.website;
    const cloud = userChanges.cloud;

    try {
      const { data = {} } = await _axios.post(
        API_METHODS.CUSTOMUSER_CREATOR_REPORT,
        {
          first_name,
          email,
          cover_letter,
          website,
          cloud,
        }
      );

      if (!data.created_at) {
        return null;
      }

      return data;
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err;

      if (err.statusCode === 404) {
        return rejectWithValue(err);
      }

      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);
