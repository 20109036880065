import React from 'react';
import cn from 'classnames';

import { useAppSelector, useContract } from 'hooks';
import { Button, Timer } from 'components/ui';
import { GreenCheckMark, BidIcon, RedCross } from 'components/icons';
import { NftStatuses } from 'config';
import { IAuction } from 'store/auction';

import classes from './auction-card.module.scss';
import { ConnectButton } from 'components/containers';
import { formatAmount } from 'helpers';
import { TigerUsd } from '../tiger-usd/tiger-usd';
import { utils } from 'libs';
import { TCurrency } from 'store/currency/types';

const currentTimeUnix = Math.floor(Date.now() / 1000);

interface IProps extends IAuction {
  onAction: (action: string, nftId: string | number) => void;
  isAuth: boolean;
  price?: string;
  id?: number;
}

type YourBidBlockType = {
  isMyLastBet: boolean;
  lastBet: number;
  myLastBet: number;
  currency: TCurrency;
  tigerPrice: number;
  isLoading: boolean;
};

const YourBidBlock = ({
  isMyLastBet,
  lastBet,
  myLastBet,
  tigerPrice,
  currency,
  isLoading,
}: YourBidBlockType) => {
  return (
    <div className={classes.price}>
      <div className={classes.priceHeader}>
        <div className={classes.title}>Your bid</div>
        <div
          className={cn(classes.status, {
            [classes.statusGreen]: isMyLastBet,
            [classes.statusRed]: !isMyLastBet,
          })}
        >
          {isMyLastBet ? <GreenCheckMark /> : <RedCross />}
          {isMyLastBet ? 'You’re winning' : 'Outbided'}
        </div>
      </div>
      <div className={classes.value}>
        <div className={classes.bnbPrice}>
          {myLastBet} <br />
          {currency}
        </div>
        <TigerUsd
          tigerPrice={tigerPrice}
          value={myLastBet}
          isPriceLoading={isLoading}
        />
      </div>
    </div>
  );
};

export const AuctionCard: React.FC<IProps> = (params) => {
  const { utils: etherUtils } = useContract();
  const { prices, isLoading } = useAppSelector((state) => state.priceStore);
  const { userData, address } = useAppSelector((state) => state.profileStore);

  const onHandleAction = (action: string) => {
    if (!params.onAction || typeof params.onAction !== 'function') {
      return;
    }

    params.onAction(action, params.id || '');
  };

  const isCreator = userData?.is_creator;

  //АУКЦИОН АКТИВНЫЙ
  const isAuctionLive = params.auction.status;

  const isAuctionEndedAndNoBids = !isAuctionLive && !params.auction.lastBet;

  //показываем кнопку клейм если АУКЦИОН ЗАКОНЧИЛСЯ. СТАТУС КЛЕЙМ. ПОСЛЕДНЯЯ СТАВКА ПОЛЬЗОВАТЕЛЯ
  const isAuctionEndedAndWin =
    !isAuctionLive &&
    (params.status === NftStatuses.claim ||
      params.status === NftStatuses.ready) &&
    (params.auction.isMyLastBet || isCreator) &&
    !isAuctionEndedAndNoBids;

  const showBidBlock = params.auction.myLastBet && !isAuctionEndedAndWin;

  const isAuctionEnded = !isAuctionLive && !params.auction.isMyLastBet;

  //показываем кнопку когда надо
  const isShownBidButton =
    isAuctionLive &&
    params.isAuth &&
    !params.auction.isMyLastBet &&
    params.auction?.auctionOwner !== address;

  //но если не авторизован то кнопка коннекта
  const isShownConnectBtn = isAuctionLive && !params.isAuth;

  //если нет первой ставки берем из price, потом уже из lastBet
  const price = params.auction.lastBet || params.price || 0;

  const isAuctionOwner = params?.auction?.auctionOwner === address;

  const auctionWasNoBids =
    params.auction &&
    !params.auction.status &&
    params.status === NftStatuses.claim &&
    !params.auction.lastBet;
  return (
    <div
      className={cn(classes.container, {
        [classes.containerBig]: isAuctionLive,
      })}
    >
      <img
        src={params.image}
        alt={params.name}
        className={cn(classes.image, { [classes.imageBig]: isAuctionLive })}
      />
      <div className={classes.info}>
        <div className={classes.name}>{params.name}</div>

        <div className={classes.author}>
          <div className={classes.title}>AUTHOR:</div>
          {params.author.username}
        </div>

        <div className={classes.price_and_time}>
          <div className={classes.price}>
            <div className={classes.title}>Current bid</div>
            <div className={classes.value}>
              <div className={classes.bnbPrice}>
                {formatAmount(+price, params.auction.currency)} <br />{' '}
                {params.auction.currency}
              </div>
              <TigerUsd
                tigerPrice={+prices[params.auction.currency]}
                value={+price}
                isPriceLoading={isLoading}
              />
            </div>
          </div>
          <div className={classes.time}>
            <>
              <div className={classes.title}>Auction ending in</div>
              <div className={classes.value}>
                <Timer
                  customNumbersCss={classes.numbers}
                  //если аукцион закончился (unlist) то делаем время в минус что бы таймер обнулился.
                  endTimestamp={
                    isAuctionEnded
                      ? currentTimeUnix - 30000
                      : params.auction.endTimestamp
                  }
                  renderCompleted
                />
              </div>
            </>
          </div>
        </div>
      </div>

      <div
        className={cn(classes.actions, {
          [classes.actionsFlexStart]:
            isAuctionEnded && !isAuctionEndedAndWin && !auctionWasNoBids,
        })}
      >
        {showBidBlock ? (
          <div className={classes.top}>
            <YourBidBlock
              isMyLastBet={params.auction.isMyLastBet}
              lastBet={params.auction.lastBet}
              myLastBet={params.auction.myLastBet}
              currency={params.auction.currency}
              tigerPrice={+prices[params.auction.currency]}
              isLoading={isLoading}
            />
          </div>
        ) : null}

        {isAuctionLive && !params.auction.myLastBet ? (
          <div className={cn(classes.top, classes.win)}>
            <div className={classes.title}>Auction time!</div>
            <div className={classes.description}>You can bid on.</div>
          </div>
        ) : null}

        {isAuctionEnded && (
          <>
            <div className={cn(classes.top, classes.win)}>
              <div className={classes.title}>Auction ended!</div>
              {!isAuctionEndedAndNoBids && (
                <div className={classes.description}>
                  Owned by: {utils.shorter(params.owner.wallet) || ''}
                </div>
              )}
            </div>
          </>
        )}

        {isAuctionEndedAndWin && (
          <>
            {!isCreator ? (
              <div className={cn(classes.top, classes.win)}>
                <div className={classes.title}>You won the auction!</div>
                {params.status === NftStatuses.claim && (
                  <div className={classes.description}>
                    Congrats, you nailed it. Claim your NFT and add it to your
                    collection.
                  </div>
                )}
              </div>
            ) : null}

            {params.status === NftStatuses.claim && (
              <Button
                label='Claim your NFT'
                type='primary'
                size='small'
                onClick={() => onHandleAction('claim')}
              />
            )}
          </>
        )}

        {auctionWasNoBids &&
        isAuctionOwner &&
        params.auction.isLastAuctionForNFt ? (
          <Button
            label='Unlist your NFT'
            type='primary'
            size='small'
            onClick={() => onHandleAction('unlist')}
          />
        ) : null}

        {isShownBidButton ? (
          <Button
            label='Place a bid'
            type='primary'
            icon={<BidIcon />}
            onClick={() => onHandleAction('bid')}
          />
        ) : null}

        {isShownConnectBtn ? <ConnectButton /> : null}
      </div>
    </div>
  );
};
