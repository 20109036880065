import React, { useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import {
  CREATE_NFT_STEPS,
  FILTER_PARAMS,
  URLS
} from 'config';
import {
  PriceInput,
  InfoText
} from 'components/ui';
import {
  useAppSelector,
  useAppDispatch
} from 'hooks';
import {
  clearState,
  setPrice
} from 'store/nft-manager';
import {
  InfoIcon,
  Redirect
} from 'components/icons';

import classes from './set-price-nft.module.scss';

export const SetPriceNFT = () => {
  const dispatch = useAppDispatch();
  const [ searchParams, setSearchParams ] = useSearchParams();
  const { startPrice, nftId } = useAppSelector((state) => state.NFTManagerStore);
  const { /*, balance*/ } = useAppSelector(state => state.profileStore);

  useEffect(() => {
    if (!nftId) {
      dispatch(clearState());
      searchParams.set(FILTER_PARAMS.STEP, `${ CREATE_NFT_STEPS.uploadImage.index }`);
      setSearchParams(searchParams);
    }
  }, []);

  return (
    <div className={ classes.container }>
      <div className={ classes.info__block }>
        <div className={ classes.info__input }>
          <PriceInput
            label='Reserve price in ETH'
            placeholder='Enter price in ETH'
            value={ startPrice }
            className={{ inputStyles: classes.input }}
            onChange={ (value) =>  dispatch(setPrice(value)) }
            // balance={ balance }
          />
        </div>
        <div className={ classes.info__description }>
          <InfoText
            text='Before you list your NFT, you must set a reserve price in ETH. This will be the minimum price the NFT can be sold for during the 24-hour auction.'
          />
        </div>
        <Link to={ URLS.FAQ_PAGE } className={ classes.profile__redirect }>
          <Redirect />
          Learn more about how auctions work
        </Link>
      </div>
    </div>
  );
};
