import React from 'react';
import cn from 'classnames';

import classes from './follows-info.module.scss';

type Props = {
  followers: number;
  following: number;
  isCanClick?: boolean;
  onOpenModal?: () => void;
};

export const FollowsInfo = ({
  followers,
  following,
  isCanClick,
  onOpenModal,
}: Props) => {
  return (
    <div className={classes.followsInfo}>
      <div
        className={cn(classes.followers, { [classes.isCanClick]: isCanClick })}
        onClick={onOpenModal}
      >
        <div className={classes.title}>Followers</div>
        <div className={classes.value}>{followers}</div>
      </div>

      <div
        className={cn(classes.followers, { [classes.isCanClick]: isCanClick })}
        onClick={onOpenModal}
      >
        <div className={classes.title}>Following</div>
        <div className={classes.value}>{following}</div>
      </div>
    </div>
  );
};
