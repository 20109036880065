import React from 'react';
import cn from 'classnames';

import { utils } from 'libs';

import classes from './process-steps.module.scss';

interface IProps {
  steps: number;
  current: number | null;
}

export const ProcessSteps = ({ steps, current }: IProps) => {
  const arr = utils.fillArray(steps, current);

  return (
    <div className={classes.minting_steps_container}>
      {arr.map((item, i) => (
        <div
          key={i}
          className={cn(classes.step, item !== 0 ? classes.active : '')}
        />
      ))}
    </div>
  );
};
