import { createAsyncThunk } from '@reduxjs/toolkit';

import { axios } from 'libs';
import { API_DOMAIN, API_METHODS } from 'config';
import { addSubscription, removeSubscription } from 'store/profile';

import { IAuthorsListResponse, ISubscribeProps } from './interface';
import { IGetListProps } from 'store/nft-list';

const _axios = axios.getAxios();

export const getAuthors = createAsyncThunk(
  'authorsStore/getAuthors',
  async (args: IGetListProps, { rejectWithValue }) => {
    try {
      const url = `${API_METHODS.CUSTOMUSER_USERS}${
        Object.keys(args).length > 0 ? `?${new URLSearchParams(args)}` : '/'
      }`;
      const { data = [] } = await _axios.get(url);
      return data;
    } catch (error: any) {
      rejectWithValue(error);
    }
  }
);

export const subscribe = createAsyncThunk(
  'authorsStore/subscribe',
  async (
    { follower, author, wallet }: ISubscribeProps,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const { data = {} } = await _axios.post(API_METHODS.ADD_SUBSCRIPTION, {
        follower,
        author,
      });

      if (!data.author) {
        return null;
      }

      dispatch(addSubscription(wallet));

      return author;
    } catch (error: any) {
      rejectWithValue(error);
    }
  }
);

export const unSubscribe = createAsyncThunk(
  'authorsStore/unSubscribe',
  async (
    { follower, author, wallet }: ISubscribeProps,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const { status } = await _axios.delete(API_METHODS.DELETE_SUBSCRIPTION, {
        data: {
          follower,
          author,
        },
      });

      if (status !== 204) {
        return null;
      }

      dispatch(removeSubscription(wallet));

      return author;
    } catch (error: any) {
      rejectWithValue(error);
    }
  }
);
