import React from 'react';

import { Button } from 'components/ui';
import { Loader } from 'components/icons';
import { useAppDispatch, useAppSelector } from 'hooks';
import { setOpenConnector } from 'store/profile';

import classes from './connect-button.module.scss';

export const ConnectButton = ({ size, full = true }: any) => {
  const dispatch = useAppDispatch();
  const { address, isLoading } = useAppSelector((state) => state.profileStore);
  const onHandleClick = () => {
    if (address || isLoading) {
      return;
    }

    dispatch(setOpenConnector(true));
  };

  if (address) {
    return null;
  }

  return (
    <Button
      label={
        isLoading ? <Loader className={classes.loader} /> : 'Connect wallet'
      }
      onClick={onHandleClick}
      size={size ? size : 'small'}
      disabled={isLoading}
      className={classes.ConnectButton}
      full={full}
    />
  );
};
