import React from 'react';
import classNames from 'classnames';

import { Message, MessageType } from 'components/ui';
import { Close } from 'components/icons';

import styles from './input.module.scss';

type A = React.InputHTMLAttributes<HTMLInputElement>;
export interface InputProps extends A {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  message?: string;
  errorMessage?: string;
  placeHolder?: string;
  leftIcon?: React.ReactNode;
  clearIcon?: React.ReactNode;
  clearClick?: () => void;
  className?: any;
  wrapperClassName?: any;
  containerClassName?: any;
  valueCounter?: any;
  maxLength?: number;
  autocomplete?: string;
}

export const Input = ({
  value,
  onChange,
  label,
  message,
  errorMessage,
  placeHolder,
  leftIcon,
  clearIcon,
  clearClick,
  className,
  wrapperClassName,
  containerClassName,
  maxLength,
  valueCounter = false,
  ...props
}: InputProps) => {
  return (
    <div
      className={classNames(styles.InputContainer, {
        [containerClassName]: containerClassName,
      })}
    >
      {label && <label className={styles.Label}>{label}</label>}
      <div
        className={classNames(styles.input__wrapper, {
          [styles.error]: errorMessage,
          [wrapperClassName]: wrapperClassName,
        })}
      >
        {leftIcon && <div className={styles.icon}>{leftIcon}</div>}
        <input
          value={value}
          onChange={(event) => onChange(event)}
          placeholder={placeHolder}
          maxLength={maxLength}
          className={classNames(styles.Input, className?.inputStyles)}
          {...props}
        />
        {value && clearClick && (
          <div className={styles.clearIcon} onClick={clearClick}>
            <Close />
          </div>
        )}
      </div>

      <div className={styles.Message}>
        <div style={{ width: '100%' }}>
          {errorMessage ? (
            <Message type={MessageType.error} text={errorMessage} />
          ) : message ? (
            <Message type={MessageType.neutral} text={message} />
          ) : null}
        </div>
        {valueCounter && (
          <span className={styles.maxlength}>
            {`${value.length}/${maxLength}`}
          </span>
        )}
      </div>
    </div>
  );
};
