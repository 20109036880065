import React from 'react';

import { Loader } from 'components/ui';
import { useGetTypesQuery } from 'store/genre';
import { GenresGallery } from '..';
import classes from './genres-content.module.scss';

export const GenresContent = () => {
  const { data: genres = [], isLoading } = useGetTypesQuery('');

  return (
    <div className={classes.genres}>
      <Loader loading={isLoading} className={classes.loader}>
        <GenresGallery genres={genres} />
      </Loader>
    </div>
  );
};
