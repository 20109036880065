import React from 'react';
import cn from 'classnames';

import classes from './modal.module.scss';

interface IProps {
  children: React.ReactNode;
  outClick?: () => void;
  opened: boolean;
  className?: string;
}

export const Modal: React.FC<IProps> = ({
  children,
  outClick,
  opened,
  className,
}) => {
  return (
    <div
      className={cn(
        classes.modal,
        { [classes.modal_opened]: opened },
        className
      )}
      onClick={outClick || undefined}
    >
      {children}
    </div>
  );
};
