export const tabs = [
  {
    label: 'Following',
    state: 'following',
    emptyTitle: null,
    emptyText: null,
    buttonLink: null,
    buttonText: null,
    learnMore: null
  },
	{
    label: 'Followers',
    state: 'followers',
		emptyTitle: null,
    emptyText: null,
    buttonLink: null,
    buttonText: null,
    learnMore: null
  },
];