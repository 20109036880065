import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch } from 'hooks';

import { Paper, ProductCard } from 'components/ui';
import { setHashLoaded } from 'store/nft-manager';

import classes from './claim.module.scss';
import { useGetNftQuery } from 'store/nft';
import { Content } from './content/content';
import { clearState } from 'store/burn';

export const Claim = () => {
  const dispatch = useAppDispatch();
  const [skip, setSkip] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      setSkip(false);
    }

    return () => {
      dispatch(clearState());
    };
  }, [id]);

  const { data } = useGetNftQuery(id || '', {
    skip,
  });

  const {
    image: nftImage,
    author: { image, username } = {} as any,
    name: nftName,
  } = (data || {}) as any;

  return (
    <Paper className={classes.container}>
      <div className={classes.left}>
        <ProductCard
          author={{
            image: image,
            username: username,
          }}
          image={nftImage}
          name={nftName}
          setImageLoaded={() => dispatch(setHashLoaded())}
          className={classes.cardWidth}
          isOwner={false}
          withActions={false}
          auction={data?.auction}
          price={data?.price}
        />
      </div>
      <div className={classes.right}>
        <Content nft={data as any} />
      </div>{' '}
    </Paper>
  );
};
