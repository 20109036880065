import React from 'react';
import cn from 'classnames';

import { Loader as LoaderPic } from 'components/icons';

import classes from './loader.module.scss';

interface IProps {
  children?: React.ReactNode;
  loading: boolean;
  theme?: 'dark';
  className?: string;
}

export const Loader: React.FC<IProps> = ({
  children,
  loading,
  theme,
  className,
}) => {
  return (
    <div className={cn(className)}>
      {loading ? (
        <div
          className={cn(classes.loader__container, {
            [classes.loader__container_dark]: theme === 'dark',
          })}
        >
          <LoaderPic
            className={cn(classes.loader__pic, {
              [classes.loader__pic_dark]: theme === 'dark',
            })}
          />
        </div>
      ) : (
        children
      )}
    </div>
  );
};
