import React, { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '..';

import classes from './footer-with-buttons.module.scss';

type Props = {
  activeBtnLabel?: string;
  activeBtnHandler?: () => void;
  activeBtnDisabled?: boolean;
  showActiveBtn?: boolean;
};
//maybe rename
export const FooterWithButtons = ({
  activeBtnLabel,
  activeBtnHandler,
  activeBtnDisabled,
  showActiveBtn,
}: Props) => {
  const navigate = useNavigate();

  return (
    <div className={classes.footer}>
      <div className={classes.container}>
        <Button
          label='Back'
          size='small'
          type='primary'
          variant='outlined'
          onClick={() => navigate(-1)}
        />

        {showActiveBtn && (
          <Button
            label={activeBtnLabel}
            onClick={activeBtnHandler}
            className={classes.listBtn}
            disabled={activeBtnDisabled}
          />
        )}
      </div>
    </div>
  );
};
