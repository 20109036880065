import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import './modal-wrapper.scss';

const MOUNT_NODE = document.getElementById('root');

interface ModalPortalProps {
  children: JSX.Element;
}

export const ModalPortal: React.FC<ModalPortalProps> = ({ children }: ModalPortalProps) => {
  const [container] = useState(() => document.createElement('div'));

  useEffect(() => {
    MOUNT_NODE!.classList.add('blured-root');
    container.classList.add('modal-wrapper');
    document.body.appendChild(container);

    return () => {
      MOUNT_NODE!.classList.remove('blured-root');
      document.body.removeChild(container);
    };
  }, []);

  return ReactDOM.createPortal(children, container);
};
