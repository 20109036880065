export const SOCIAL_LINKS = {
	website: {
		url: '',
		label: 'Website'
	},
	twitter: {
		url: 'https://twitter.com/',
		label: 'Twitter'
	},
	instagram: {
		url: 'https://www.instagram.com/',
		label: 'Instagram'
	},
	facebook: {
		url: 'https://www.facebook.com/',
		label: 'Facebook'
	},
	discord: {
		url: 'https://discord.com/',
		label: 'Discord'
	},
	youtube: {
		url: 'https://www.youtube.com/@',
		label: 'Youtube'
	},
	tiktok: {
		url: 'https://www.tiktok.com/@',
		label: 'TikTok'
	},
	twitch: {
		url: 'https://www.twitch.tv/',
		label: 'Twitch'
	},
	snapchat: {
		url: 'https://www.snapchat.com/',
		label: 'Snapchat'
	}
};