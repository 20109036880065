import React from 'react';

import { ModalPortal } from 'components/ui';
import { Close } from 'components/icons';
import classes from './nft-modal.module.scss';

type Props = {
  setOpen: () => void;
  open: boolean;
  src: string;
};

export const NFTModal = ({ setOpen, open, src }: Props) => {
  if (!open) return null;

  return (
    <ModalPortal>
      <div className={classes.nft__modal}>
        <div className={ classes.close } onClick={setOpen}>
          <Close style={{ fill: '#cccccc' }} />
        </div>
        <div
          className={classes.nfr__modal_wrap}
          style={{ backgroundImage: `url(${ src })` }}
        />
      </div>
    </ModalPortal>
  );
};
