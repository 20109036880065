import React from 'react'

import classes from './create-nft-wrapper.module.scss';

interface IProps{
    title: string
    tooltip?: string
    children: React.ReactNode
}

export const CreateNftWrapper: React.FC<IProps> = ({ title, tooltip, children }) => {
  return (
    <div className={ classes.paper }>
      <div className={ classes.head }>
        <h3 className={ classes.title }>{ title }</h3>
        <div className={ classes.step }>{ tooltip }</div>
      </div>
      {
        children
      }
    </div>
  );
}
