import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { removeFromLocalStorage, writeToLocalStorage } from 'libs';
import { LAST_CONNECT_KEY } from 'config';

import {
  updateUserData,
  login,
  // getSubscriptions,
  getUserByWallet,
  checkUsername,
  sendRequestBecomeAuthor,
  sendReport,
} from './actions';
import { IInitialState, ILogin, IProfileChanges, ISubscription } from './types';

const initialState: IInitialState = {
  userData: undefined,
  userChanges: {},
  isCheckingUsernameLoading: false,
  userNameIsAvailable: false,
  updateError: false,
  error: '',
  // showPreview: false,
  isLoading: true,
  address: null,
  balance: 0,
  signature: null,
  openConnector: false,
  isMetamaskInstalled: window.ethereum ? window.ethereum.isMetaMask : false,
  chainId: null,
  showCongratulations: false,
  subscriptions: [],
  isSubscriptionsLoading: false,
  profileData: null,
  isRequestBecomeAuthor: false,
};

export const profile = createSlice({
  name: 'profileState',
  initialState,
  reducers: {
    updateProfileData: (state: any, action) => {
      state.userData = action.payload;
    },
    clearProfile: (state: any) => {
      state.userData = undefined;
      state.userChanges = {};
      state.isCheckingUsernameLoading = false;
      state.userNameIsAvailable = false;
      state.updateError = false;
      state.error = '';
      state.previewUserData = null;
      // state.showPreview = false;
      state.isLoading = false;
      state.address = null;
      state.balance = null;
      state.signature = null;
      state.openConnector = false;
      state.isMetamaskInstalled = window.ethereum
        ? window.ethereum.isMetaMask
        : false;
      state.chainId = null;
      state.showCongratulations = false;
    },
    setCongratulations: (state, action: PayloadAction<boolean>) => {
      state.showCongratulations = action.payload;
    },
    // updateShowPreview: (state: any, action: any) => {
    //   state.showPreview = action.payload;
    // },
    removeUserFollower: (state: any, action) => {
      state.userData = {
        ...state.userData,
        subscribedTo: (state.userData.subscribedTo || []).filter(
          (subscribe: ISubscription) =>
            subscribe.author.id !== action.payload.author_id
        ),
      };
    },
    addUserFollower: (state: any, action) => {
      state.userData = {
        ...state.userData,
        subscribedTo: [
          ...state.userData.subscribedTo,
          {
            follower: { id: action.payload.follower },
            author: { id: action.payload.author },
          },
        ],
      };
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setOpenConnector: (state, action: PayloadAction<boolean>) => {
      state.openConnector = action.payload;
    },
    addSubscription: (state, action: PayloadAction<string>) => {
      const wallet = action.payload;

      if (!wallet) {
        return;
      }

      state.userData.following.push(wallet);
    },
    removeSubscription: (state, action: PayloadAction<string>) => {
      const wallet = action.payload;

      state.userData.following = state.userData.following.filter(
        (item: any) => item !== wallet
      );
    },
    clearProfileData: (state) => {
      state.profileData = null;
    },
    setUserChanges: (state, action: PayloadAction<IProfileChanges>) => {
      let key: keyof typeof state.userChanges;
      let value: any;

      state.userChanges.meta = state.userData.meta;

      // @ts-ignore
      for ([key, value] of Object.entries(action.payload)) {
        if (!key) {
          continue;
        }

        const metaKey: any = key.includes('.') ? key.split('.')[1] : null;

        if (metaKey) {
          if (!state.userChanges.meta) {
            state.userChanges.meta = {};
          }

          state.userChanges.meta[metaKey] = value;
        } else {
          state.userChanges[key] = value;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        login.fulfilled,
        (state, action: PayloadAction<ILogin | null>) => {
          if (!action.payload) {
            return;
          }

          const { signature, connector, address, balance, chainId, ...params } =
            action.payload;

          writeToLocalStorage(LAST_CONNECT_KEY, { connector, signature });

          state.userData = params;
          state.signature = signature;
          state.address = address;
          state.balance = balance;
          state.chainId = chainId;
          state.isLoading = false;
        }
      )
      .addCase(login.rejected, (state, action: any) => {
        state.error = action.payload
          ? action.payload?.errorMessage
          : action.error.message;
        state.isLoading = false;
        state.openConnector = false;
      })
      .addCase(updateUserData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        updateUserData.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.showCongratulations = true;
          state.userData = action.payload;
          state.isLoading = false;
          state.userChanges = {};
          // state.showPreview = false;
          state.profileData = null;
        }
      )
      // .addCase(getSubscriptions.pending, state => {
      //   state.isSubscriptionsLoading = true;
      // })
      // .addCase(getSubscriptions.fulfilled, (state, action: PayloadAction<any>) => {
      //   state.subscriptions = action.payload;
      //   state.isSubscriptionsLoading = false;
      // })
      // .addCase(getSubscriptions.rejected, state => {
      //   state.isSubscriptionsLoading = false;
      // })
      .addCase(
        getUserByWallet.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.profileData = action.payload;
        }
      )
      .addCase(checkUsername.pending, (state) => {
        state.isCheckingUsernameLoading = true;

        if (state.userChanges?.username) {
          delete state.userChanges.username;
        }

        state.userNameIsAvailable = false;
      })
      .addCase(
        checkUsername.fulfilled,
        (state, action: PayloadAction<string | null>) => {
          const username = action.payload;

          state.userChanges.username = username;

          if (username) {
            state.userNameIsAvailable = true;
          }

          state.isCheckingUsernameLoading = false;
        }
      )
      .addCase(sendRequestBecomeAuthor.pending, (state) => {})
      .addCase(
        sendRequestBecomeAuthor.fulfilled,
        (state, action: PayloadAction<any>) => {
          const { id } = action.payload;

          if (id) {
            state.isRequestBecomeAuthor = true;
          }
        }
      )
      .addCase(
        sendRequestBecomeAuthor.rejected,
        (state, action: PayloadAction<any>) => {}
      )
      .addCase(sendReport.pending, (state) => {})
      .addCase(sendReport.fulfilled, (state, action: PayloadAction<any>) => {})
      .addCase(sendReport.rejected, (state, action: PayloadAction<any>) => {});
  },
});

export const {
  clearProfile,
  setLoading,
  setOpenConnector,
  // updateShowPreview,
  setCongratulations,
  addSubscription,
  removeSubscription,
  clearProfileData,
  setUserChanges,
} = profile.actions;
