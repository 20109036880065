import { axios } from 'libs';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { API_METHODS } from 'config';

import {
  IGetProfileNftProps,
  IGetListProps,
  IGetDetailsProps,
} from './interface';

const _axios = axios.getAxios();

export const getProfileNft = createAsyncThunk(
  'nftList/getProfileNft',
  async ({ key, author }: IGetProfileNftProps, { rejectWithValue }) => {
    try {
      const url =
        API_METHODS[
          `GET_${key.toUpperCase()}_NFTS` as keyof typeof API_METHODS
        ];

      if (!url) {
        return rejectWithValue({
          message: `We can't create url for getting ${key} nft's.`,
        });
      }

      const { data = [] } = await _axios.get(url, {
        params: {
          author,
        },
      });

      if (!data.length) {
        return [];
      }

      return data.reduce((acc: any[], item: any) => {
        const {
          id,
          name,
          image,
          description,
          genre,
          nft_id,
          price,
          status,
          author,
          owner,
          auction,
          is_owner,
          is_open_box,
          is_mark_minted,
          can_list_or_open,
        } = item;
        const object = {
          id,
          name,
          image,
          auction,
          description,
          genre,
          nft_id,
          price,
          status,
          is_owner,
          is_mark_minted,
          isOpenBox: is_open_box,
          canListOrOpen: can_list_or_open,
          author: {
            image: author.image,
            username: author.username,
          },
          owner: {
            image: owner.image,
            username: owner.username,
          },
        };

        // if (auctions) {
        //   const findAuction = auctions[auctions.length - 1];

        //   if (findAuction) {
        //     const events = findAuction.event;

        //     object.auction = {
        //       status: findAuction.is_active,
        //     };

        //     if (events.length) {
        //       for (let i = events.length - 1; i >= 0; i--) {
        //         if (events[i].event_type.includes('BidSubmitted')) {
        //           object.auction.lastBid = events[i].args.amount.toString();
        //           object.auction.endTimestamp = events[i].args.endTimestamp;
        //           break;
        //         }
        //       }
        //     }
        //   }
        // }

        acc.push(object);

        return acc;
      }, []);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getFeeds = createAsyncThunk(
  'nftList/getFeeds',
  async (args: any, { rejectWithValue }) => {
    try {
      const { data = [] } = await _axios.get(API_METHODS.GET_FEED);

      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getNftList = createAsyncThunk(
  'nftList/getNftList',
  async (args: IGetListProps, { rejectWithValue }) => {
    try {
      const url = `${API_METHODS.GET_NFTS}${
        Object.keys(args).length > 0 ? `/?${new URLSearchParams(args)}` : '/'
      }`;
      const { data = [] } = await _axios.get(url);

      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getNftDetails = createAsyncThunk(
  'nftList/getNftDetails',
  async ({ id }: IGetDetailsProps, { rejectWithValue }) => {
    try {
      const { data = null } = await _axios.get(`${API_METHODS.GET_NFT}${id}/`);

      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);
