import { configureStore, createAsyncThunk } from '@reduxjs/toolkit';

import { API_METHODS } from 'config';
import { axios } from 'libs';

const _axios = axios.getAxios();

export const getPrices = createAsyncThunk(
  'priceStore/getPrices',
  async (args, { rejectWithValue }) => {
    try {
      const { data } = await _axios.get(API_METHODS.GET_PRICES);
      console.log('data', data);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
