import { Genre } from 'components/ui';
import { FILTER_PARAMS, URLS } from 'config';
import { useWindowSize } from 'hooks/useWindowSize/useWindowSize';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IGenre } from 'store/genre';

import classes from './genres-gallery.module.scss';
import { getDesktopRows, getMobileRows, getTabletRows } from './utils';

const defaultDevice = {
  mobile: false,
  tablet: false,
  desktop: false,
};
const minDesktopWidth = 1000;
const minTabletWidth = 550;

interface IProps {
  genres: IGenre[];
}

export const GenresGallery: React.FC<IProps> = ({ genres }) => {
  const navigate = useNavigate();
  const windowSize = useWindowSize();
  const [device, setDevice] = React.useState(defaultDevice);

  const data =
    React.useMemo(() => {
      if (device.desktop) {
        return getDesktopRows(genres);
      }
      if (device.tablet) {
        return getTabletRows(genres);
      }
      if (device.mobile) {
        return getMobileRows(genres);
      }
    }, [device, genres]) || [];

  React.useEffect(() => {
    const { width } = windowSize;

    if (width >= minDesktopWidth && !device.desktop) {
      setDevice({ ...defaultDevice, desktop: true });
    }
    if (width < minDesktopWidth && width >= minTabletWidth && !device.tablet) {
      setDevice({ ...defaultDevice, tablet: true });
    }
    if (width < minTabletWidth && !device.mobile) {
      setDevice({ ...defaultDevice, mobile: true });
    }
  }, [windowSize]);

  const handleClick = (name: string) => {
    navigate(`${URLS.NFT_PAGE}/?${FILTER_PARAMS.NFT_TYPE}=${name}`);
  };

  return (
    <div className={classes.gallery}>
      {data.map((subArr: IGenre[], index: number) => {
        const rawClass = classes[`row_${subArr.length}`];
        const prevSubArr = data[index - 1];
        const sameLength =
          prevSubArr?.length === subArr.length && !device.mobile;
        return (
          <div
            className={`${classes.row} ${rawClass} ${classes.row_margin}`}
            key={index}
          >
            {subArr.map((item: IGenre, index: number) => {
              const width =
                index < 1 ? classes.row__item_40 : classes.row__item_60;
              return (
                <div
                  id={sameLength ? `${width}` : ''}
                  className={classes.row__item}
                  key={item.name}
                  onClick={() => handleClick(item.name)}
                >
                  <Genre image={item.upload} name={item.name} />
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
