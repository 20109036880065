import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  CREATE_NFT_STEPS,
  FILTER_PARAMS,
  mintingSteps
} from 'config';
import {
  Loader,
  EtherscanLink,
  ProcessSteps,
  ProcessText,
  ProcessProfile
} from 'components/ui';
import {
  useAppSelector,
  useAppDispatch,
  useContract
} from 'hooks';
import {
  clearState,
  mintNFT
} from 'store/nft-manager';

import classes from './mint-nft.module.scss';

export const MintNFT = () => {
  const dispatch = useAppDispatch();
  const [ searchParams, setSearchParams ] = useSearchParams();
  const { nftContract } = useContract();
  const {
    IPFSHash,
    name,
    genre,
    description,
    mintingStep,
    mintingError,
    txHashMint
  } = useAppSelector((state) => state.NFTManagerStore);
  const {
    userData,
    address
  } = useAppSelector(state => state.profileStore);

  useEffect(() => {
    if (!IPFSHash && !name && !genre && !description || !nftContract) {
      return;
    }

    dispatch(mintNFT());
  }, [ IPFSHash, name, genre, description ]);

  useEffect(() => {
    if (mintingError) {
      dispatch(clearState());
      searchParams.set(FILTER_PARAMS.STEP, `${ CREATE_NFT_STEPS.uploadImage.index }`);
      setSearchParams(searchParams);
    }
  }, [ mintingError ]);

  return (
    <div className={ classes.container }>
      <ProcessSteps
        steps={ 4 }
        current={ mintingStep }
      />
      <ProcessText
        current={ mintingStep }
        steps={ mintingSteps }
      />

      <div className={ classes.steps__launch }>
        {
          mintingStep === 2 &&
          <ProcessProfile
            image={ userData && userData.image }
            username={ userData && userData.username }
            address={ address }
          />
        }
        {
          mintingStep && mintingStep > 2 && <EtherscanLink txHash={ txHashMint } />
        }
        <Loader loading={ mintingStep !== 4 }>
          <div style={{ width: 80, height: 80 }} />
        </Loader>
      </div>
    </div>
  );
};
