import React from 'react';
import classNames from 'classnames';

import { Message, MessageType } from 'components/ui';

import styles from './text-area.module.scss';

interface IProps extends React.HTMLProps<HTMLTextAreaElement> {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  label?: string;
  message?: string;
  errorMessage?: string;
  className?: any;
  valueCounter?: any;
  maxLength: number;
}

export const TextArea: React.FC<IProps> = ({
  value,
  onChange,
  label,
  message,
  errorMessage,
  className,
  maxLength,
  valueCounter = false,
  ...props
}) => {
  return (
    <div className={styles.TextareaContainer}>
      {label && <label className={styles.Label}>{label}</label>}
      <textarea
        value={value || ''}
        onChange={(event) => onChange(event)}
        className={classNames(
          styles.Textarea,
          errorMessage && styles.error,
          className?.Textarea
        )}
        maxLength={maxLength}
        {...props}
      />
      <div className={styles.Message}>
        <div>
          {errorMessage && (
            <Message type={MessageType.error} text={errorMessage} />
          )}
          {message && <Message type={MessageType.neutral} text={message} />}
        </div>
        {valueCounter && (
          <span className={styles.maxlength}>
            {`${value?.length || 0}/${maxLength}`}
          </span>
        )}
      </div>
    </div>
  );
};
