import { lazy } from 'react';

import { URLS } from './urls';

export const routes = [
  {
    path: URLS.HOME_PAGE,
    element: lazy(async () => await import('components/pages/home/home')),
  },
  {
    path: URLS.ABOUT_PAGE,
    element: lazy(async () => await import('components/pages/about/about')),
  },
  {
    path: URLS.AUTHORS_PAGE,
    element: lazy(async () => await import('components/pages/authors/authors')),
  },
  {
    path: URLS.PROFILE_PAGE,
    element: lazy(async () => await import('components/pages/profile/profile')),
  },
  {
    path: URLS.PROFILE_EDIT_PAGE,
    element: lazy(
      async () => await import('components/pages/edit-profile/edit-profile')
    ),
  },
  {
    path: URLS.PROFILE_BECOME_ARTIST,
    element: lazy(
      async () =>
        await import('components/pages/become-an-artist/become-an-artist')
    ),
  },
  {
    path: URLS.CREATE_NFT_PAGE,
    element: lazy(
      async () => await import('components/pages/create-nft/create-nft')
    ),
  },
  {
    path: URLS.NFT_DETAILS_PAGE,
    element: lazy(
      async () => await import('components/pages/nft-details/nft-details')
    ),
  },
  {
    path: URLS.NFT_PAGE,
    element: lazy(async () => await import('components/pages/nft/nft')),
  },
  {
    path: URLS.FEED_PAGE,
    element: lazy(async () => await import('components/pages/feed/feed')),
  },
  {
    path: URLS.AUCTIONS_PAGE,
    element: lazy(
      async () => await import('components/pages/auctions/auctions')
    ),
  },
  {
    path: URLS.GENRES_PAGE,
    element: lazy(async () => await import('components/pages/genres/genres')),
  },
  {
    path: URLS.CLAIM_PAGE,
    element: lazy(async () => await import('components/pages/claim/claim')),
  },
  {
    path: URLS.FAQ_PAGE,
    element: lazy(async () => await import('components/pages/faq/faq')),
  },
  {
    path: URLS.BURN_PAGE,
    element: lazy(
      async () => await import('components/pages/burn-nft/burn-nft')
    ),
  },
  {
    path: URLS.DELETE_DRAFT_PAGE,
    element: lazy(
      async () => await import('components/pages/delete-draft/delete-draft')
    ),
  },
  {
    path: URLS.UNLIST_PAGE,
    element: lazy(
      async () => await import('components/pages/unlist-nft/unlist-nft')
    ),
  },
  {
    path: URLS.PLACE_BID,
    element: lazy(
      async () => await import('components/pages/place-bid/place-bid')
    ),
  },

  {
    path: URLS.OPEN_BOX,
    element: lazy(
      async () => await import('components/pages/open-box/open-box')
    ),
  },
  {
    path: URLS.LIST_PAGE,
    element: lazy(async () => await import('components/pages/list/list')),
  },
  {
    path: URLS.CHANGE_RESERVE_PRICE_PAGE,
    element: lazy(
      async () =>
        await import(
          'components/pages/change-reserve-price/change-reserve-price'
        )
    ),
  },
  {
    path: '*',
    element: lazy(
      async () => await import('components/pages/not-found/not-found')
    ),
  },
];
