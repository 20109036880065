import React from 'react';
import classNames from 'classnames';

import styles from './message.module.scss';
import { MessageNeutral, MessageSuccess, MessageError } from 'components/icons';

export enum MessageType {
  'success' = 'success',
  'neutral' = 'neutral',
  'error' = 'error',
}

interface IMessageProps {
  type: MessageType;
  text: string;
}

// TODO refactor
export const Message = ({ type, text }: IMessageProps) => {
  const getIcon = () => {
    switch (type) {
      case MessageType.error:
        return <MessageError className={styles.Icon} />;
      case MessageType.neutral:
        return <MessageNeutral className={styles.Icon} />;
      case MessageType.success:
        return <MessageSuccess className={styles.Icon} />;
      default:
        return;
    }
  };

  return (
    <span className={classNames(styles.Message, type && styles[type])}>
      {getIcon()}
      {text}
    </span>
  );
};
