import { createAsyncThunk } from '@reduxjs/toolkit';
import { NETWORK } from 'config';

import { getContract } from 'hooks';
import { logger } from 'libs';

import { setStep, setTxHash } from './reducer';

export const openBox = createAsyncThunk(
  'openBoxStore/openBox',
  async ({ nftId }: any, { getState, dispatch }) => {
    const { nftContract } = getContract();

    try {
      // const {
      //   openBoxStore: { },
      // }: any = getState();

      console.log('OPEN BOX START:', nftId);
      dispatch(setStep(1));
      const openBox = await nftContract.reveal(nftId);
      dispatch(setStep(2));

      await openBox.wait();

      console.log('OPEN BOX FINISH:', openBox);

      dispatch(setTxHash(openBox.hash));
      dispatch(setStep(3));

      logger.info(`OPEN BOX END`);

      return {};
    } catch (error: any) {
      return {
        error: error.reason,
      };
    }
  }
);
