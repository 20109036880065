import React from 'react';

export const SearchIcon: React.FC<React.SVGAttributes<SVGElement>> = (props) => {
  return (
    <svg
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <mask id='mask0_152_613' maskUnits='userSpaceOnUse' x='0' y='0' width='28'
            height='28'>
        <rect width='28' height='28' fill='#D9D9D9'/>
      </mask>
      <g mask='url(#mask0_152_613)'>
        <path
          d='M21.9334 23.8292L15.4584 17.3542C14.8751 17.8014 14.2042 18.1611 13.4459 18.4333C12.6876 18.7056 11.8806 18.8417 11.0251 18.8417C8.86673 18.8417 7.03895 18.088 5.54173 16.5807C4.04451 15.0741 3.2959 13.2417 3.2959 11.0833C3.2959 8.925 4.04451 7.09216 5.54173 5.58483C7.03895 4.07828 8.86673 3.325 11.0251 3.325C13.1834 3.325 15.0162 4.07828 16.5236 5.58483C18.0301 7.09216 18.7834 8.925 18.7834 11.0833C18.7834 11.9389 18.6523 12.7458 18.3902 13.5042C18.1273 14.2625 17.7723 14.9236 17.3251 15.4875L23.8292 22.0208C24.0626 22.2542 24.1792 22.5509 24.1792 22.911C24.1792 23.2703 24.0528 23.5764 23.8001 23.8292C23.5473 24.0819 23.2315 24.2083 22.8527 24.2083C22.4732 24.2083 22.1667 24.0819 21.9334 23.8292ZM11.0251 16.1583C12.4445 16.1583 13.6501 15.6672 14.6417 14.6848C15.6334 13.7033 16.1292 12.5028 16.1292 11.0833C16.1292 9.66389 15.6334 8.463 14.6417 7.48066C13.6501 6.49911 12.4445 6.00833 11.0251 6.00833C9.60562 6.00833 8.40512 6.49911 7.42356 7.48066C6.44123 8.463 5.95006 9.66389 5.95006 11.0833C5.95006 12.5028 6.44123 13.7033 7.42356 14.6848C8.40512 15.6672 9.60562 16.1583 11.0251 16.1583Z'
          fill='#6F6767'/>
      </g>
    </svg>
  );
};
