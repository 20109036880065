import React from 'react';
import classNames from 'classnames';

import styles from './button.module.scss';

export interface IButtonProps {
  // TEMPTODO - Последние 2 лишние
  type?: 'primary' | 'rounded' | 'secondary' | 'tertiary';
  variant?: 'contained' | 'outlined';
  disabled?: boolean;
  size?: 'small' | 'medium';
  label: any;
  icon?: JSX.Element;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  hover?: string;
  className?: string;
  isSubmit?: boolean;
  full?: boolean;
  id?: string;
}

export const Button = (params: IButtonProps) => {
  const {
    type = 'primary',
    size = 'medium',
    disabled = false,
    variant = 'contained',
    icon,
    label,
    hover,
    className,
    isSubmit,
    full,
    ...props
  } = params;

  return (
    <button
      type={isSubmit ? 'submit' : 'button'}
      className={classNames(
        styles.Button,
        styles[type],
        styles[variant],
        styles[size],
        disabled && styles['disabled'],
        className,
        { [styles.full]: full }
      )}
      disabled={disabled}
      {...props}
    >
      {icon && <span className={styles.Icon}>{icon}</span>}
      {label}
    </button>
  );
};
