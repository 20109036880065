import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Paper,
  ProductCard,
  InfoText,
  PriceInput,
  LinkToFaq,
  Button,
  Agreement,
  ProcessSteps,
  ProcessText,
  ProcessProfile,
  Loader,
  EtherscanLink,
  StepLabel,
} from 'components/ui';
import { useAppSelector, useAppDispatch, useContract } from 'hooks';
import { clearState, setAmount, setAgreement, placeBid } from 'store/bid';
import { URLS, INDEX_URLS, GET_TARGET_PAGE, bidSteps, bidErrors } from 'config';
import { useGetNftQuery } from 'store/nft';

import classes from './place-bid.module.scss';
import { FooterWithButtons } from 'components/ui/footer-with-buttons/footer-with-buttons';
import { getPrices } from 'store/currency';
import { TigerUsd } from 'components/ui/tiger-usd/tiger-usd';
import { formatAmount } from 'helpers';
import { TCurrency } from 'store/currency/types';

export const PlaceBid = () => {
  const { utils: etherUtils } = useContract();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    bidStep: transactionStep,
    amount,
    agreement,
    txHash,
    bidError,
    isBid,
  } = useAppSelector((state) => state.bidStore);
  const { userData, address } = useAppSelector((state) => state.profileStore);
  const { prices, isLoading: isPriceLoading } = useAppSelector(
    (state) => state.priceStore
  );

  const { data: nft } = useGetNftQuery(id!, {
    skip: !id,
  });

  const currency: TCurrency = nft?.auction?.currency;
  useEffect(() => {
    dispatch(getPrices());

    if (!nft) {
      return;
    }

    return () => {
      dispatch(clearState());
    };
  }, []);

  const onHandleAmount = (value: string) => {
    dispatch(setAmount(value));
  };

  const onHandleBid = () => {
    if (!nft || !nft.nftId) {
      return;
    }

    dispatch(placeBid({ nftId: nft.nftId, currency }));
  };

  const onHandleNavigate = () => {
    if (!nft || !nft.nftId) {
      return;
    }

    navigate(URLS.AUCTIONS_PAGE);
  };

  if (!nft) {
    return null;
  }

  const isLoaderVisible = transactionStep && transactionStep !== 3;

  //начались транзакции это 2 шаг, заполнение форм - 1
  const step = !transactionStep ? 1 : 2;
  const titleStep = !transactionStep ? 'Set a bid amount' : 'Place a bid';

  const price = nft.auction?.lastBet || nft.price || 0;
  //если была ставка, то умножаем, а если нет то из обычной цены
  const bidAtleast =
    price * (nft.auction?.lastBet ? nft.auction.increaseCoefficient : 1);

  const showInputError = Boolean(amount && +amount < +bidAtleast);

  return (
    <>
      <Paper className={classes.container}>
        <div className={classes.header}>
          <div className={classes.title}>{titleStep}</div>
          <StepLabel current={step} max={2} />
        </div>

        <div className={classes.grid}>
          <div className={classes.left}>
            <ProductCard
              author={{
                image: nft.author.image,
                username: nft.author.username,
              }}
              image={nft.image}
              name={nft.name}
              isOwner={false}
              withActions={false}
              className={classes.cardWidth}
              price={nft.price}
              auction={nft.auction}
            />
          </div>
          <div className={classes.right}>
            {transactionStep === null && (
              <>
                <InfoText
                  text={`You must bid at least ${formatAmount(
                    bidAtleast,
                    nft.auction.currency
                  )} ${nft.auction.currency}`}
                  className={classes.info}
                />
                <div className={classes.input}>
                  <PriceInput
                    value={amount ? `${amount}` : ''}
                    placeholder='Enter bid amount'
                    label='Bid amount'
                    onChange={onHandleAmount}
                    errorMessage={
                      showInputError
                        ? `Minimum bid is ${formatAmount(
                            bidAtleast,
                            nft.auction.currency
                          )} ${nft.auction.currency}`
                        : ''
                    }
                  />
                  <div className={classes.usd}>
                    <TigerUsd
                      isPriceLoading={isPriceLoading}
                      tigerPrice={+prices[currency]}
                      value={Number(amount)}
                    />
                  </div>
                </div>
              </>
            )}
            {!transactionStep && (
              <div className={classes.buttons}>
                <Button
                  label={'Place a bid'}
                  onClick={onHandleBid}
                  className={classes.listBtn}
                  disabled={!amount || showInputError}
                />
              </div>
            )}
            {transactionStep && transactionStep >= 1 && !bidError && (
              <>
                <ProcessSteps steps={3} current={transactionStep} />
                <ProcessText steps={bidSteps} current={transactionStep} />
                {transactionStep === 1 && (
                  <ProcessProfile
                    image={userData && userData.image}
                    username={userData && userData.username}
                    address={address}
                  />
                )}
                {transactionStep > 1 && <EtherscanLink txHash={txHash} />}
              </>
            )}
            {isBid && (
              <div className={classes.buttons}>
                <Button label='Go to auctions' onClick={onHandleNavigate} />
              </div>
            )}
            {bidError && (
              <>
                <InfoText text={bidError} type='error' />
                <Button
                  label={'Try again'}
                  onClick={() => dispatch(clearState())}
                  className={classes.listBtn}
                />
              </>
            )}

            {isLoaderVisible && (
              <Loader loading className={classes.loaderSize} />
            )}
          </div>
        </div>
      </Paper>
      <FooterWithButtons
        activeBtnHandler={onHandleBid}
        activeBtnLabel='Place a bid'
        activeBtnDisabled={!amount || showInputError || !currency}
        // showActiveBtn={!transactionStep}
      />
    </>
  );
};
