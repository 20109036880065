import React from 'react';
import { createRoot } from 'react-dom/client';

import { App } from 'components/app';

//for web3 connect coinbase/WalletConnect
import { Buffer } from 'buffer';
window.Buffer = window.Buffer || Buffer;

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(<App />);
