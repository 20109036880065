import React from 'react';
import { FollowingButton } from '../following-button/following-button';

import classes from './follows-item.module.scss';
import { getImage } from 'libs/utils/utils';

interface IFollowingData {
  action: string | null;
  authorId: number;
  wallet: string;
}

type Props = {
  authorId: number;
  wallet: string;
  image?: string;
  first_name?: string;
  username: string;
  onHandleSubscribe: (data: IFollowingData) => void;
  isFollowing: boolean;
};

export const FollowsItem = ({
  authorId,
  wallet,
  image,
  first_name,
  username,
  onHandleSubscribe,
  isFollowing,
}: Props) => {
  const handleUserClick = () => {};

  return (
    <div className={classes.FollowsItem}>
      <div className={classes.FollowsItem__user}>
        <img
          src={getImage(image || '')}
          className={classes.FollowsItem__avatar}
        />

        <div className={classes.FollowsItem__info}>
          <div
            className={classes.FollowsItem__username}
            onClick={handleUserClick}
          >
            @{username ? username : 'anonym'}
          </div>

          <div className={classes.FollowsItem__first_name}>
            {first_name ? first_name : 'Anonym'}
          </div>
        </div>
      </div>

      <FollowingButton
        onClick={() => {
          onHandleSubscribe({
            action: isFollowing ? 'delete' : !isFollowing ? 'add' : null,
            authorId: authorId,
            wallet: wallet,
          });
        }}
        isFollow={isFollowing}
        disabled={false}
      />
    </div>
  );
};
