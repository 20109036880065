import cl from './step-label.module.scss';

type Props = {
  current: number;
  max: number;
};
export const StepLabel = ({ current, max }: Props) => {
  return (
    <div className={cl.step}>
      Step {current}/{max}
    </div>
  );
};
