import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IState } from './interface';
import { unlist as unlistActions } from './actions';

const initialState: IState = {
  step: null,
  error: null,
  txHash: null,
  isLoading: false,
  isCompleted: false,
};

export const unlistSlice = createSlice({
  name: 'burn',
  initialState,
  reducers: {
    clearState: () => initialState,
    setStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    setTxHash: (state, action: PayloadAction<string>) => {
      state.txHash = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      unlistActions.fulfilled,
      (state, action: PayloadAction<any>) => {
        const { error } = action.payload;

        if (error) {
          return (state.error = error);
        }

        state.isCompleted = true;
        state.step = 4;
      }
    );
  },
});

export const { clearState, setStep, setTxHash } = unlistSlice.actions;
