import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { axios } from 'libs';
import { API_METHODS } from 'config';

import { ValidationErrors } from './types';

const _axios = axios.getAxios();

export const getFollowing = createAsyncThunk(
  'profileStore/getFollowing',
  async (id: number, { rejectWithValue}) => {     
    try {
      const { data } = await _axios.get(`${API_METHODS.GET_SUBSCRIPTION}?user_id=${ id }`);

      if (!data) {
        return null;
      }

      return data.map((item: any) => item.author);      
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err;

      if (err.statusCode === 404) {
        return rejectWithValue(err);
      }

      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const getFollowers = createAsyncThunk(
  'profileStore/getFollowers',
  async (id: number, { rejectWithValue}) => {     
    try {
      const { data } = await _axios.get(`${API_METHODS.GET_SUBSCRIBERS}${ id }`);

      if (!data) {
        return null;
      }

      return data.map((item: any) => item.follower);      
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err;

      if (err.statusCode === 404) {
        return rejectWithValue(err);
      }

      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);
