import React from 'react';

export const LeftChevron: React.FC<React.SVGAttributes<SVGElement>> = (
  props
) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <mask
        id='mask0_709_3115'
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='24'
        height='24'
      >
        <rect width='24' height='24' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_709_3115)'>
        <path
          d='M13.2004 17.4L8.60039 12.8C8.48372 12.6834 8.40039 12.5584 8.35039 12.425C8.30039 12.2917 8.27539 12.15 8.27539 12C8.27539 11.85 8.30039 11.7084 8.35039 11.575C8.40039 11.4417 8.48372 11.3167 8.60039 11.2L13.2004 6.60002C13.4171 6.38336 13.6837 6.27502 14.0004 6.27502C14.3171 6.27502 14.5837 6.38336 14.8004 6.60002C15.0171 6.81669 15.1254 7.08336 15.1254 7.40002C15.1254 7.71669 15.0171 7.98336 14.8004 8.20002L11.0004 12L14.8004 15.8C15.0171 16.0167 15.1254 16.2834 15.1254 16.6C15.1254 16.9167 15.0171 17.1834 14.8004 17.4C14.5837 17.6167 14.3171 17.725 14.0004 17.725C13.6837 17.725 13.4171 17.6167 13.2004 17.4Z'
          fill='#d3d2d4'
        />
      </g>
    </svg>
  );
};
