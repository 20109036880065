import React from 'react';

import { Close } from 'components/icons';
import { Button } from 'components/ui';

import classes from './modal-note.module.scss';

interface IProps {
  title: string;
  text: string;
  onClick: () => void;
  onClose: () => void;
}

export const ModalNote: React.FC<IProps> = ({
  onClick,
  onClose,
  text,
  title,
}) => {
  return (
    <div className={classes.modal}>
      <Close className={classes.modal__close} onClick={onClose} />
      <div className={classes.modal__title}>{title}</div>
      <div className={classes.modal__text}>{text}</div>
      <div className={classes.modal__btn}>
        <Button
          label='Ok'
          onClick={onClick}
          size='medium'
          type='primary'
          variant='contained'
        />
      </div>
    </div>
  );
};
