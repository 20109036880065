import { createAsyncThunk } from '@reduxjs/toolkit';

import { getContract } from 'hooks';
import { logger } from 'ethers';

import { setStep, setTxHash } from './reducer';

export const burn = createAsyncThunk(
  'burnStore/burn',
  async ({ nftId }: { nftId: string | number }, { dispatch }) => {
    const { nftContract } = getContract();
    dispatch(setStep(2));

    try {
      const burn = await nftContract.burn(nftId);
      dispatch(setStep(3));
      dispatch(setTxHash(burn.hash));

      await burn.wait();

      logger.info(`[ NFT IS BURNED ]`);

      return {};
    } catch (error: any) {
      console.log({ error });

      return {
        error: error.reason,
      };
    }
  }
);
