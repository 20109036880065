import React, { useEffect, useState } from 'react';

import { AuthorCard, Loader } from 'components/ui';
import { IFollowingData } from 'components/ui/author-card/author-card.interface';
import { getAuthors, subscribe, unSubscribe } from 'store/authors';
import { useAppSelector, useAppDispatch, useQuery } from 'hooks';

import classes from './authors-list.module.scss';
import { NETWORK } from 'config';
import { ITEMS_PER_PAGE } from 'config/pagination';
import { Pagination } from 'components/ui/pagination/pagination';

export const AuthorsList = () => {
  const dispatch = useAppDispatch();
  const params = useQuery();
  const { userData = {} } = useAppSelector((state) => state.profileStore);
  const { authors, isLoading, isSubscribeProcess, count } = useAppSelector(
    (state) => state.authorsStore
  );
  const [itemOffset, setItemOffset] = useState(0);
  const [selectedPage, setSelectedPage] = useState(0);
  const pageCount = Math.ceil(count / ITEMS_PER_PAGE);
  const endOffset = itemOffset + ITEMS_PER_PAGE;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  useEffect(() => {
    params.limit = ITEMS_PER_PAGE;
    params.offset = itemOffset;
    dispatch(getAuthors(params));
  }, [itemOffset]);

  const handlePageClick = (event: any) => {
    setSelectedPage(event.selected);
    const newOffset = (event.selected * ITEMS_PER_PAGE) % count;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  const onHandleSubscribe = async ({
    action,
    authorId,
    wallet,
  }: IFollowingData) => {
    if (!userData.id || !action || !authorId) {
      return;
    }

    if (action === 'add') {
      dispatch(
        subscribe({
          follower: userData.id,
          author: authorId,
          wallet,
        })
      );
    }

    if (action === 'delete') {
      dispatch(
        unSubscribe({
          follower: userData.id,
          author: authorId,
          wallet,
        })
      );
    }
  };

  return (
    <Loader loading={isLoading}>
      <Pagination
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        forcePage={selectedPage}
        pageCount={pageCount}
      />
      <div className={classes.page_list}>
        {!isLoading &&
          authors?.map((item: any) => {
            if (item.wallet === NETWORK.auctionAddress) return false;
            return (
              <AuthorCard
                key={item.id}
                authorId={item.id}
                wallet={item.wallet}
                isFollowing={item.is_following}
                description={item.bio}
                followers={item.followers}
                image={item.image}
                name={item.first_name}
                nickName={item.username}
                onHandleSubscribe={onHandleSubscribe}
                subscribeProcess={isSubscribeProcess || !userData.id}
              />
            );
          })}
      </div>
      {!isLoading && !authors.length && (
        <div className={classes.not_found}>Not founded :(</div>
      )}
      <Pagination
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        forcePage={selectedPage}
        pageCount={pageCount}
      />
    </Loader>
  );
};
