import React, { useState } from 'react';
import { useAppDispatch, useAppSelector, useDebounce2 } from 'hooks';
import { IProfileChanges, checkUsername, sendRequestBecomeAuthor, setUserChanges, updateUserData } from 'store/profile';
import { Form, Formik, FieldProps, Field, FormikProps, useFormikContext } from 'formik';
import cn from 'classnames';
import { Button, Input, TextArea } from 'components/ui';
import ValidationSchema from './schema';

import classes from './become-artiste-form.module.scss';

export const BecomeArtisteForm: React.FC = () => {
	const [isDdisabled, setIsDdisabled] = useState<boolean>(true);
	const ref = React.useRef<any>(null);
  const dispatch = useAppDispatch();
  const debounce = useDebounce2(500);
	const {
    isLoading,
    userChanges,
    userNameIsAvailable,
    userData,
    address,
    isCheckingUsernameLoading
  } = useAppSelector((state) => state.profileStore);
	const state = useAppSelector((state) => state);
	const object = { ...userData, ...userChanges };
  const finalObject = {
    ...object,
		cover_letter: '',
		cloud: '',
  };

	const handleOnSubmit = async () => {
		await dispatch(sendRequestBecomeAuthor());
		await dispatch(updateUserData());
	};

	const onHandleChange = async (params: IProfileChanges) => {
    const { username, ...props } = params;

    if (typeof username === 'string' && username.length > 3) {
      await debounce();

      if (username !== userData.username) {
        return dispatch(checkUsername(username));
      }

      return dispatch(setUserChanges({ username: null }));
    }

    dispatch(setUserChanges(props));
  }

	const onHandleClear = (key: string) => {
    const form = ref.current;

    form.setFieldValue(key, '');
    
		dispatch(setUserChanges({
      [ key ]: null
    }));
  }

	// const HandlerTest = () => {
	// 	const {isValid} = useFormikContext();

	// 	useEffect(() => {
	// 		setIsDdisabled(!isValid);
	// 		console.log('[useFormikContext] isValid - ', isValid)
	// 	}, [isValid]);

	// 	return null;
	// };

	return (
		<Formik
			initialValues={finalObject}
			onSubmit={handleOnSubmit}
			validationSchema={() => ValidationSchema()}
			innerRef={ ref }
		>
			{({ errors, touched }: FormikProps<any>) => {
				return (
					<Form
						onChange={({ target }:any) => {
							onHandleChange({ [ target.name ] :  target.value });
						}}
					>						
						<div className={classes.Row}>
							<div className={classes.Column}>
								<h3 className={classes.Title}>Enter your details</h3>
							</div>
							<div className={classes.Column}>
								<div className={ cn(classes.FieldWrap, classes.mb30) }>
									<Field name='first_name'>
										{({ field, meta: { touched, error } }: FieldProps) => (
											<Input
												{...field}
												label='Name'
												placeHolder='Enter your name'
												errorMessage={ error ? error : undefined }
												clearClick={() => {
													onHandleClear('first_name');
												}}
											/>
										)}
									</Field>
								</div>
							</div>
						</div>

						<div className={classes.Row}>
							<div className={classes.Column}>
								<h3 className={classes.Title}>Get email notifications</h3>
								<p className={classes.Description}>
									Add your email address to start getting notifications about
									your activity on KALI. Your email won’t be available to
									other users.
								</p>
							</div>
							<div className={classes.Column}>
								<div className={classes.FieldWrap}>
									<Field name='email'>
										{({ field, meta: { touched, error } }: FieldProps) =>
											(
												<Input
													type='email'
													label='Email'
													errorMessage={error ? error : undefined}
													placeHolder='Enter your email'
													clearClick={() => {
														onHandleClear('email');
													}}
													{...field}
												/>
											)
										}
									</Field>
								</div>
							</div>
						</div>

						<div className={classes.Row}>
							<div className={classes.Column}>
								<h3 className={classes.Title}>Add a cover letter</h3>
							</div>
							<div className={classes.Column}>
								<Field name='cover_letter'>
									{({ field, meta: { touched, error } }: FieldProps) => (
										<TextArea
											{...field}
											label='Cover letter'
											errorMessage={error ? error : undefined}
											maxLength={200}
											placeholder={'Enter cover letter'}
										/>
									)}
								</Field>
							</div>
						</div>

						<div className={classes.Row}>
							<div className={classes.Column}>
								<h3 className={classes.Title}>
									Add links to your portfolio
								</h3>
							</div>
							<div className={classes.Column}>
								<div className='profile-form__soc'>
									<div className={classes.FieldWrap}>
										<Field name='cloud'>
										{({ field, meta: { touched, error } }: FieldProps) => (
												<Input
													{...field}
													type='text'
													errorMessage={error ? error : undefined}
													placeholder='Enter URL'
													label='Cloud'
													// leftIcon={<At />}  dfg
													clearClick={() => {
														onHandleClear('cloud');
													}}
												/>
											)}
										</Field>
									</div>

									<div className={classes.FieldWrap}>
										<Field name='meta.website'>
											{({ field, meta }: FieldProps) => (
												<Input
													{...field}
													type='text'
													errorMessage={
														meta.error ? 'Wrong format' : undefined
													}
													label={'Website'}
													placeholder={'Enter URL'}
													clearClick={() =>{
														onHandleClear('meta.website');
													}}
												/>
											)}
										</Field>
									</div>
								</div>
							</div>
						</div>

						<div className={cn(classes.Row, classes.buttons)}>
							<Button
								disabled={ isLoading || !Object.keys(userChanges).length }
								isSubmit
								label='Send'
								className={ classes.resultButton }
							/>
						</div>

						{/* <HandlerTest /> */}
					</Form>
				);
			}}
		</Formik>
	);
};
