import { HelpIcon } from '../components/icons/help-icon';

export const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;

export const INDEX_URLS = {
  NFT: '/nft/',
  PROFILE: '/profile/',
  BURN: '/burn/',
  DELETE_DRAFT: '/delete-draft/',
  UNLIST: '/unlist/',
  LIST: '/list/',
  CHANGE_RESERVE_PRICE: '/change-reserve-price-nft/',
  CLAIM: '/claim/',
  PLACE_BID: '/place-bid/',
  OPEN_BOX: '/open-box/',
};

export const FILTER_PARAMS = {
  NFT_TYPE: 'image__type__name',
  SEARCH: 'search',
  STATUS: 'status',
  STEP: 'step',
};

export const GET_TARGET_PAGE = (
  index: string,
  param: number | string
): string => `${index}${param}/`;

export const URLS = {
  HOME_PAGE: '/',
  ABOUT_PAGE: '/about',
  AUTHORS_PAGE: '/owners',
  NFT_PAGE: '/nft',
  NFT_DETAILS_PAGE: `${INDEX_URLS.NFT}:id/`,
  FEED_PAGE: '/feed',
  AUCTIONS_PAGE: '/auctions',
  GENRES_PAGE: '/genres',
  CREATE_NFT_PAGE: '/create-nft',
  PROFILE_PAGE: `${INDEX_URLS.PROFILE}:address`,
  PROFILE_EDIT_PAGE: '/profile/edit',
  PROFILE_BECOME_ARTIST: '/profile/become-an-artist',
  FAQ_PAGE: '/faq',
  COMMUNITY_RULES_PAGE: '/community-rules',
  SERVICE_TERMS_PAGE: '/service-terms',
  PRIVACY_TERMS_PAGE: '/privacy-terms',
  FEES_PAGE: '/fees',
  CLAIM_PAGE: `${INDEX_URLS.CLAIM}:id`,
  BURN_PAGE: `${INDEX_URLS.BURN}:id`,
  DELETE_DRAFT_PAGE: `${INDEX_URLS.DELETE_DRAFT}:id`,
  UNLIST_PAGE: `${INDEX_URLS.UNLIST}:id`,
  LIST_PAGE: `${INDEX_URLS.LIST}:id`,
  CHANGE_RESERVE_PRICE_PAGE: `${INDEX_URLS.CHANGE_RESERVE_PRICE}:id`,
  PLACE_BID: `${INDEX_URLS.PLACE_BID}:id`,
  OPEN_BOX: `${INDEX_URLS.OPEN_BOX}:id`,

  TWITTER: 'https://twitter.com',
  INSTAGRAM: 'https://instagram.com',
  TELEGRAM: 'https://telegram.com',
  YOUTUBE: 'https://youtuve.com',
};

export const API_METHODS = {
  GET_TYPES: `${API_DOMAIN}/web3/type/`,
  GET_NFTS: `${API_DOMAIN}/web3/nfts`,
  GET_CREATED_NFTS: `${API_DOMAIN}/web3/nfts/created/`,
  GET_DRAFT_NFTS: `${API_DOMAIN}/web3/nfts/draft/`,
  GET_COLLECTED_NFTS: `${API_DOMAIN}/web3/nfts/collected/`,
  GET_LISTED_NFTS: `${API_DOMAIN}/web3/nfts/listed/`,
  GET_FEED: `${API_DOMAIN}/web3/feed/`,
  GET_ALL_AUCTION_LIST: '/web3/all_auctions/',
  GET_AUCTION_LIST: '/web3/auction/',
  GET_NFT: '/web3/nft_details/',
  DELETE_DRAFT_NFTS: `${API_DOMAIN}/web3/nfts/draft/`,
  // PRICE TOKEN BNB TIGER
  GET_PRICES: '/web3/prices/',
  //GET AUCTION ON PAGE
  GET_UPCOMING_AUCTION: '/web3/upcoming_auction/',
  //GET DELAYED AUCTION ON PAGE
  GET_DELAYED_AUCTION: '/web3/scheduled_auction/',
  //POST DELAYED LISTING
  POST_DELAYED_LISTING: '/web3/create_auction/',

  GET_NFT_HISTORY: '/web3proxy/nfts/history',
  CUSTOMUSER_EDIT: '/customuser/edit',
  CUSTOMUSER_USER: '/customuser/user',
  SUBSCRIPTION: '/subscription',
  ADD_SUBSCRIPTION: '/subscription/subscription/',
  DELETE_SUBSCRIPTION: '/subscription/subscription/',
  GET_SUBSCRIPTION: `${API_DOMAIN}/subscription/subscription/`,
  GET_SUBSCRIBERS: `${API_DOMAIN}/subscription/subscribers/`,
  CUSTOMUSER_USERS: '/customuser/',
  CUSTOMUSER_CREATOR_REPORT: '/customuser/creator_report/',
  CUSTOMUSER_SEND_REPORT: '/customuser/send_report/',
  UPLOAD_IMG: '/upload_image/s3/',
  W3AUTH_LOGIN_API: '/w3auth/login_api',
  /** ссылка для перехода в браузере */
  VERIFY_INSTAGRAM: '/verification/verify/instagram/request',
  /** для отправки полученных из урла данных */
  VERIFY_CHECK_INSTAGRAM: '/verification/verify/instagram/',
  /** ссылка для перехода в браузере */
  VERIFY_TWITTER: '/verification/verify/twitter/request',
  /** для отправки полученных из урла данных */
  VERIFY_CHECK_TWITTER: '/verification/verify/twitter/',
  // UPLOAD_IMG_TO_PINATA: '/upload_image/pinata/',
  UPLOAD_NFT_IMG: '/upload_image/s3/',
  UPLOAD_NFT_IMG_METADATA: '/upload_image/s3/',
  UPDATE_VERIFICATION: '/',
};

export const HEADER_URLS = [
  { url: URLS.AUCTIONS_PAGE, label: 'Auctions' },
  { url: URLS.NFT_PAGE, label: 'NFTs' },
  // TEDO - нвозможно, что нужно будет поменять ссылку
  { url: URLS.AUTHORS_PAGE, label: 'Owners' },
  { url: URLS.ABOUT_PAGE, label: 'About' },
  { url: URLS.FAQ_PAGE, label: 'How to buy' },
];

export const FOOTER_URLS = [
  {
    link: 'https://t.me/BABYBNBTIGERNFT',
    help: true,
    label: 'Help',
    icon: <HelpIcon />,
  },
  { link: 'https://twitter.com/BabyBNBTiger', label: 'Twitter' },
  { link: 'https://t.me/BabyBNBTigerEN', label: 'Telegram' },
  {
    link: 'https://www.dextools.io/app/en/bnb/pair-explorer/0xc7dd912045c2aa9cd12662040efe53e2a7916a54',
    label: 'Chart',
  },
  {
    link: 'https://app.unicrypt.network/amm/pancake-v2/pair/0xc7dd912045c2aa9cd12662040efe53e2a7916a54',
    label: 'LiqLock',
  },
];
