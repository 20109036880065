import React, { useEffect, useRef } from 'react';
import cn from 'classnames';

import { Message, MessageType } from 'components/ui';
import { Check, Arrow } from 'components/icons';

import styles from './drop-down.module.scss';

interface IDropdownProps {
  label: string;
  options: IOption[];
  image?: string;
  open: boolean;
  setOpen: (_: boolean) => void;
  classes?: any;
  btnClasses?: any;
  errorMessage?: string;
  message?: string;
  value?: string;
  closeOnClick?: boolean;
  select?: boolean;
  type?: 'primary' | 'secondary';
}

interface IOption {
  label: string;
  icon?: string;
  checked?: boolean;
  onClick?: (checked: boolean) => void;
  onCheckboxClick?: (e: any) => void;
}

// TODO refactor this.

export const DropDown: React.FC<IDropdownProps> = (params: IDropdownProps) => {
  const {
    label,
    image,
    options,
    open,
    setOpen,
    classes,
    value,
    closeOnClick,
    btnClasses,
    errorMessage,
    message,
    select = true,
    type = 'primary',
  } = params;

  const rootEl = useRef<any>(null);
  const onClick = (e: MouseEvent) => {
    rootEl.current?.contains(e.target) || setOpen(false);
  };

  useEffect(() => {
    document.addEventListener('click', onClick);

    return () => {
      document.removeEventListener('click', onClick);
    };
  }, []);

  return (
    <div ref={rootEl} className={styles.Wrapper}>
      <button
        className={cn(styles.Button, image && styles.withImg, btnClasses, {
          [styles.ButtonPrimary]: type === 'primary',
          [styles.ButtonSecondary]: type === 'secondary',
        })}
        onClick={() => setOpen(!open)}
      >
        {image && <img src={image} alt='avatar' className={styles.Image} />}
        {label}
        {select && (
          <Arrow className={cn(styles.ArrowDown, open && styles.rotate)} />
        )}
      </button>
      {errorMessage && <Message type={MessageType.error} text={errorMessage} />}
      {message && <Message type={MessageType.neutral} text={message} />}
      {select && (
        <div className={cn(styles.Dropdown, classes, { [styles.open]: open })}>
          {options.map((option, key) => (
            <div
              className={cn(styles.Option, {
                [styles.OptionChecked]: option.checked,
                [styles.active]: value === option.label,
              })}
              onClick={() => {
                if (closeOnClick) {
                  setOpen(false);
                }

                if (option.onClick) {
                  option.onClick(!option.checked);
                }
              }}
              key={key}
            >
              {option.icon && (
                <img src={option.icon} alt='icon' className={styles.Icon} />
              )}
              {option.label}
              {/* {value === option.label && <Check />} */}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
