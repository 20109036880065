import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Web3Provider, ExternalProvider } from '@ethersproject/providers';

import { Wrapper, Footer, Header } from 'components/ui';
import { ConnectModal, ShowCongratulations } from 'components/containers';
import { Router } from 'components/router';
import { routes } from 'config';
import { storeConfig } from 'store';

import 'assets/styles/main.scss';
import { Web3ContextProvider } from 'components/contexts/Web3ContextProvider';

export const App = (): JSX.Element => {
  return (
    <Web3ContextProvider>
      <Provider store={storeConfig}>
        <ConnectModal />
        <BrowserRouter>
          <Header />
          <Wrapper>
            <Router routes={routes} />
          </Wrapper>
          <Footer />
          <ShowCongratulations />
        </BrowserRouter>
      </Provider>
    </Web3ContextProvider>
  );
};
