import { IFile } from 'components/containers';
import { INft } from 'store/nft-list';
import defaultUserAvatar from '../../assets/img/default-profile-pic.png';

export const defaultImagePath = defaultUserAvatar;

export const goToMetaMask = (): void => {
  window.open('https://metamask.io/', '_blank')!.focus();
};

export const isMobile = () => {
  const regex =
    /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  return regex.test(navigator.userAgent);
};

export const connectMetamaskMobile = () => {
  const dappUrl = window.location.href.split('//')[1].split('/')[0];
  const metamaskAppDeepLink = 'https://metamask.app.link/dapp/' + dappUrl;
  window.open(metamaskAppDeepLink, '_self');
};

export const shorter = (str: string, length = 6): string => {
  return str?.length > 8 ? str.slice(0, length) + '...' + str.slice(-4) : str;
};

export const formatTime = (unit: string | number): string => {
  return unit.toString().length > 1 ? unit.toString() : `0${unit}`;
};

export const getTime = (executeDate: Date) => {
  const countDown = new Date(executeDate).getTime() - new Date().getTime();
  const hours = Math.floor(countDown / (1000 * 60 * 60));
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  const stringHours =
    hours.toString().length > 1 ? hours.toString() : `0${hours}`;
  const stringMinutes =
    minutes.toString().length > 1 ? minutes.toString() : `0${minutes}`;
  const stringSeconds =
    seconds.toString().length > 1 ? seconds.toString() : `0${seconds}`;

  return { stringHours, stringMinutes, stringSeconds };
};

export const copyToClipboard = (str: string) => {
  if (navigator && navigator.clipboard && navigator.clipboard?.writeText) {
    return navigator.clipboard.writeText(str);
  }

  return null;
};

export const parseQueryString = (string: string) => {
  const str: any = new URLSearchParams(string);
  let data: any = {};
  for (let params of str.entries()) {
    data[params[0]] = params[1];
  }

  return data;
};

export const isGt = (first: number, second: number): boolean => {
  return first > second;
};

export const urlToFile = ({ body, mimeType, name }: IFile): Promise<File> => {
  return fetch(body)
    .then((res) => res.arrayBuffer())
    .then((buf) => new File([buf], name, { type: mimeType }));
};

export const checkUnsupportedChainId = (str: string): boolean => {
  return str.toLowerCase().indexOf('unsupported chain id') > -1;
};

export const fillArray = (steps: number, current: number | null): number[] => {
  const arr = new Array(steps);

  if (!current) {
    return arr.fill(0);
  }

  for (let i = 0; i < steps; i++) {
    arr[i] = i + 1 <= current ? 1 : 0;
  }

  return arr;
};

export const roundBalance = (
  balance: number | undefined | null
): string | number => {
  if (!balance) {
    return 0;
  }

  return balance.toFixed(4);
};

export const getCookie = (name: string) => {
  let matches = document.cookie.match(
    new RegExp(
      '(?:^|; )' +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
        '=([^;]*)'
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

interface Options {
  expires?: Date | number | string;
  path?: string;
  domain?: string;
  secure?: boolean;
  samesite?: 'strict';
  'max-age'?: number;
}

/**Пример использования: setCookie('user', 'John', { secure: true, 'max-age': 3600 }); */
export const setCookie = (
  name: string,
  value: string,
  options: Options = {}
) => {
  options = {
    path: '/',
    ...options,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie =
    encodeURIComponent(name) + '=' + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += '; ' + optionKey;
    let optionValue = options[optionKey as keyof Options];
    if (optionValue !== true) {
      updatedCookie += '=' + optionValue;
    }
  }

  document.cookie = updatedCookie;
};

export const getImage = (src: string): string => {
  return src || defaultImagePath!;
};

export const prepareNftItems = (items: any[]): any[] => {
  return items.map((item: any) => {
    const { author, ...props } = item;
    const { image, ...authorProps } = author;
    const newImage = getImage(image);

    return {
      ...props,
      nftId: props.nft_id,
      image: props.image ? props.image.thumbnail : null,
      IPFSHash: props.image ? props.image.id : null,
      metadata: props.image ? props.image.metadata : null,
      author: {
        ...authorProps,
        image: newImage,
      },
      auction: props?.auction
        ? {
            status: props?.auction?.is_active,
            isMyLastBet: props?.auction?.my_bid
              ? props?.auction?.my_bid?.bidder.wallet ===
                props?.auction?.max_bid?.bidder.wallet
              : false,
            currency: props?.auction?.currency,
            endTimestamp: props?.auction?.time_end,
            myLastBet: props?.auction?.my_bid?.amount,
            lastBet: props?.auction?.max_bid?.amount,
            auctionOwner: props?.auction?.auction_owner,
          }
        : null,
    };
  });
};

export const getUserSocialLinks = (
  nicknames: any,
  socialLinks: any,
  icons: any
): any[] => {
  let links: any[] = [];

  for (let key in nicknames) {
    if (key in socialLinks && nicknames[key]) {
      links.push({
        icon: icons[key],
        link: `${socialLinks[key].url}${nicknames[key]}`,
        label: socialLinks[key].label,
      });
    }
  }

  return links;
};

export const formatDateUnixToString = (unixTimestamp?: number) => {
  if (!unixTimestamp) return;
  const dateObject = new Date(unixTimestamp); // Умножаем на 1000, так как время в Unix-формате указывается в миллисекундах

  const day = String(dateObject.getDate()).padStart(2, '0'); // День месяца с ведущим нулем
  const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Месяц с ведущим нулем (начинается с 0)
  const year = dateObject.getUTCFullYear(); // Полный год
  const hours = String(dateObject.getHours()).padStart(2, '0'); // Часы с ведущим нулем
  const minutes = String(dateObject.getMinutes()).padStart(2, '0'); // Минуты с ведущим нулем
  // Формируем строку в нужном формате
  const formattedDate = `${day}.${month}.${year} ${hours}:${minutes}`;

  return formattedDate;
};
