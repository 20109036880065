import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { API_DOMAIN, API_METHODS } from 'config';
import { TCurrency } from 'store/currency/types';

type PostRequest = {
  price: string;
  currency: TCurrency;
  coefficient: number;
  start: number;
  nft_id: number;
};

export const listApi = createApi({
  reducerPath: 'listApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_DOMAIN,
    credentials: 'include',
  }),
  endpoints: (build) => ({
    addDelayedListing: build.mutation<PostRequest, PostRequest>({
      query: ({ price, coefficient, start, nft_id, currency }) => ({
        url: `${API_METHODS.POST_DELAYED_LISTING}`,
        method: 'POST',
        body: { price, coefficient, start, nft_id, currency },
      }),
    }),
  }),
});

export const { useAddDelayedListingMutation } = listApi;
