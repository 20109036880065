import React from 'react';

export const BurnIcon: React.FC<React.SVGAttributes<SVGElement>> = (props) => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      { ...props }
    >
      <path d="M0 11C0 12.4667 0.358333 13.804 1.075 15.012C1.79167 16.2207 2.73333 17.175 3.9 17.875C3.61667 17.475 3.396 17.0373 3.238 16.562C3.07933 16.0873 3 15.5833 3 15.05C3 14.3833 3.125 13.7583 3.375 13.175C3.625 12.5917 3.99167 12.0583 4.475 11.575L8 8.10001L11.55 11.575C12.0167 12.0417 12.375 12.571 12.625 13.163C12.875 13.7543 13 14.3833 13 15.05C13 15.5833 12.921 16.0873 12.763 16.562C12.6043 17.0373 12.3833 17.475 12.1 17.875C13.2667 17.175 14.2083 16.2207 14.925 15.012C15.6417 13.804 16 12.4667 16 11C16 9.68334 15.7 8.44167 15.1 7.27501C14.5 6.10834 13.675 5.15001 12.625 4.40001C12.4417 4.26667 12.246 4.22067 12.038 4.26201C11.8293 4.30401 11.6583 4.40834 11.525 4.57501C11.325 4.80834 11.096 4.98767 10.838 5.11301C10.5793 5.23767 10.3083 5.30001 10.025 5.30001C9.45833 5.30001 8.97933 5.10834 8.588 4.72501C8.196 4.34167 8 3.86667 8 3.30001V2.00001C8 1.55001 7.80433 1.21234 7.413 0.987006C7.021 0.76234 6.64167 0.775006 6.275 1.02501C4.325 2.32501 2.79167 3.84167 1.675 5.57501C0.558334 7.30834 0 9.11667 0 11ZM8 10.9L5.875 12.975C5.59167 13.2583 5.375 13.5793 5.225 13.938C5.075 14.296 5 14.6667 5 15.05C5 15.8667 5.29167 16.5623 5.875 17.137C6.45833 17.7123 7.16667 18 8 18C8.83333 18 9.54167 17.7123 10.125 17.137C10.7083 16.5623 11 15.8667 11 15.05C11 14.65 10.925 14.275 10.775 13.925C10.625 13.575 10.4083 13.2583 10.125 12.975L8 10.9Z" fill="black"/>
    </svg>
  );
};
