import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import {
  Loader,
  EtherscanLink,
  ProcessSteps,
  ProcessText,
  Button,
  ProcessProfile,
} from 'components/ui';
import { useAppSelector, useAppDispatch } from 'hooks';

import classes from './content.module.scss';
import { User } from 'components/icons/user';
import { unlist, setStep } from 'store/unlist';
import { INft } from 'store/nft/interface';
import { unlistingSteps } from 'config/unlisting';
import { ProfileIcon } from 'components/icons';

interface IProps {
  nft: any;
}

export const Content: React.FC<IProps> = ({ nft }) => {
  const dispatch = useAppDispatch();
  const { step, txHash } = useAppSelector((state) => state.unlist);
  const { address, userData } = useAppSelector((state) => state.profileStore);

  useEffect(() => {
    dispatch(setStep(1));
  }, []);

  if (!step) {
    return null;
  }

  const isLoaderVisible = step === 2 || step === 3;
  return (
    <div className={classes.container}>
      {step > 1 && <ProcessSteps steps={3} current={step - 1} />}
      <ProcessText current={step} steps={unlistingSteps} />

      {(step === 3 || step === 4) && <EtherscanLink txHash={txHash} />}
      {step === 1 && (
        <Button
          label='Continue'
          size='small'
          onClick={() => dispatch(unlist({ nftId: nft.nftId }))}
        />
      )}
      {step === 4 && (
        <Link to={`/profile/${address}`} className={classes.link}>
          <Button label='Go to my profile' icon={<ProfileIcon />} />
        </Link>
      )}
      {step === 2 && (
        <ProcessProfile
          image={userData && userData.image}
          username={userData && userData.username}
          address={address}
        />
      )}
      {isLoaderVisible && <Loader loading className={classes.loaderSize} />}
    </div>
  );
};
