import React from 'react';
import { Link } from 'react-router-dom';

import { NavTab } from 'components/ui';
import { LogoLightHorizontal } from 'components/icons';
import { BurgerMenu, DesktopMenu } from 'components/containers';
import { HEADER_URLS } from 'config';

import classes from './header.module.scss';

export const Header = () => {
  return (
    <>
      <header className={classes.header}>
        <div className={classes.header__content}>
          <Link to='/' className={classes.header__logo}>
            <LogoLightHorizontal />
          </Link>
          <div className={classes.header__tabs}>
            {HEADER_URLS.map(({ url, label }) => (
              <Link to={url} key={url} className={classes.header__link}>
                <NavTab active={false} label={label} />
              </Link>
            ))}
          </div>
          <div className={classes.header__control}>
            <BurgerMenu />
            <DesktopMenu />
          </div>
        </div>
      </header>
    </>
  );
};
