import React from 'react';

import { TIconWrapper } from './declarations';

export const WalletConnectIcon: React.FC<TIconWrapper> = ({ size = 32, className}) => {
  return(
    <svg
      width={ size }
      height={ size }
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={ className }
    >
      <path
        d='M6.54942 10.0178C11.7666 4.90975 20.2254 4.90975 25.4426 10.0178L26.0705 10.6326C26.3313 10.888 26.3313 11.3021 26.0705 11.5575L23.9226 13.6605C23.7921 13.7882 23.5807 13.7882 23.4502 13.6605L22.5862 12.8145C18.9465 9.25096 13.0455 9.25096 9.40582 12.8145L8.48048 13.7205C8.35005 13.8482 8.13859 13.8482 8.00816 13.7205L5.86024 11.6175C5.59938 11.3621 5.59938 10.948 5.86024 10.6926L6.54942 10.0178ZM29.8847 14.367L31.7963 16.2387C32.0572 16.4941 32.0572 16.9082 31.7963 17.1636L23.1766 25.6032C22.9157 25.8586 22.4928 25.8586 22.2319 25.6032C22.2319 25.6032 22.2319 25.6032 22.2319 25.6032L16.1141 19.6134C16.0489 19.5495 15.9432 19.5495 15.878 19.6134C15.878 19.6134 15.8779 19.6134 15.8779 19.6134L9.7603 25.6032C9.49944 25.8586 9.07651 25.8586 8.81564 25.6032C8.81564 25.6032 8.81564 25.6032 8.81564 25.6032L0.195645 17.1635C-0.065215 16.9081 -0.065215 16.494 0.195645 16.2386L2.1073 14.3669C2.36816 14.1115 2.79109 14.1115 3.05195 14.3669L9.16982 20.3568C9.23504 20.4207 9.34077 20.4207 9.40599 20.3568C9.40599 20.3568 9.40599 20.3568 9.40599 20.3568L15.5235 14.3669C15.7844 14.1115 16.2073 14.1115 16.4682 14.3669C16.4682 14.3669 16.4682 14.3669 16.4682 14.3669L22.5861 20.3568C22.6513 20.4207 22.757 20.4207 22.8222 20.3568L28.94 14.367C29.2009 14.1116 29.6238 14.1116 29.8847 14.367Z'
        fill='#3B99FC'/>
    </svg>
  );
}
