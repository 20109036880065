import React from 'react';

export const TwitchIcon = () => {
  return (
    <svg
      width='18'
      height='20'
      viewBox='0 0 18 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.63969 3.93H10.0697V8.21H8.63969M12.5697 3.93H13.9997V8.21H12.5697M3.99969 0L0.429688 3.57V16.43H4.70969V20L8.28969 16.43H11.1397L17.5697 10V0M16.1397 9.29L13.2897 12.14H10.4297L7.92969 14.64V12.14H4.70969V1.43H16.1397V9.29Z'
        fill='#F9F9F9'
      />
    </svg>
  );
};
