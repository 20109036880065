import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { CreateNFTProgressBar } from 'components/ui';
import {
  useAppSelector,
  useAppDispatch
} from 'hooks';
import {
  CREATE_NFT_STEPS,
  FILTER_PARAMS
} from 'config';
import {
  clearState,
  uploadImage
} from 'store/nft-manager';

export const LoadToIPFS: React.FC = () => {
  const dispatch = useAppDispatch();
  const [ searchParams, setSearchParams ] = useSearchParams();
  const { file, imageLoadingProgress } = useAppSelector(state => state.NFTManagerStore);

  useEffect(() => {
    if (!file) {
      searchParams.set(FILTER_PARAMS.STEP, `${ CREATE_NFT_STEPS.uploadImage.index }`);
      dispatch(clearState());

      return setSearchParams(searchParams);
    }

    dispatch(uploadImage());
  }, [  ]);

  return(
    <CreateNFTProgressBar progress={ imageLoadingProgress } />
  );
}
