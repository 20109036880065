import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Input, TextArea, DropDown } from 'components/ui';
import { useAppSelector, useAppDispatch } from 'hooks';
import { CREATE_NFT_STEPS, FILTER_PARAMS } from 'config';
import { setMeta, clearState } from 'store/nft-manager';
import { useGetTypesQuery } from 'store/genre';

import { setGenres } from './config';
import classes from './add-nft-meta-data.module.scss';

export const AddNFTMetaData = () => {
  const dispatch = useAppDispatch();
  const [openDropdown, setOpenDropdown] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: genres = [] } = useGetTypesQuery('');
  const { IPFSHash, name, genre, description } = useAppSelector(
    (state) => state.NFTManagerStore
  );

  useEffect(() => {
    if (!IPFSHash) {
      searchParams.set(
        FILTER_PARAMS.STEP,
        `${CREATE_NFT_STEPS.uploadImage.index}`
      );
      dispatch(clearState());

      setSearchParams(searchParams);
    }
  }, []);

  const onHandleChange = ({ target }: any) => {
    dispatch(
      setMeta({
        [target.name]: ![''].includes(target.value) ? target.value : null,
      })
    );
  };

  const onHandleSelect = ({ genre }: any) => {
    const find = genres.find(({ name }) => name === genre);

    if (find) {
      const { id, name } = find;

      dispatch(
        setMeta({
          genre: name,
          genreId: id,
        })
      );
    }
  };

  const handleOpenDropdown = (_: boolean): void => {
    setOpenDropdown(_);
  };

  return (
    <div className={classes.formWrapper}>
      <div className={classes.input_wrapper}>
        <Input
          name='name'
          label='Name'
          placeholder='Enter NFT name'
          onChange={onHandleChange}
          value={name || ''}
          className={{ inputStyles: classes.input }}
          // errorMessage={  }
        />
      </div>

      <div className={classes.selectWrap}>
        <DropDown
          open={openDropdown}
          setOpen={(_) => handleOpenDropdown(_)}
          label={genre || 'Select genres'}
          // errorMessage={touched && error ? error : undefined}
          options={setGenres(genres, onHandleSelect)}
          value={genre || ''}
          btnClasses={classes.select}
          classes={classes.dropdown}
          closeOnClick={true}
        />
      </div>

      <div className={classes.textAreaWrap}>
        <TextArea
          name='description'
          label='Description'
          placeholder='Enter NFT description'
          className={{ Textarea: classes.textarea }}
          onChange={onHandleChange}
          value={description || ''}
          maxLength={300}
          // errorMessage={  }
        />
      </div>

      <div className={classes.description}>
        Provide a detailed description of your artwork, including the title,
        year of creation, size, and any other relevant information.
      </div>
    </div>
  );
};
