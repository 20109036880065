import React from 'react';

export const ChevronRight: React.FC<React.SVGAttributes<SVGElement>> = (
  props
) => {
  return (
    <svg
      width='10'
      height='16'
      viewBox='0 0 10 16'
      xmlns='http://www.w3.org/2000/svg'
      fill='#A34F3D'
      {...props}
    >
      <path
        d='M0.467148 15.2C0.178259 14.9111 0.0338135 14.5556 0.0338135 14.1334C0.0338135 13.7111 0.178259 13.3556 0.467148 13.0667L5.53381 8.00003L0.467148 2.93337C0.178259 2.64448 0.0338135 2.28892 0.0338135 1.8667C0.0338135 1.44448 0.178259 1.08892 0.467148 0.800032C0.756037 0.511143 1.11159 0.366699 1.53381 0.366699C1.95604 0.366699 2.31159 0.511143 2.60048 0.800032L8.73381 6.93337C8.88937 7.08892 9.00048 7.25559 9.06715 7.43337C9.13381 7.61114 9.16715 7.80003 9.16715 8.00003C9.16715 8.20003 9.13381 8.38892 9.06715 8.5667C9.00048 8.74448 8.88937 8.91114 8.73381 9.0667L2.60048 15.2C2.31159 15.4889 1.95604 15.6334 1.53381 15.6334C1.11159 15.6334 0.756037 15.4889 0.467148 15.2Z'
        fill='inherit'
      />
    </svg>
  );
};
