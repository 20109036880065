import { NETWORK } from 'config';
import Web3 from 'web3';
import { ethers } from 'ethers';

import {
  // ISignProps,
  ISwitchProps,
  IError,
  // IState
} from './interface';

const _web3 = new Web3(Web3.givenProvider);
const _provider = _web3.givenProvider;
const _utils = _web3.utils;

// let _web3: any = null;
// let _provider: any = null;
// let _eth: any = null;
// let _personal: any = null;
// let _net: any = null;
// let _utils: any = null;
//
// let _account: string | null = null;
// let _network: string | null = null;
// let account: IState = {
//   address: null,
//   balance: null,
//   network: null
// }
//
// /*
//   get last connection from storage
//
//   create provider data
//
//  */
//
export const errorMessage = (method: string, error: IError): void => {
  console.error(`[ WEB3 ERROR ][ ${method} ][ ${error.message} ]`);
};

// export const switchNetworks = async ({
//   chainId,
// }: ISwitchProps): Promise<void> => {
//   if (!chainId) {
//     return;
//   }

//   const wb3 = new Web3(Web3.givenProvider);
//   const provider = wb3.givenProvider;

//   try {
//     await provider.sendAsync({
//       method: 'wallet_addEthereumChain',
//       params: paramChain,
//     });

//     await provider.sendAsync({
//       method: 'wallet_switchEthereumChain',
//       params: [
//         {
//           chainId: _utils.toHex(chainId),
//         },
//       ],
//     });
//   } catch (error) {
//     console.log('ERROR CONNECT', error);
//   }
// };
//
// export const initProvider = (): void => {
//   try {
//     _web3 = new Web3(Web3.givenProvider);
//     _provider = _web3.givenProvider;
//     _eth = _web3.eth;
//     _personal = _eth.personal;
//     _net = _eth.net;
//     _utils = _web3.utils;
//   } catch (error: any) {
//     errorMessage('initProvider', error );
//   }
// }
//
// export const useProvider = () => {
//   if (!_provider) {
//     return null;
//   }
//
//   return _web3.givenProvider;
// }
//
// export const library = () => {
//   if (!_provider) {
//     return null;
//   }
//
//   return _eth;
// }
//
// export const signer = () => {
//   if (!_provider) {
//     return null;
//   }
//
//   return _personal;
// }
//
// export const utils = () => {
//   if (!_provider) {
//     return null;
//   }
//
//   return _web3.utils;
// }
//
// export const getAddress = async (): Promise<string | null> => {
//   try {
//     const accounts = await _eth.getAccounts();
//
//     return accounts.length ? accounts[0] : null;
//   } catch (error: any) {
//     errorMessage('getAddress', error);
//
//     return null;
//   }
// }
//
// export const sign = async ({ message, address }: ISignProps): Promise<string | null> => {
//   try {
//     return await _personal.sign(message, address, '');
//   } catch (error: any) {
//     errorMessage('sign', error);
//
//     return null;
//   }
// }

export const toHex = async ({
  chainId,
}: ISwitchProps): Promise<string | null> => {
  if (!chainId) {
    return null;
  }

  return _utils.toHex(chainId);
};

// export const switchNetworks = async ({ chainId }: ISwitchProps): Promise<void> => {
//   try {
//     _provider.sendAsync({
//       method: 'wallet_switchEthereumChain',
//       params: [
//         {
//           chainId: _utils.toHex(chainId)
//         }
//       ]
//     });
//   } catch (error: any) {
//     errorMessage('switchNetworks', error);
//   }
// }

export const hexToNumber = async (hex: string) => {
  try {
    const num = parseFloat(_utils.hexToNumberString(hex));

    return num ? num : null;
  } catch (error: any) {
    errorMessage('hexToNumber', error);
    return null;
  }
};

export async function addNFTToMetaMask(tokenId: string) {
  const contractAddress = NETWORK.nftAddress!;

  try {
    // Добавление токена в MetaMask
    await window?.ethereum?.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC721',
        options: {
          address: contractAddress,
          symbol: 'NFT',
          decimals: 18,
          tokenId,
        },
      },
    });

    console.log('NFT added to MetaMask');
  } catch (error) {
    console.error('Error adding NFT to MetaMask:', error);
  }
}
