import { NETWORK } from 'config';

export enum Chain {
  BNB,
  BNB_TESTNET,
}

const currentChain = NETWORK.chainId === 56 ? Chain.BNB : Chain.BNB_TESTNET;
export const CurrentConfig = {
  chain: currentChain,
  rpc: {
    bnb: 'https://bsc.publicnode.com',
    bnb_testnet: 'https://bsc-testnet.publicnode.com',
  },
};

// Chains
const BNB_CHAIN_ID = 56;
const BNB_TESTNET_CHAIN_ID = 97;

export const INPUT_CHAIN_ID =
  CurrentConfig.chain === Chain.BNB ? BNB_CHAIN_ID : BNB_TESTNET_CHAIN_ID;
export const INPUT_CHAIN_URL =
  CurrentConfig.chain === Chain.BNB
    ? CurrentConfig.rpc.bnb
    : CurrentConfig.rpc.bnb_testnet;

export const CHAIN_TO_URL_MAP = {
  [BNB_CHAIN_ID]: CurrentConfig.rpc.bnb,
  [BNB_TESTNET_CHAIN_ID]: CurrentConfig.rpc.bnb_testnet,
};

type ChainInfo = {
  explorer: string;
  label: string;
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: 18;
  };
  rpcUrl: string;
};

export const CHAIN_INFO: { [key: string]: ChainInfo } = {
  [BNB_CHAIN_ID]: {
    explorer: 'https://bscscan.com',
    label: 'BNB Smart Chain Mainnet',
    nativeCurrency: { name: 'Binance', symbol: 'BNB', decimals: 18 },
    rpcUrl: CurrentConfig.rpc.bnb,
  },
  [BNB_TESTNET_CHAIN_ID]: {
    explorer: 'https://testnet.bscscan.com',
    label: 'BNB Smart Chain - Testnet',
    nativeCurrency: { name: 'TBinance', symbol: 'tBNB', decimals: 18 },
    rpcUrl: CurrentConfig.rpc.bnb_testnet,
  },
};

// URLs
export const METAMASK_URL = 'https://metamask.io/';
