import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';

import { Button, DropDown } from 'components/ui';
import { ConnectButton, ReportButton } from 'components/containers';
import {
  URLS,
  INDEX_URLS,
  GET_TARGET_PAGE,
  CACHED_WALLET_KEY,
  LAST_CONNECT_KEY,
} from 'config';
import {
  getConnection,
  getFromLocalStorage,
  removeFromLocalStorage,
  tryDeactivateConnector,
  utils,
} from 'libs';
import { useAppSelector, useAppDispatch } from 'hooks';
import { clearProfile } from 'store/profile';

import classes from './desktop-menu.module.scss';
import { getImage } from 'libs/utils/utils';

export const DesktopMenu: React.FC = () => {
  const navigation = useNavigate();
  const dispatch = useAppDispatch();
  const [openDropdown, setOpenDropdown] = useState(false);
  const { address, userData } = useAppSelector((state) => state.profileStore);

  const handleOpenDropdown = (_: boolean): void => {
    setOpenDropdown(_);
  };

  const createOptions = () => {
    const options = [
      {
        label: 'View your profile',
        onClick: () => {
          if (address) {
            navigation(GET_TARGET_PAGE(INDEX_URLS.PROFILE, address));
          }
        },
      },
    ];

    return [
      ...options,
      {
        label: 'Disconnect wallet',
        onClick: async () => {
          const deactivation = await tryDeactivateConnector(
            getConnection(getFromLocalStorage(LAST_CONNECT_KEY).connector)
              .connector
          );
          // undefined means the deactivation failed
          if (deactivation === undefined) {
            return;
          }
          removeFromLocalStorage(LAST_CONNECT_KEY);
          dispatch(clearProfile());
        },
      },
    ];
  };

  return (
    <div className={classes.header__button}>
      <div className={classes.header__actions}>
        <Button
          label='Get BABYBNBTIGER'
          size='small'
          variant='outlined'
          onClick={() => {
            window.open(
              'https://pancakeswap.finance/swap?outputCurrency=BNB&inputCurrency=0x5a04565ee1c90c84061aD357AE9E2f1c32D57dc6',
              '_blank',
              'noopener,noreferrer'
            );
          }}
        />
        {address ? (
          <DropDown
            open={openDropdown}
            setOpen={(_) => handleOpenDropdown(_)}
            label={utils.shorter(address)}
            image={getImage(userData?.image)}
            options={createOptions()}
            closeOnClick={true}
          />
        ) : (
          <ConnectButton />
        )}
      </div>
    </div>
  );
};
