import * as Yup from 'yup';

export default (): Yup.ObjectSchema<any> => {
  const validationShape: any = {
    email: Yup.string()
      .matches(/^[-\w.]+@([A-z0-9][-A-z0-9]+\.)+[A-z]{2,7}$/, 'Wrong email')
      .required('Required'),
  };

  return Yup.object().shape(validationShape);
};
