import React from 'react';

export const Burger: React.FC<React.SVGAttributes<SVGElement>> = (props) => {
  return (
    <svg
      width='24'
      height='16'
      viewBox='0 0 24 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M1.33333 16C0.955556 16 0.639111 15.872 0.384 15.616C0.128 15.3609 0 15.0444 0 14.6667C0 14.2889 0.128 13.9724 0.384 13.7173C0.639111 13.4613 0.955556 13.3333 1.33333 13.3333H22.6667C23.0444 13.3333 23.3609 13.4613 23.616 13.7173C23.872 13.9724 24 14.2889 24 14.6667C24 15.0444 23.872 15.3609 23.616 15.616C23.3609 15.872 23.0444 16 22.6667 16H1.33333ZM1.33333 9.33333C0.955556 9.33333 0.639111 9.20533 0.384 8.94933C0.128 8.69422 0 8.37778 0 8C0 7.62222 0.128 7.30533 0.384 7.04933C0.639111 6.79422 0.955556 6.66667 1.33333 6.66667H22.6667C23.0444 6.66667 23.3609 6.79422 23.616 7.04933C23.872 7.30533 24 7.62222 24 8C24 8.37778 23.872 8.69422 23.616 8.94933C23.3609 9.20533 23.0444 9.33333 22.6667 9.33333H1.33333ZM1.33333 2.66667C0.955556 2.66667 0.639111 2.53911 0.384 2.284C0.128 2.028 0 1.71111 0 1.33333C0 0.955556 0.128 0.638667 0.384 0.382667C0.639111 0.127556 0.955556 0 1.33333 0H22.6667C23.0444 0 23.3609 0.127556 23.616 0.382667C23.872 0.638667 24 0.955556 24 1.33333C24 1.71111 23.872 2.028 23.616 2.284C23.3609 2.53911 23.0444 2.66667 22.6667 2.66667H1.33333Z'
        fill='white'
      />
    </svg>
  );
};
