import { WEI_MULTIPLIER } from 'constants/constants';
import { getContract } from 'hooks';
import { Currency, TCurrency } from 'store/currency/types';

//
export const formatAmount = (num: number, currency?: TCurrency) => {
  if (currency === 'BNB') {
    return num;
  }
  const billion = 1000000000;
  const million = 1000000;
  const thousand = 1000;
  if (num >= billion) {
    const billions = Math.floor(num / billion);
    const remainder = num % billion;
    const millions = Math.floor(remainder / million);
    const thousands = Math.floor((remainder % million) / thousand);
    if (billions > 0) {
      return billions + ' b. ' + millions + ' m. ' + thousands + ' k.';
    } else if (millions > 0) {
      return millions + ' m. ' + thousands + ' k.';
    } else {
      return thousands + ' k.';
    }
  } else if (num >= million) {
    const millions = Math.floor(num / million);
    const remainder = num % million;
    const thousands = Math.floor(remainder / thousand);
    if (millions > 0) {
      return millions + ' m. ' + thousands + ' k.';
    } else {
      return thousands + ' k.';
    }
  } else if (num >= thousand) {
    return (num / thousand).toFixed(1) + ' k.';
  } else {
    return num.toString();
  }
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatAmountTo = (
  amount: number | string,
  currency: TCurrency
) => {
  if (currency === Currency.BNB) {
    return (+amount * WEI_MULTIPLIER).toString();
  }
  const { units } = getContract();
  return units.parseUnits(amount.toString(), 9).toString();
};
