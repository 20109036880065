import React, {useEffect, useState} from 'react';
import { Link, useParams } from 'react-router-dom';

import classes from './delete-draft.module.scss';
import { useAppDispatch, useAppSelector } from 'hooks';
import { clearState, deleteNFTDraft, getNFTDraft, setHashLoaded } from 'store/nft-manager';
import { Button, ProductCard } from 'components/ui';
import { User } from 'components/icons';

export const DeleteDRAFT = () => {
	const {id} = useParams();
	const dispatch = useAppDispatch();
  const {fullPath, name} = useAppSelector(state => state.NFTManagerStore);
  const {userData, address} = useAppSelector(state => state.profileStore);
  const [isDeleted, setIsDeleted] = useState(false);

	useEffect(() => {
		dispatch(getNFTDraft(id));
	}, [id]);

  const handlerDeleteDraft = () => {
    dispatch(deleteNFTDraft(id));
    dispatch(clearState());

    setIsDeleted(true);
  };

  const {
    image: image,
    username: username,
  } = (userData || {}) as any;

	return (
		<div className={classes.container}>
      {!isDeleted &&
        <div className={classes.left}>
          <ProductCard
            author={{
              image: image,
              username: username,
            }}
            image={fullPath}
            name={name ? name : undefined}
            setImageLoaded={() => dispatch(setHashLoaded())}
            className={classes.productCard}
            isOwner={false}
            withActions={false}
          />
        </div>
      }
      <div className={classes.right}>
				<div className={classes.right__content}>
          <div className={classes.title}>
            {!isDeleted ? 
              "Delete this Draft" 
              : 
              <div style={{textAlign: 'center'}}>The draft was successfully deleted.</div>
            }
          </div>

          {!isDeleted &&
            <div className={classes.description}>
              Deleting a draft destroys it - there's no way to get it back. Are you sure you want to continue?"           
            </div>
          }

          {!isDeleted ?
            <Button
              label='Continue'
              size='small'
              onClick={handlerDeleteDraft}
            />
            :
            <Link to={`/profile/${address}`} className={classes.link}>
              <Button
                label='Go to my profile'
                size='small'
                icon={<User />}
                type='secondary'
              />
            </Link>
          }
        </div>
      </div>{' '}
    </div>
	);
};