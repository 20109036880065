import React, { useState } from 'react';

import { Share, Ellipsis } from 'components/ui';
import { ReportModal } from 'components/containers';
import { useMedia } from 'hooks';
import { IAuthor } from 'store/auction';

import classes from './nft-info.module.scss';
import { Link } from 'react-router-dom';
import { GET_TARGET_PAGE, INDEX_URLS } from 'config';
import cn from 'classnames';
import { NftLabel } from '../nft-label/nft-label';
import { INft } from 'store/nft-list/interface';
import { utils } from 'libs';

export const NFTInfo = (details: INft) => {
  const { isDesktop } = useMedia();
  const [openReport, setOpenReport] = useState(false);
  const [isReport, setIsReport] = useState(false);
  const [isShare, setIsShare] = useState(false);
  const metadata = details?.metadata?.[0]?.fields;

  return (
    <div className={classes.nft__info_wrap}>
      <div className={classes.nft__section}>
        <div className={classes.nft__section_block}>
          <div className={classes.nft__name}>{details.name}</div>
        </div>
        <div className={classes.nft__section_block_row}>
          <Ellipsis
            openReport={() => setOpenReport(true)}
            setOpen={() => {
              setIsReport(!isReport);
              setIsShare(false);
            }}
            open={isReport}
          />
          <Share
            setOpen={() => {
              setIsShare(!isShare);
              setIsReport(false);
            }}
            open={isShare}
            text={isDesktop ? 'Share' : undefined}
          />
        </div>
      </div>

      <div className={classes.nft__aboutWrapper}>
        {details.author ? (
          <div className={classes.nft__about}>
            <span className={classes.nft__aboutTitle}>author:</span>{' '}
            <span className={classes.nft__aboutText}>
              {details.author.username}
            </span>
          </div>
        ) : null}
        {details.owner ? (
          <div className={cn(classes.nft__about, classes.nft__aboutBigMargin)}>
            <span className={classes.nft__aboutTitle}>owner:</span>{' '}
            <span className={classes.nft__aboutText}>
              {utils.shorter(details.owner.wallet) || '-'}
            </span>
          </div>
        ) : null}

        {/* TODO PASS data genre */}
        {metadata && metadata.name && (
          <div className={cn(classes.nft__about, classes.nft__aboutFlexRow)}>
            <div className={classes.nft__aboutTitle}>Type</div>
            <NftLabel type={metadata?.name.toLowerCase()} />
          </div>
        )}
      </div>
      {details && details.description ? (
        <div className={classes.description}>
          <div className={classes.descriptionTitle}>description</div>
          <div className={classes.descriptionText}>{details?.description}</div>
        </div>
      ) : null}

      <ReportModal
        setOpen={() => setOpenReport(!openReport)}
        open={openReport}
      />
    </div>
  );
};
