import { ethers } from 'ethers';
import * as units from '@ethersproject/units';
import * as ethersBigNumber from '@ethersproject/bignumber';

import NFTAbi from 'contracts/bnbbabytiger-nft-abi.json';
import AuctionAbi from 'contracts/bnbbabytiger-auction-abi.json';
import CoinAbi from 'contracts/bnbbabytiger-coin-abi.json';
import { NETWORK } from 'config';

interface IUseContract {
  nftContract: any;
  auctionContract: any;
  utils: any;
  units: any;
  ethersBigNumber: any;
  setContracts: (signer: any) => void;
  clearContracts: () => void;
}

let nftContract: any = null;
let auctionContract: any = null;
let coinContract: any = null;

let utils: any = ethers.utils;

export const useContract = (): IUseContract => {
  const setContracts = (signer: any) => {
    if ([undefined, null].includes(signer)) {
      return clearContracts();
    }

    if (
      !NETWORK.nftAddress &&
      !NETWORK.auctionAddress &&
      !NETWORK.coinAddress
    ) {
      return clearContracts();
    }

    nftContract = new ethers.Contract(NETWORK.nftAddress!, NFTAbi, signer);
    auctionContract = new ethers.Contract(
      NETWORK.auctionAddress!,
      AuctionAbi,
      signer
    );
    coinContract = new ethers.Contract(NETWORK.coinAddress!, CoinAbi, signer);
  };

  const clearContracts = () => {
    nftContract = null;
    auctionContract = null;
    coinContract = null;
  };

  return {
    nftContract,
    auctionContract,
    setContracts,
    clearContracts,
    utils,
    units,
    ethersBigNumber,
  };
};

export const getContract = () => ({
  nftContract,
  auctionContract,
  coinContract,
  utils,
  units,
  ethersBigNumber,
});
