import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  getFollowing,
  getFollowers
} from './actions';
import {
  IInitialState,
} from './types';

const initialState: IInitialState = {
  isOpen: false,
	isFollowersLoading: false,
  isFollowingLoading: false,
  following: [],
  followers: [],
};

export const follows = createSlice({
  name: 'followsState',
  initialState,
  reducers: {
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    } 
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFollowing.pending, state => {
        state.isFollowingLoading = true;
      })
      .addCase(getFollowing.fulfilled, (state, action: PayloadAction<any>) => {
        state.following = action.payload;
        state.isFollowingLoading = false;
      })
      .addCase(getFollowing.rejected, state => {
        state.isFollowingLoading = false;
      })
      .addCase(getFollowers.pending, state => {
        state.isFollowersLoading = true;
      })
      .addCase(getFollowers.fulfilled, (state, action: PayloadAction<any>) => {
        state.followers = action.payload;
        state.isFollowersLoading = false;
      })
      .addCase(getFollowers.rejected, state => {
        state.isFollowersLoading = false;
      })
  },
});

export const {
  setOpen
} = follows.actions;
