import React, { useState } from 'react';

import {
  // VolumeIcon,
  FullScreenIcon,
} from 'components/icons';

import { NFTModal } from 'components/ui';

import classes from './nft-preview.module.scss';

type Props = {
  src: string;
};

export const NFTPreview = ({ src }: Props) => {
  const [openNFT, setOpenNFT] = useState(false);

  return (
    <div className={classes.nft__img_wrap}>
      {/* <div className={classes.nft__img_content}> */}
      <img
        className={classes.nft__img}
        src={src}
        alt=''
        onClick={() => setOpenNFT(true)}
      />
      {/*<div className={classes.nft__img_icon} style={{ right: 72 }}>*/}
      {/*  <VolumeIcon />*/}
      {/*</div>*/}

      {/*<div className={classes.nft__img_icon} onClick={() => setOpenNFT(true)}>*/}
      {/*  <FullScreenIcon />*/}
      {/*</div>*/}
      {/* </div> */}
      <NFTModal setOpen={() => setOpenNFT(!openNFT)} open={openNFT} src={src} />
    </div>
  );
};
