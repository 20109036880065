import { axios } from 'libs';
import { API_METHODS } from 'config';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ValidationErrors } from 'store/profile';
import { setHistory } from 'store/nft-history/reducer';

const _axios = axios.getAxios();

export const getNftHistory = createAsyncThunk(
  'nftHistory/getNftHistory',
  async ({ id }: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await _axios.get(
        `${API_METHODS.GET_NFT_HISTORY}/?nft_id=${id}`
      );
      dispatch(setHistory(response.data));

      return null;
    } catch (err: any) {
      const error: AxiosError<ValidationErrors> = err;

      if (!error.response) {
        throw err;
      }

      return rejectWithValue(error.response.data);
    }
  }
);
