export const filter = [
  {
    label: 'All',
    value: 'All',
  },
  {
    label: 'On sale',
    value: 'sale',
  },

  {
    label: 'Not on sale',
    value: 'ready',
  },
];

export const getStatusFilter = (
  onClick: ({ label, value }: { label: string; value: string }) => void
) => {
  return filter.map(({ label, value }) => ({
    label,
    value,
    onClick: () => onClick({ value, label }),
  }));
};
