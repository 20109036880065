import React from 'react';

import { OpenIn } from 'components/icons';

import classes from './create-nft-progress-bar.module.scss';

interface IProps {
  progress: number;
}

export const CreateNFTProgressBar = ({ progress }: IProps) => {
  return(
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <h4 className={ classes.title }>
        {
          progress === 100 ? 'Upload complete' : 'Upload in progress'
        }
      </h4>
      <div className={ classes.progressBar }>
        <div className={ classes.progress }>
          <div className={ classes.bar } style={{ width: `${ progress }%`}} />
        </div>
      </div>
      <span className={ classes.description } style={{ width: '560px', textAlign: 'center' }}>
        Your artwork will be uploaded to the InterPlanetary File System (IPFS), a peer-to-peer network for sharing data in a distributed file system.
      </span>
      <div className={ classes.link }>
        <a href='https://www.pinata.cloud/' className={ classes.wrap } target='_blank'>
          <div className={ classes.icon }>
            <OpenIn />
          </div>
          <span>Learn more about IPFS here</span>
        </a>
      </div>
    </div>
  );
}
