import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { utils } from 'libs';
import { NftStatuses } from 'config';

import { INftListState } from './interface';
import { getProfileNft, getFeeds, getNftList, getNftDetails } from './actions';

const initialState: INftListState = {
  isLoading: false,
  profileNftList: [],
  feeds: [],
  list: [],
  count: 0,
  details: null,
};

const { prepareNftItems, getImage } = utils;

export const nftList = createSlice({
  name: 'nftList',
  initialState,
  reducers: {
    clearDetails: (state: INftListState) => {
      state.details = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfileNft.pending, (state: INftListState) => {
        state.isLoading = true;
        state.profileNftList = [];
      })
      .addCase(
        getProfileNft.fulfilled,
        (state: INftListState, action: PayloadAction<any>) => {
          state.profileNftList = prepareNftItems(action.payload);
          state.isLoading = false;
        }
      )
      .addCase(
        getProfileNft.rejected,
        (state: INftListState, action: PayloadAction<any>) => {
          console.log('error: ', action.payload);

          state.isLoading = false;
        }
      )
      .addCase(getFeeds.pending, (state: INftListState) => {
        state.isLoading = true;
      })
      .addCase(
        getFeeds.fulfilled,
        (state: INftListState, action: PayloadAction<any>) => {
          state.feeds = prepareNftItems(action.payload);
          state.isLoading = false;
        }
      )
      .addCase(
        getFeeds.rejected,
        (state: INftListState, action: PayloadAction<any>) => {
          console.log('error: ', action.payload);

          state.isLoading = false;
        }
      )
      .addCase(getNftList.pending, (state: INftListState) => {
        state.isLoading = true;
      })
      .addCase(
        getNftList.fulfilled,
        (state: INftListState, action: PayloadAction<any>) => {
          state.list = prepareNftItems(
            action.payload.results || action.payload
          );
          state.count = action.payload.count;
          state.isLoading = false;
        }
      )
      .addCase(
        getNftList.rejected,
        (state: INftListState, action: PayloadAction<any>) => {
          console.log('error: ', action.payload);

          state.isLoading = false;
        }
      )
      .addCase(getNftDetails.pending, (state: INftListState) => {
        state.isLoading = true;
      })
      .addCase(
        getNftDetails.fulfilled,
        (state: INftListState, action: PayloadAction<any>) => {
          const {
            name,
            image,
            description,
            genre,
            nft_id,
            price,
            status,
            author,
            owner,
            auction,
            can_list_or_open,
            is_open_box,
          } = action.payload;
          const result: any = {
            name,
            image: image ? image.compressed : null,
            metadata: image ? image.metadata : null,
            description,
            genre,
            nftId: nft_id,
            status,
            price,
            canListOrOpen: can_list_or_open,
            isOpenBox: is_open_box,
            author: {
              image: getImage(author.image),
              username: author.username,
              wallet: author.wallet,
            },
            owner: {
              image: getImage(owner.image),
              username: owner.username,
              wallet: owner.wallet,
            },
            auction: null,
          };

          if (auction) {
            const {
              max_bid,
              my_bid,
              time_end,
              is_active,
              auction_owner,
              currency,
            } = auction;

            result.auction = {
              auctionOwner: auction_owner,
              status: is_active,
              isMyLastBet:
                max_bid && my_bid
                  ? max_bid.bidder.wallet === my_bid.bidder.wallet
                  : false,
              endTimestamp: time_end,
              myLastBet: my_bid ? my_bid.amount : null,
              lastBet: max_bid ? max_bid.amount : null,
              currency: currency || null,
            };
          }

          state.details = result;
          state.isLoading = false;
        }
      )
      .addCase(
        getNftDetails.rejected,
        (state: INftListState, action: PayloadAction<any>) => {
          console.log('error: ', action.payload);

          state.isLoading = false;
        }
      );
  },
});

export const { clearDetails } = nftList.actions;
