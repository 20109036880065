import { createAsyncThunk } from '@reduxjs/toolkit';

import { getContract } from 'hooks';
import { logger } from 'ethers';

import { setStep, setTxHash } from './reducer';

export const unlist = createAsyncThunk(
  'unlistStore/unlist',
  async ({ nftId }: { nftId: string | number }, { dispatch }) => {
    const { auctionContract } = getContract();
    dispatch(setStep(2));

    try {
      const unlist = await auctionContract.cancelAuction(nftId);

      dispatch(setStep(3));
      dispatch(setTxHash(unlist.hash));

      await unlist.wait();

      logger.info(`[ NFT IS UNLISTED ]`);

      return {};
    } catch (error: any) {
      console.log({ error });

      return {
        error: error.reason,
      };
    }
  }
);
