import React from 'react';
import { InfoIcon, EllipsisIcon } from 'components/icons';

import classes from './ellipsis.module.scss';

type Props = {
  open: boolean;
  setOpen: () => void;
  openReport: () => void;
};

export const Ellipsis = ({ open, setOpen, openReport }: Props) => {
  return (
    <div className={classes.more_wrap}>
      <button onClick={setOpen} className={classes.more__button}>
        <EllipsisIcon />
      </button>
      {open && (
        <div className={classes.more__dropdown}>
          <div className={classes.more__dropdown_item} onClick={openReport}>
            <InfoIcon />
            Report
          </div>
        </div>
      )}
    </div>
  );
};
