import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { DropDown } from 'components/ui';
import { useGetTypesQuery } from 'store/genre';
import { FILTER_PARAMS } from 'config';

import { setGenres } from './config';
import classes from './nft-filter-genres.module.scss';
import cn from 'classnames';
import { filter } from '../nft-filter-status/config';

export const NFTFilterGenres: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: genres = [], isLoading } = useGetTypesQuery(''); // TODO add loading effect
  const [open, setOpen] = useState(false);
  const [filterName, setFilterName] = useState('All');

  const onHandleSet = (value: { name: string }) => {
    setFilterName(value.name);

    if (value.name === 'All') {
      searchParams.delete(FILTER_PARAMS.NFT_TYPE);
    } else {
      searchParams.set(FILTER_PARAMS.NFT_TYPE, value.name);
    }

    setSearchParams(searchParams);
  };
  const filterIsActive = () => {
    return searchParams.get(FILTER_PARAMS.NFT_TYPE);
  };

  return (
    <div
      className={cn(classes.dropdown, {
        [classes.is_active]: filterIsActive(),
      })}
    >
      <DropDown
        open={open}
        setOpen={setOpen}
        label={filterName}
        options={setGenres(
          genres,
          onHandleSet,
          searchParams.get(FILTER_PARAMS.NFT_TYPE)
        )}
        value={searchParams.get(FILTER_PARAMS.NFT_TYPE) || 'All'}
        closeOnClick={true}
        classes={classes.dropdownOptions}
        type='secondary'
      />
    </div>
  );
};

// <DropDown
//      open={open}
//      setOpen={setOpen}
//      label={filterName}
//      options={getStatusFilter(onHandleClick)}
//      classes={classes.dropdownOptions}
//      value={searchParams.get(FILTER_PARAMS.STATUS) || filter[0].label}
//      closeOnClick={true}
//      type='secondary'
//    />
