import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IState } from './interface';
import { placeBid } from './actions';
import { bidErrors, DEFAULT_TX_ERROR_TEXT, transactionErrors } from 'config';

const initialState: IState = {
  amount: null,
  bidStep: null,
  bidError: null,
  txHash: null,
  isLoading: false,
  agreement: false,
  isBid: false,
};

export const bid = createSlice({
  name: 'bidStore',
  initialState,
  reducers: {
    clearState: () => initialState,
    setStep: (state, action: PayloadAction<number>) => {
      state.bidStep = action.payload;
    },
    setAmount: (state, action: PayloadAction<number | string>) => {
      state.amount = action.payload;
    },
    setAgreement: (state, action: PayloadAction<boolean>) => {
      state.agreement = action.payload;
    },
    setTxHash: (state, action: PayloadAction<string>) => {
      state.txHash = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(placeBid.pending, (state) => {
        state.bidStep = 1;
      })
      .addCase(placeBid.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload?.error) {
          state.isBid = false;
          state.bidStep = 3;

          const mergedErrors = {
            ...bidErrors,
            ...transactionErrors,
          };

          Object.keys(mergedErrors).forEach((i) => {
            if (!action.payload?.error.includes(i)) {
              return (state.bidError = DEFAULT_TX_ERROR_TEXT);
            } else {
              return (state.bidError =
                mergedErrors[i as keyof typeof mergedErrors]);
            }
          });
        } else {
          state.isBid = true;
          state.bidStep = 3;
        }
      });
  },
});

export const { clearState, setAmount, setAgreement, setStep, setTxHash } =
  bid.actions;
