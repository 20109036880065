import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IState } from './interface';
import { openBox } from './actions';
import { DEFAULT_TX_ERROR_TEXT, transactionErrors } from 'config';

const initialState: IState = {
  openBoxStep: null,
  openBoxError: null,
  txHash: null,
  isLoading: false,
  isOpen: false,
};

export const openBoxStore = createSlice({
  name: 'openBoxStore',
  initialState,
  reducers: {
    clearState: () => initialState,
    setStep: (state, action: PayloadAction<number>) => {
      state.openBoxStep = action.payload;
    },
    setTxHash: (state, action: PayloadAction<string>) => {
      state.txHash = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(openBox.pending, (state) => {
        state.openBoxStep = 1;
      })
      .addCase(openBox.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.error) {
          state.isOpen = false;
          state.openBoxStep = 3;
          Object.keys(transactionErrors).forEach((i) => {
            if (!action.payload.error.includes(i)) {
              return (state.openBoxError = DEFAULT_TX_ERROR_TEXT);
            } else {
              return (state.openBoxError =
                transactionErrors[i as keyof typeof transactionErrors]);
            }
          });
        } else {
          state.isOpen = true;
          state.openBoxStep = 3;
        }
      });
  },
});

export const { clearState, setStep, setTxHash } = openBoxStore.actions;
