import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { getContract, useAppDispatch, useAppSelector } from 'hooks';
import { useGetNftQuery } from 'store/nft';
import { listingSteps, GET_TARGET_PAGE, INDEX_URLS, URLS } from 'config';
import {
  ProductCard,
  InfoText,
  PriceInput,
  LinkToFaq,
  Button,
  Agreement,
  ProcessSteps,
  ProcessText,
  ProcessProfile,
  Loader,
  EtherscanLink,
  Paper,
  StepLabel,
  FooterWithButtons,
  DateTimeInput,
  DropDown,
  Message,
  MessageType,
} from 'components/ui';
import {
  setAmount,
  setCoefficient,
  clearState,
  list,
  setStep,
  setComplete,
} from 'store/list';

import classes from './list.module.scss';
import { INCREASE_COEFFICIENT_MULTIPLIER } from 'constants/constants';
import { TigerUsd } from 'components/ui/tiger-usd/tiger-usd';
import { getPrices } from 'store/currency';
import { useAddDelayedListingMutation } from 'store/list/api';
import { Tooltip } from 'react-tooltip';
import cn from 'classnames';
import { Currency, TCurrency } from 'store/currency/types';
import { formatAmountTo } from 'helpers';

export const List = () => {
  const { units } = getContract();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [startTimestamp, setStartTimestamp] = useState(0);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [shouldShowCoefficientError, setShouldShowCoefficientError] =
    useState(false);
  const [currency, setCurrency] = useState<TCurrency>(Currency.BabyBNBTiger);
  const {
    step: transactionStep,
    txHash,
    amount,
    coefficient,
    isCompleted,
    agreement,
    error,
  } = useAppSelector((state) => state.listStore);

  const { userData, address } = useAppSelector((state) => state.profileStore);
  const { prices, isLoading: isPriceLoading } = useAppSelector(
    (state) => state.priceStore
  );

  const isCreator = userData?.is_creator;

  const { data: nft } = useGetNftQuery(id!, {
    skip: !id,
  });

  const [addDelayedListing, result] = useAddDelayedListingMutation();

  useEffect(() => {
    dispatch(getPrices());

    if (!nft) {
      return;
    }

    if (nft.is_owner === false) {
      navigate(URLS.HOME_PAGE);

      return () => {
        dispatch(clearState());
      };
    }
  }, [nft]);
  const onHandleList = async () => {
    if (!nft || !nft.nftId) {
      return;
    }

    if (Number(coefficient || 0) <= 1) {
      setShouldShowCoefficientError(true);
      return;
    }

    // // Получаем текущую дату и время
    // const currentTime = new Date();
    // currentTime.setMinutes(currentTime.getMinutes() + 1);
    // const timestamp = Math.floor(currentTime.getTime() / 1000);

    if (isCreator && amount && coefficient) {
      await addDelayedListing({
        price: formatAmountTo(amount, currency),
        coefficient: +coefficient * INCREASE_COEFFICIENT_MULTIPLIER,
        start: startTimestamp,
        currency: currency,
        nft_id: nft.nftId,
      });

      dispatch(setStep(3));
      dispatch(setComplete(true));
    } else {
      dispatch(
        list({
          nftId: nft.nftId,
          currency,
        })
      );
    }
  };

  const onHandleAmount = (value: string) => {
    dispatch(setAmount(value));
  };

  const onHandleCoefficient = (value: string) => {
    setShouldShowCoefficientError(false);
    dispatch(setCoefficient(value));
  };

  const onHandleNavigate = () => {
    // if (!id) {
    // return;
    // }

    // navigate(GET_TARGET_PAGE(INDEX_URLS.NFT, id));
    navigate('/');
  };

  if (!nft) {
    return null;
  }

  const isLoaderVisible = transactionStep && transactionStep !== 3;

  //начались транзакции это 2 шаг, заполнение форм - 1
  const step = !transactionStep ? 1 : 2;
  const titleStep = !transactionStep ? 'Set a reserve price' : 'List NFT';
  return (
    <>
      <Paper className={classes.container}>
        <div className={classes.header}>
          <div className={classes.title}>{titleStep}</div>
          <StepLabel current={step} max={2} />
        </div>
        <div className={classes.grid}>
          <div className={classes.left}>
            <ProductCard
              author={{
                image: nft.author.image,
                username: nft.author.username,
              }}
              image={nft.image}
              name={nft.name}
              className={classes.cardWidth}
              isOwner={false}
              withActions={false}
              auction={nft?.auction}
              price={nft?.price}
            />
          </div>
          <div className={classes.right}>
            {!transactionStep && (
              <>
                <div>
                  <DropDown
                    open={openDropdown}
                    setOpen={setOpenDropdown}
                    label={currency}
                    options={[
                      {
                        label: Currency.BNB,
                        onClick: () => {
                          setCurrency(Currency.BNB);
                          setOpenDropdown(false);
                        },
                      },
                      {
                        label: Currency.BabyBNBTiger,
                        onClick: () => {
                          setCurrency(Currency.BabyBNBTiger);
                          setOpenDropdown(false);
                        },
                      },
                    ]}
                  />
                  <PriceInput
                    value={amount ? `${amount}` : ''}
                    placeholder=''
                    label={`Reserve price in ${currency}`}
                    onChange={onHandleAmount}
                  />
                  <div className={classes.usd}>
                    <TigerUsd
                      isPriceLoading={isPriceLoading}
                      tigerPrice={+prices[currency]}
                      value={Number(amount)}
                    />
                  </div>
                </div>
                <div>
                  <PriceInput
                    value={coefficient ? `${coefficient}` : ''}
                    placeholder=''
                    label='Сoefficient of bid'
                    onChange={onHandleCoefficient}
                  />
                  {shouldShowCoefficientError && (
                    <Message
                      type={MessageType.error}
                      text={'Coefficient should be more than 1'}
                    />
                  )}
                </div>

                {isCreator ? (
                  <DateTimeInput onUpdate={(val) => setStartTimestamp(val)} />
                ) : null}
              </>
            )}
            {transactionStep && transactionStep >= 1 && !error && (
              <>
                <ProcessSteps steps={3} current={transactionStep} />
                <ProcessText steps={listingSteps} current={transactionStep} />
                {transactionStep === 1 && (
                  <ProcessProfile
                    image={userData && userData.image}
                    username={userData && userData.username}
                    address={address}
                  />
                )}
                {transactionStep > 1 && <EtherscanLink txHash={txHash} />}
              </>
            )}

            {!transactionStep && (
              <>
                <div className={classes.buttons}>
                  <Button
                    label={'List NFT'}
                    onClick={onHandleList}
                    disabled={
                      !amount || !coefficient || shouldShowCoefficientError
                    }
                  />
                  {!nft.isOpenBox && (
                    <>
                      {!nft.canListOrOpen ? (
                        <Tooltip
                          anchorSelect={`#tooltip-${id}`}
                          content='Open box inactive until all NFT sold.'
                          place='top'
                          className={classes.tooltipStyles}
                        />
                      ) : null}
                      <div id={`tooltip-${id}`}>
                        <Button
                          label={'Open Box'}
                          onClick={() => {
                            if (!nft.canListOrOpen) return;
                            navigate(GET_TARGET_PAGE(INDEX_URLS.OPEN_BOX, id!));
                          }}
                          className={cn({
                            [classes.disabledBtn]: !nft.canListOrOpen,
                          })}
                          // disabled={!nft.canListOrOpen}
                        />
                      </div>
                    </>
                  )}
                </div>
              </>
            )}

            {error && (
              <>
                <InfoText
                  className={classes.bidErrorText}
                  text={error}
                  type='error'
                />
                <Button
                  label={'Try again'}
                  onClick={() => dispatch(clearState())}
                  className={classes.listBtn}
                />
              </>
            )}
            {isCompleted && (
              <div className={classes.buttons}>
                <Button
                  label='Go to main'
                  className={classes.button}
                  onClick={onHandleNavigate}
                />
              </div>
            )}
            {isLoaderVisible && (
              <Loader loading className={classes.loaderSize} />
            )}
          </div>
        </div>
      </Paper>

      <FooterWithButtons
        activeBtnHandler={onHandleList}
        activeBtnLabel='List NFT'
        activeBtnDisabled={!amount || !coefficient}
        // showActiveBtn={!transactionStep}
      />
    </>
  );
};
