export const bidSteps = [
  {
    title: 'Confirm transaction',
    description: 'Confirm this transaction with your wallet to continue.',
  },
  {
    title: 'Your bid has been submitted!',
    description:
      'Your bid is being confirmed on the BNB blockchain. You are free to leave this page if you like.',
  },
  {
    title: 'Your bid has been confirmed!',
    description:
      'Your bid has been successfully confirmed on the BNB blockchain.',
  },
];

export const bidErrors = {
  'Auction does not exist':
    "The auction you're looking for doesn't exist. Please double-check the details or search for another auction.",
  'Auction has been finished':
    'This auction has already ended. Please explore other ongoing auctions.',
  'Low bid amount':
    "Your bid was too low. It's possible that someone placed a higher bid before you. To participate successfully, please increase your bid.",
};
