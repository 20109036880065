import { createAsyncThunk } from '@reduxjs/toolkit';

import { getContract } from 'hooks';
import { logger, web3 } from 'libs';

import { setStep, setTxHash } from './reducer';
import { NETWORK } from 'config';

export const changeReservePrice = createAsyncThunk(
  'changeReservePriceStore/changeReservePrice',
  async ({ nftId }: { nftId: string | number }, { getState, dispatch }) => {
    const { auctionContract, units } = getContract();
    const {
      changeReservePrice: { amount, coefficient },
    }: any = getState();

    try {
      nftId = nftId.toString();

      const changeReservePrice = await auctionContract.changeReservePrice(
        nftId,
        units.parseUnits(amount, 9)
      );

      dispatch(setTxHash(changeReservePrice.hash));
      dispatch(setTxHash('hash'));
      dispatch(setStep(2));

      /*const { transactionHash, logs } = */ await changeReservePrice.wait();
      // const hex = logs[0].topics[3];
      // const tokenId = await web3.hexToNumber(hex);
      // const nft = await auctionContract.getAuctionData(tokenId);
      //
      // console.log('change reserve price tx: ', transactionHash);
      // console.log('change reserve price hex: ', hex);
      // console.log('change reserve price tokenId: ', tokenId);
      // console.log('change reserve price nft: ', nft);

      logger.info(`[ RESERVE PRICE IS CHANGED ]`);

      return {};
    } catch (error: any) {
      console.log({ error });

      return {
        error: error.reason,
      };
    }
  }
);
