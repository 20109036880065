import React from 'react';

export const Twitter: React.FC<React.SVGAttributes<SVGElement>> = (props) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M16.6667 6.27604C16.1806 6.49306 15.6554 6.64062 15.0911 6.71875C15.6814 6.37153 16.0851 5.86372 16.3021 5.19531C15.7378 5.52517 15.1562 5.74653 14.5573 5.85938C14.0278 5.28646 13.3637 5 12.5651 5C11.8099 5 11.1654 5.26693 10.6315 5.80078C10.0977 6.33464 9.83073 6.97917 9.83073 7.73438C9.83073 7.98611 9.85243 8.19444 9.89583 8.35938C8.77604 8.29861 7.72569 8.01649 6.74479 7.51302C5.76389 7.00955 4.93056 6.33681 4.24479 5.49479C3.99306 5.92882 3.86719 6.38889 3.86719 6.875C3.86719 7.86458 4.26215 8.62413 5.05208 9.15365C4.6441 9.14497 4.21007 9.03212 3.75 8.8151V8.84115C3.75 9.49219 3.96701 10.0716 4.40104 10.5794C4.83507 11.0872 5.36892 11.4019 6.0026 11.5234C5.75087 11.5929 5.52951 11.6276 5.33854 11.6276C5.22569 11.6276 5.05642 11.6102 4.83073 11.5755C5.01302 12.1224 5.33637 12.5738 5.80078 12.9297C6.26519 13.2856 6.79253 13.4679 7.38281 13.4766C6.37587 14.2578 5.24306 14.6484 3.98438 14.6484C3.75868 14.6484 3.54167 14.6354 3.33333 14.6094C4.61806 15.4253 6.01562 15.8333 7.52604 15.8333C8.49826 15.8333 9.40972 15.6793 10.2604 15.3711C11.1111 15.0629 11.8403 14.6506 12.4479 14.1341C13.0556 13.6176 13.5786 13.023 14.0169 12.3503C14.4553 11.6775 14.7808 10.9744 14.9935 10.2409C15.2062 9.50738 15.3125 8.77604 15.3125 8.04688C15.3125 7.89062 15.3082 7.77344 15.2995 7.69531C15.8464 7.30469 16.3021 6.8316 16.6667 6.27604ZM20 3.75V16.25C20 17.283 19.6332 18.1662 18.8997 18.8997C18.1662 19.6332 17.283 20 16.25 20H3.75C2.71701 20 1.83377 19.6332 1.10026 18.8997C0.366753 18.1662 0 17.283 0 16.25V3.75C0 2.71701 0.366753 1.83377 1.10026 1.10026C1.83377 0.366753 2.71701 0 3.75 0H16.25C17.283 0 18.1662 0.366753 18.8997 1.10026C19.6332 1.83377 20 2.71701 20 3.75Z'
        fill='#F9F9F9'
        fillOpacity='1'
      />
    </svg>
  );
};
