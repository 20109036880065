import React, { useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'hooks';
import { useGetNftQuery } from 'store/nft';
import {
  changeReservePriceSteps,
  GET_TARGET_PAGE,
  INDEX_URLS,
  URLS,
} from 'config';
import {
  ProductCard,
  InfoText,
  PriceInput,
  LinkToFaq,
  Button,
  Agreement,
  ProcessSteps,
  ProcessText,
  ProcessProfile,
  Loader,
  EtherscanLink,
  Message,
  MessageType,
  Paper,
  StepLabel,
  FooterWithButtons,
  DateTimeInput,
} from 'components/ui';
import {
  setAmount,
  setCoefficient,
  setAgreement,
  clearState,
  changeReservePrice,
} from 'store/change-reserve-price';

import classes from './change-reserve-price.module.scss';
import { ProfileIcon } from 'components/icons';
import { TigerUsd } from 'components/ui/tiger-usd/tiger-usd';
import { getPrices } from 'store/currency';
import { Currency, TCurrency } from 'store/currency/types';

export const ChangeReservePrice = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    step: transactionStep,
    txHash,
    amount,
    coefficient,
    isCompleted,
    error,
    agreement,
  } = useAppSelector((state) => state.changeReservePrice);
  const { userData, address } = useAppSelector((state) => state.profileStore);
  const { prices, isLoading: isPriceLoading } = useAppSelector(
    (state) => state.priceStore
  );

  const { data: nft } = useGetNftQuery(id!, {
    skip: !id,
  });

  const currency: TCurrency = nft.auction.currency;

  useEffect(() => {
    dispatch(getPrices());

    if (!nft) {
      return;
    }

    if (nft.is_owner === false) {
      navigate(URLS.HOME_PAGE);
    }

    return () => {
      dispatch(clearState());
    };
  }, [nft]);

  const onHandleChangePrice = () => {
    if (!nft || !nft.nftId) {
      return;
    }

    dispatch(
      changeReservePrice({
        nftId: nft.nftId,
      })
    );
  };

  const onHandleAmount = (value: string) => {
    dispatch(setAmount(value));
  };

  const onHandleCoefficient = (value: string) => {
    dispatch(setCoefficient(value));
  };

  const onHandleAgree = (checked: boolean) => {
    dispatch(setAgreement(checked));
  };

  const onHandleNavigate = () => {
    // if (!nft || !nft.nftId) {
    //   return;
    // }

    navigate(GET_TARGET_PAGE(INDEX_URLS.NFT, nft.id));
  };

  if (!nft) {
    return null;
  }

  const isLoaderVisible =
    transactionStep !== null && transactionStep !== 3 && !error;

  //начались транзакции это 2 шаг, заполнение форм - 1
  const step = !transactionStep ? 1 : 2;

  return (
    <>
      <Paper className={classes.container}>
        <div className={classes.header}>
          <div className={classes.title}>Change reserve price</div>
          <StepLabel current={step} max={2} />
        </div>

        <div className={classes.grid}>
          <div className={classes.left}>
            <ProductCard
              author={{
                image: nft.author.image,
                username: nft.author.username,
              }}
              image={nft.image}
              name={nft.name}
              isOwner={false}
              withActions={false}
              className={classes.cardWidth}
              auction={nft?.auction}
              price={nft?.price}
            />
          </div>
          <div className={classes.right}>
            {!transactionStep && (
              <>
                <div className={classes.input}>
                  <PriceInput
                    value={amount ? `${amount}` : ''}
                    placeholder={`Reserve price in ${currency}`}
                    label={`Reserve price in ${currency}`}
                    onChange={onHandleAmount}
                  />
                  <div className={classes.usd}>
                    <TigerUsd
                      isPriceLoading={isPriceLoading}
                      tigerPrice={+prices[currency]}
                      value={Number(amount)}
                    />
                  </div>
                </div>
                {/* <PriceInput
                  value={coefficient ? `${coefficient}` : ''}
                  placeholder='Сoefficient of bit'
                  label='Сoefficient of bit'
                  onChange={onHandleCoefficient}
                /> */}
                {/* <PriceInput
                  value={amount ? `${amount}` : ''}
                  placeholder='11.05.23 15:00'
                  label='Start in:'
                  onChange={onHandleAmount}
                /> */}
                {/* <DateTimeInput /> */}
              </>
            )}

            {error && (
              <>
                <InfoText text={error} type='error' />
                <Button
                  label={'Try again'}
                  onClick={() => {
                    dispatch(clearState());
                  }}
                />
              </>
            )}

            {transactionStep && transactionStep >= 1 && !error && (
              <>
                <ProcessSteps steps={3} current={transactionStep} />
                <ProcessText
                  steps={changeReservePriceSteps}
                  current={transactionStep}
                />
                {transactionStep === 1 && (
                  <ProcessProfile
                    image={userData && userData.image}
                    username={userData && userData.username}
                    address={address}
                  />
                )}
                {transactionStep > 1 && <EtherscanLink txHash={txHash} />}
                {transactionStep === 3 && (
                  <div className={classes.buttonsFlex}>
                    <Button
                      label='View NFT'
                      disabled={!amount || !agreement}
                      onClick={onHandleNavigate}
                      size='small'
                    />

                    <Link to={`/profile/${address}`} className={classes.link}>
                      <Button
                        size='small'
                        variant='outlined'
                        label='Go to my profile'
                        icon={<ProfileIcon />}
                      />
                    </Link>
                  </div>
                )}
              </>
            )}

            {!transactionStep && (
              <div className={classes.buttons}>
                <Button
                  label={'Change reserve'}
                  onClick={onHandleChangePrice}
                  disabled={!amount}
                />
              </div>
            )}

            {isLoaderVisible && (
              <Loader loading className={classes.loaderSize} />
            )}
          </div>
        </div>
      </Paper>

      <FooterWithButtons
        activeBtnHandler={onHandleChangePrice}
        activeBtnLabel='Change reserve'
        activeBtnDisabled={!amount}
        // showActiveBtn={!transactionStep}
      />
    </>
  );
};
