export const getDesktopRows = (data: any[]) => {
  return data.reduce((acc, item, index, input) => {
    if (!acc.length) {
      acc.push([]);
    }

    const arr = acc[acc.length - 1];
    const arrIndex = acc.findIndex((item: any) => item === arr);
    const prevArr = acc[arrIndex - 1];

    const itemsLeft = input.length - (index + 1);
    //Если предыдущий с 1 эл
    if (!prevArr || prevArr?.length === 1) {
      arr.push(item);
      //Если уже набили 1 строку 3-мя эл-ми и есть еще создаем новый подмассив
      if (arr.length === 3 && itemsLeft > 0) {
        acc.push([]);
      }
    }
    if (prevArr?.length === 3) {
      arr.push(item);
      //Если уже набили 2 строку 2-мя эл-ми и есть еще создаем новый подмассив
      if (arr.length === 2 && itemsLeft > 0) {
        acc.push([]);
      }
    }
    if (prevArr?.length === 2) {
      arr.push(item);
      //Если уже набили 3 строку 1-м эл-м и есть еще создаем новый подмассив
      if (arr.length === 1 && itemsLeft > 1) {
        acc.push([]);
      }
    }

    return acc;
  }, []);
};

export const getTabletRows = (data: any[]) => {
  return data.reduce((acc, item, index, input) => {
    if (!acc.length) {
      acc.push([]);
    }

    const arr = acc[acc.length - 1];
    const arrIndex = acc.findIndex((item: any) => item === arr);
    const prevArr = acc[arrIndex - 1];

    const itemsLeft = input.length - (index + 1);
    //Если предыдущий с 1 эл
    if (!prevArr || prevArr?.length === 2) {
      arr.push(item);
      //Если уже набили 1 строку 3-мя эл-ми и есть еще создаем новый подмассив
      if (arr.length === 1 && itemsLeft > 0) {
        acc.push([]);
      }
    }
    if (prevArr?.length === 1) {
      arr.push(item);
      //Если уже набили 2 строку 2-мя эл-ми и есть еще создаем новый подмассив
      if (arr.length === 2 && itemsLeft > 0) {
        acc.push([]);
      }
    }

    if (!itemsLeft) {
      const array = acc[acc.length - 1];
      const prevArray = acc[acc.length - 2];
      if (array.length === prevArray?.length) {
        prevArray.push(array[0]);
        acc.pop();
      }
    }

    return acc;
  }, []);
};

export const getMobileRows = (data: any[]) => {
  return data.map((item: any) => [item]);
};
