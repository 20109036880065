import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getAuthors, subscribe, unSubscribe } from './actions';
import { IState, IAuthor } from './interface';

const initialState: IState = {
  authors: [],
  count: 0,
  isLoading: true,
  isSubscribeProcess: false,
};

export const authors = createSlice({
  name: 'authorsStore',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAuthors.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAuthors.fulfilled, (state, action: PayloadAction<any>) => {
        const { results = [] } = action.payload;
        state.count = action.payload.count;
        state.authors = results;
        state.isLoading = false;
      })
      .addCase(getAuthors.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(subscribe.pending, (state) => {
        state.isSubscribeProcess = true;
      })
      .addCase(subscribe.fulfilled, (state, action: PayloadAction<any>) => {
        const author = action.payload;

        if (author) {
          state.authors = state.authors.map((item) => ({
            ...item,
            is_following: item.id === author ? true : item.is_following,
          }));
        }

        state.isSubscribeProcess = false;
      })
      .addCase(subscribe.rejected, (state) => {
        state.isSubscribeProcess = false;
      })
      .addCase(unSubscribe.pending, (state) => {
        state.isSubscribeProcess = true;
      })
      .addCase(unSubscribe.fulfilled, (state, action: PayloadAction<any>) => {
        const author = action.payload;

        if (author) {
          state.authors = state.authors.map((item) => ({
            ...item,
            is_following: item.id === author ? false : item.is_following,
          }));
        }

        state.isSubscribeProcess = false;
      })
      .addCase(unSubscribe.rejected, (state) => {
        state.isSubscribeProcess = false;
      });
  },
});

export const {} = authors.actions;
