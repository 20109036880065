import React from 'react';

import {
  NFTFilterSearch,
  NFTFilterSearchResult,
  NFTFilterGenres,
  NFTFilterStatus,
} from 'components/containers';

import classes from './nft-filter.module.scss';

export const NFTFilter = () => {
  return (
    <>
      <NFTFilterSearch />
      <div className={classes.panel}>
        <NFTFilterSearchResult />
        <div className={classes.panel__control}>
          <NFTFilterGenres />
          <NFTFilterStatus />
        </div>
      </div>
      {/* <NFTFilterTags /> */}
    </>
  );
};
