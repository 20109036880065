import moment from 'moment';

interface IFormatted {
  date: Date | moment.Moment | string | undefined | null;
  format: string | undefined
}

export const formatted = ({ date, format }: IFormatted): string | null => {
  if (!date || !format) {
    return null;
  }

  return moment(date).format(format)
}
