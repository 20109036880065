import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useQuery = () => {
  const [ searchParams ] = useSearchParams();

  return useMemo(() => {
    const result: any = {};

    searchParams.forEach((value, key) => {
      result[key] = value;
    });

    return result;
  }, [ searchParams ]);
}
