import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuctionCard, Loader, Info } from 'components/ui';
import { useAppDispatch, useAppSelector, useQuery } from 'hooks';
import { getAuctions, clearState } from 'store/auction';
import { URLS, GET_TARGET_PAGE, INDEX_URLS } from 'config';

import classes from './auction-list.module.scss';
//TODO REMOVE MOCK
import { getPrices } from 'store/currency';
import { ITEMS_PER_PAGE } from 'config/pagination';
import { Pagination } from 'components/ui/pagination/pagination';

export const AuctionList = () => {
  const navigate = useNavigate();
  const params = useQuery();
  const dispatch = useAppDispatch();
  const { isLoading, auctions, count } = useAppSelector(
    (state) => state.auctionStore
  );
  const [itemOffset, setItemOffset] = useState(0);
  const [selectedPage, setSelectedPage] = useState(0);
  const pageCount = Math.ceil(count / ITEMS_PER_PAGE);
  const endOffset = itemOffset + ITEMS_PER_PAGE;
  const { address } = useAppSelector((state) => state.profileStore);
  const isAuth = !!address;

  useEffect(() => {
    params.limit = ITEMS_PER_PAGE;
    params.offset = itemOffset;
    dispatch(getPrices());

    if (!address) {
      return;
    }
    dispatch(getAuctions(params));

    let timerId = setInterval(() => {
      dispatch(getAuctions(params));
    }, 10000);

    return () => {
      clearInterval(timerId);
      dispatch(clearState());
    };
  }, [address, itemOffset]);

  const onAction = (action: string, nftId: string | number) => {
    if (action === 'bid') {
      return navigate(GET_TARGET_PAGE(INDEX_URLS.PLACE_BID, nftId));
    }

    if (action === 'view') {
      return navigate(GET_TARGET_PAGE(INDEX_URLS.NFT, nftId));
    }

    if (action === 'unlist') {
      return navigate(GET_TARGET_PAGE(INDEX_URLS.UNLIST, nftId));
    }

    if (action === 'claim') {
      return navigate(GET_TARGET_PAGE(INDEX_URLS.CLAIM, nftId));
    }
  };

  const handlePageClick = (event: any) => {
    setSelectedPage(event.selected);
    const newOffset = (event.selected * ITEMS_PER_PAGE) % count;
    setItemOffset(newOffset);
  };

  if (!address && !isLoading && !auctions.length) {
    return (
      <div className={classes.info_container}>
        <Info
          title='You are not logged in!'
          text='In order to see your auctions you need to connect your wallet.'
        />
      </div>
    );
  }

  return (
    <Loader loading={isLoading && auctions.length === 0}>
      <Pagination
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        forcePage={selectedPage}
        pageCount={pageCount}
      />
      {!auctions.length && !isLoading && (
        <div className={classes.info_container}>
          <Info
            title='Your auctions will be shown here'
            text='When you place a bid on an NFT, it will show up here.'
            buttonText='Explore NFT'
            buttonLink={URLS.NFT_PAGE}
          />
        </div>
      )}
      {auctions.length > 0 && (
        <div className={classes.page__list}>
          {auctions.map((item, index) => (
            <AuctionCard
              isAuth={isAuth}
              key={index}
              onAction={onAction}
              {...item}
            />
          ))}
        </div>
      )}
      <Pagination
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        forcePage={selectedPage}
        pageCount={pageCount}
      />
    </Loader>
  );
};
