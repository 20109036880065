export const unlistingSteps = [
  {
    title: 'Unlist this NFT',
    description:
      'By unlisting your NFT you remove it from sale and return it to your wallet. It will still be shown on your profile. In case you want to remove it, just burn your NFT.',
  },
  {
    title: 'Transaction confirmation',
    description:
      'Confirm this transaction to proceed. Your NFT will be unlisted for auction and returned to your wallet.',
  },
  {
    title: 'Your NFT will soon be unlisted',
    description:
      'Your NFT will get back to your wallet from our smart contract shortly. It will no longer be available for auction. However, it will still be shown on your profile.',
  },
  {
    title: 'Your NFT is unlisted',
    description:
      'Your NFT is no longer listed in sale. You can find it in your wallet.',
  },
];
