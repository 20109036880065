import image from './../assets/img/img.png';
import tigerImage from './../assets/img/tiger.png';

export const address = '0x61dd481a114a2e761c554b641742c973867899d3';

export const userData = {
  id: 1,
  wallet: '0x61Dd481A114A2E761c554B641742C973867899D3',
  is_following: true,
  bio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit eget magnis diam magna id non elementum. Nulla in ut sociis vel, convallis sapien, et urna id.',
  image: image,
  username: 'ownername',
  twitter: 'Твиттер',
  is_creator: false,
  cover_image: tigerImage,
  date_joined: '27.05.1829',
  followers: [1],
  following: [1],
  first_name: 'Owner name',
  instagram: 'Инста',
  meta: '',
};

export const profileData = {
  id: 1,
  wallet: '0x61Dd481A114A2E761c554B641742C973867899D3',
  is_following: true,
  bio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit eget magnis diam magna id non elementum. Nulla in ut sociis vel, convallis sapien, et urna id.',
  image: image,
  username: 'ownername',
  twitter: 'Твиттер',
  is_creator: false,
  cover_image: tigerImage,
  date_joined: '27.05.1829',
  followers: [1],
  following: [1],
  first_name: 'Owner name',
  instagram: 'Инста',
  meta: '',
};
