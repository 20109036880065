import React from 'react';

import { TIconWrapper } from './declarations';

export const CoinbaseIcon: React.FC<TIconWrapper> = ({ size = 32, className }) => {
  return(
    <svg
      width={ size }
      height={ size }
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M16.0009 2.66675C8.63733 2.66675 2.66754 8.63654 2.66754 16.0001C2.66754 23.3636 8.63733 29.3334 16.0009 29.3334C23.3644 29.3334 29.3342 23.3642 29.3342 16.0001C29.3342 8.63602 23.3644 2.66675 16.0009 2.66675ZM16.0009 23.8397C14.4504 23.8397 12.9347 23.3799 11.6454 22.5185C10.3562 21.657 9.35141 20.4327 8.75805 19.0002C8.16469 17.5677 8.00944 15.9914 8.31193 14.4707C8.61442 12.9499 9.36107 11.553 10.4575 10.4567C11.5538 9.36028 12.9507 8.61363 14.4715 8.31114C15.9922 8.00864 17.5685 8.16389 19.001 8.75725C20.4335 9.35061 21.6578 10.3554 22.5193 11.6446C23.3807 12.9339 23.8405 14.4496 23.8405 16.0001C23.8405 18.0793 23.0145 20.0733 21.5443 21.5435C20.0741 23.0137 18.0801 23.8397 16.0009 23.8397Z'
        fill='#0D5AF5'/>
      <path
        d='M17.8881 13.4888H14.1152C13.77 13.4888 13.4902 13.7686 13.4902 14.1138V17.8867C13.4902 18.2319 13.77 18.5117 14.1152 18.5117H17.8881C18.2333 18.5117 18.5131 18.2319 18.5131 17.8867V14.1138C18.5131 13.7686 18.2333 13.4888 17.8881 13.4888Z'
        fill='#0D5AF5'/>
    </svg>
  );
}
