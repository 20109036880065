import React, { useState, useEffect } from 'react';
import Cropper from 'react-easy-crop';

import {
  Button,
  Loader
} from 'components/ui';
import { fileManager } from 'libs';

import { IProps, IArea } from './image-crop.interface';
import classes from './image-crop.module.scss';

const cropStyle = {
  square: {
    minWidth: 150,
    minHeight: 150
  },
  rectangle: {
    minWidth: '100%',
    minHeight: 150
  }
}

export const ImageCrop = (props: IProps) => {
  const {
    img,
    onCancel,
    onSuccessCrop,
    onFailedCrop,
    open,
    aspectType = 'square'
  } = props;
  const [ isLoading, setLoading ] = useState(true);
  const [ crop, setCrop ] = useState({ x: 0, y: 0 });
  const [ zoom, setZoom ] = useState(1);
  const [ croppedAreaPixels, setCroppedAreaPixels ] = useState<IArea | null>(null);

  useEffect(() => {
    if (img) {
      setLoading(false);
    }
  }, [ img ]);

  const onCropComplete = (_: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const onHandleError = (message: string) => {
    setLoading(false);
    onFailedCrop(message);
  }

  const onCrop = async () => {
    setLoading(true);
    if (!img && !croppedAreaPixels) {
      return onHandleError(`We haven't any image to crop.`);
    }

    const { image, error } = await fileManager.getCroppedImage({
      image: img!,
      area: croppedAreaPixels!
    });

    if (error) {
      return onHandleError(error);
    }

    if (!image) {
      return onHandleError(`We can't crop image.`)
    }

    setLoading(false);
    onSuccessCrop(image);
  }

  if (!open) {
    return null;
  }

  return (
    <div className={ classes.cropper }>
      <Loader loading={ isLoading } className={ classes.loader }>
        <div className={ classes.square } />
      </Loader>
      {
        img && !isLoading &&
        (
          <div className={ classes.crop_wrapper } >
            <div className={ classes.crop_content }>
              <Cropper
                image={ img }
                crop={ crop }
                zoom={ zoom }
                aspect={ aspectType === 'rectangle' ? 9/3 : 1 }
                onCropChange={ setCrop }
                onCropComplete={ onCropComplete }
                onZoomChange={ setZoom }
                objectFit='contain'
                style={{
                  cropAreaStyle: cropStyle[aspectType]
                }}
                maxZoom={ 10 }
                restrictPosition={ true }
              />
            </div>
            <div className={ classes.crop_menu }>
              <Button label='Cancel' size='small' onClick={ onCancel } />
              <Button label='OK' size='small' onClick={ onCrop } />
            </div>
          </div>
        )
      }
    </div>
  );
};
