export const setGenres = (genres: any[], onSelect: (e: any) => any) => {
  return genres.map(({ name }) => ({
    label: name,
    onClick: () => {
      onSelect({
        genre: name
      });
    }
  }));
}
