import { initializeConnector } from '@web3-react/core';
import { WalletConnect } from '@web3-react/walletconnect-v2';
import { NETWORK } from 'config';

import { Connection, ConnectionType, onConnectionError } from './connections';
import { CHAIN_TO_URL_MAP } from './constants';

export function buildWalletConnectConnector() {
  const [web3WalletConnect, web3WalletConnectHooks] =
    initializeConnector<WalletConnect>(
      (actions) =>
        new WalletConnect({
          actions,
          options: {
            rpcMap: CHAIN_TO_URL_MAP,
            showQrModal: true,
            projectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID || '',
            chains: [NETWORK.chainId],
          },
          onError: onConnectionError,
        })
    );
  const walletConnectConnection: Connection = {
    connector: web3WalletConnect,
    hooks: web3WalletConnectHooks,
    type: ConnectionType.WALLET_CONNECT,
  };
  return walletConnectConnection;
}
