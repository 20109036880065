import React from 'react';

export const MessageError: React.FC<React.SVGAttributes<SVGElement>> = (
  props
) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='#CD3B1B'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M5.7 14.3C5.88333 14.4833 6.11667 14.575 6.4 14.575C6.68333 14.575 6.91667 14.4833 7.1 14.3L10 11.4L12.925 14.325C13.1083 14.5083 13.3377 14.5957 13.613 14.587C13.8877 14.579 14.1167 14.4833 14.3 14.3C14.4833 14.1167 14.575 13.8833 14.575 13.6C14.575 13.3167 14.4833 13.0833 14.3 12.9L11.4 10L14.325 7.075C14.5083 6.89167 14.5957 6.66233 14.587 6.387C14.579 6.11233 14.4833 5.88333 14.3 5.7C14.1167 5.51667 13.8833 5.425 13.6 5.425C13.3167 5.425 13.0833 5.51667 12.9 5.7L10 8.6L7.075 5.675C6.89167 5.49167 6.66267 5.404 6.388 5.412C6.11267 5.42067 5.88333 5.51667 5.7 5.7C5.51667 5.88333 5.425 6.11667 5.425 6.4C5.425 6.68333 5.51667 6.91667 5.7 7.1L8.6 10L5.675 12.925C5.49167 13.1083 5.40433 13.3373 5.413 13.612C5.421 13.8873 5.51667 14.1167 5.7 14.3ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6873 3.825 17.975 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.262667 12.6833 0 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31267 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.31233 6.1 0.787C7.31667 0.262333 8.61667 0 10 0C11.3833 0 12.6833 0.262333 13.9 0.787C15.1167 1.31233 16.175 2.025 17.075 2.925C17.975 3.825 18.6873 4.88333 19.212 6.1C19.7373 7.31667 20 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6873 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6873 13.9 19.212C12.6833 19.7373 11.3833 20 10 20Z'
        fill='inherit'
      />
    </svg>
  );
};
