import { ICompressProps, ICropParams, ICropResponse } from './file.interface';

export const compressImage = async (props: ICompressProps): Promise<string | null> => {
  const { file, type = 'image/jpeg', quality = 0.3 } = props;

  return new Promise(resolve => {
    try {
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d')!;

        canvas.width = img.width;
        canvas.height = img.height;

        ctx.drawImage(img, 0, 0);

        const image = canvas.toDataURL(type, quality);

        resolve(image);
      }

    } catch (error) {
      console.log('[ IMAGX ERROR ]', error);

      resolve(null);
    }
  });
}

export const getFileSize = async (image: File | Blob): Promise<string> => {
  return ( image.size / (1024 * 1024) ).toFixed(2);
}

export const createImage = async (src: string): Promise<HTMLImageElement | null> => {
  return new Promise((resolve) => {
    const image = new Image();
    image.src = src;

    image.addEventListener('load', () => {
      resolve(image);
    });
    image.addEventListener('error', (error) => {
      console.log('create image error: ', error);

      resolve(null);
    });
    image.setAttribute('crossOrigin', 'anonymous');
  });
}

export const getRadianAngle = (degreeValue: number): number => {
  return (degreeValue * Math.PI) / 180;
}

export const rotateSize = (width: number, height: number, rotRad: number) => {
  return({
    width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  });
}

export const getCroppedImage = async (params: ICropParams): Promise<ICropResponse> => {
  const {
    image,
    area,
    rotation = 0,
    flip = { vertical: false, horizontal: false }
  } = params;

  return new Promise(async resolve => {
    try {
      const _image = await createImage(image);
      const _canvas = document.createElement('canvas');
      const _ctx = _canvas.getContext('2d');

      if (!_image || !_ctx) {
        return resolve({
          image: null,
          error: `We can't create crop image. May be you should update your browser.`
        });
      }

      const rotRad = getRadianAngle(rotation);
      const { width: bBoxWidth, height: bBoxHeight } = rotateSize( _image.width, _image.height, rotRad );

      _canvas.width = bBoxWidth;
      _canvas.height = bBoxHeight;

      _ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
      _ctx.rotate(rotRad);
      _ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
      _ctx.translate(-_image.width / 2, -_image.height / 2);
      _ctx.drawImage(_image, 0, 0);

      const data = _ctx.getImageData(
        Math.round(area.x),
        Math.round(area.y),
        Math.round(area.width),
        Math.round(area.height)
      );

      _canvas.width = area.width;
      _canvas.height = area.height;

      _ctx.putImageData(data, 0, 0);

      _canvas.toBlob(async (file: Blob | null) => {
        if (!file) {
          return resolve({
            image: null,
            error: `We can't build output image. Try later.`
          });
        }

        resolve({
          image: file
        });
      }, 'image/jpeg', 0.7);
    } catch (error: any) {
      return resolve({
        image: null,
        error: `Cropper error: ${ error.message }`
      });
    }
  });
}
