import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ProfileCard as PCard } from 'components/ui';
import { IFollowingData } from 'components/ui/author-card/author-card.interface';
import {
  At,
  Instagram,
  Twitter,
  Facebook,
  DiscordIcon,
  YoutubeIcon,
  TikTokIcon,
  TwitchIcon,
  SnapchatIcon,
} from 'components/icons';
import { useAppSelector, useAppDispatch } from 'hooks';
import { URLS } from 'config';
import { getUserByWallet, clearProfileData } from 'store/profile';

import classes from './profile-card.module.scss';
import { subscribe, unSubscribe } from 'store/authors';
import { setOpen } from 'store/follows';
import { FollowsModal } from '../follows-modal/follows-modal';
import { SOCIAL_LINKS } from 'config/social-links';
import { getUserSocialLinks } from 'libs/utils/utils';

const ICONS = {
  website: At,
  instagram: Instagram,
  twitter: Twitter,
  facebook: Facebook,
  discord: DiscordIcon,
  youtube: YoutubeIcon,
  tiktok: TikTokIcon,
  twitch: TwitchIcon,
  snapchat: SnapchatIcon,
};

export const ProfileCard = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const {
    profileData,
    userChanges,
    address,
    // showPreview,
    userData = {},
    isLoading
  } = useAppSelector((state) => state.profileStore);
  const { isSubscribeProcess } = useAppSelector((state) => state.authorsStore);
  const user = Object.assign(
    {
      ...profileData,
    },
    { ...userChanges }
    // showPreview &&
    // { ...userData, ...userChanges }
  );


  useEffect(() => {
    const { address } = params;

    if (!address) {
      return;
    }

    dispatch(clearProfileData());
    dispatch(getUserByWallet(address));
  }, [params]);

  const onHandleSubscribe = async ({
    action,
    authorId,
    wallet,
  }: IFollowingData) => {
    if (!userData.id || !action || !authorId) {
      return;
    }

    if (action === 'add') {
      dispatch(
        subscribe({
          follower: userData.id,
          author: authorId,
          wallet,
        })
      );
    }

    if (action === 'delete') {
      dispatch(
        unSubscribe({
          follower: userData.id,
          author: authorId,
          wallet,
        })
      );
    }
  };

  const handlerBecomeAnArtist = () => {
    if (address === profileData?.wallet) {
      navigate(URLS.PROFILE_BECOME_ARTIST);
    }
  };

  const handleOpenModalFoloows = () => {
    dispatch(setOpen(true));
  };

  const getFollows = () => {
    let followers = user?.followers ? user?.followers?.length : 0;
    let following = user?.following ? user?.following?.length : 0;

    if (address === profileData?.wallet) {
      followers = userData?.followers ? userData?.followers?.length : 0;
      following = userData?.following ? userData?.following?.length : 0;
    }

    return {
      followers: followers,
      following: following,
    };
  };

  return (
    <div className={classes.container}>
      <PCard
        onBecomeAnArtist={handlerBecomeAnArtist}
        isCreator={user && user.is_creator}
        authorWallet={user.wallet}
        authorId={user.id}
        isFollowing={
          userData.following ? userData.following.includes(user.wallet) : false
        }
        onHandleSubscribe={onHandleSubscribe}
        subscribeProcess={isSubscribeProcess || !userData.id}
        loading={!Object.keys(user).length || isLoading}
        image={profileData && profileData.image}
        name={user.first_name}
        userName={user.username}
        followers={getFollows().followers}
        isOwner={address === profileData?.wallet}
        address={user.wallet || ''}
        following={getFollows().following}
        bio={user.bio}
        links={getUserSocialLinks(user.meta, SOCIAL_LINKS, ICONS)}
        joined={user.date_joined ? new Date(user.date_joined) : null}
        className={classes.profileCard__card}
        onClickEdit={() => {
          navigate(URLS.PROFILE_EDIT_PAGE);
        }}
        isEditHidden={false}
        onOpenModalFoloows={handleOpenModalFoloows}
      />

      {address === profileData?.wallet && <FollowsModal />}
    </div>
  );
};
