import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_METHODS, API_DOMAIN } from 'config';

export interface IGenre {
  id: number;
  description: string;
  name: string;
  updated_at: string;
  upload: string;
  created_at: string;
}

export const typesApi = createApi({
  reducerPath: 'typesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_DOMAIN,
    credentials: 'include',
  }),
  endpoints: (build) => ({
    getTypes: build.query<IGenre[], string>({
      query: () => API_METHODS.GET_TYPES,
    }),
  }),
});

export const { useGetTypesQuery } = typesApi;
