import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Loader, ProductCard } from 'components/ui';
import { GET_TARGET_PAGE, INDEX_URLS } from 'config';
import { useQuery, useAppDispatch, useAppSelector } from 'hooks';
import { getNftList } from 'store/nft-list';

import classes from './nft-list.module.scss';
import { Pagination } from 'components/ui/pagination/pagination';
import { ITEMS_PER_PAGE } from 'config/pagination';
import { scrollToY } from 'libs';

type Props = {
  max?: number;
};

export const NFTList = (props: Props) => {
  const { max } = props;
  const params = useQuery();
  const dispatch = useAppDispatch();

  const { list, isLoading, count } = useAppSelector(
    (state) => state.nftListStore
  );
  const [itemOffset, setItemOffset] = useState(0);
  const [selectedPage, setSelectedPage] = useState(0);

  const endOffset = itemOffset + ITEMS_PER_PAGE;
  const pageCount = Math.ceil(count / ITEMS_PER_PAGE);
  const handlePageClick = (event: any) => {
    setSelectedPage(event.selected);
    const newOffset = (event.selected * ITEMS_PER_PAGE) % count;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    params.limit = ITEMS_PER_PAGE;
    params.offset = itemOffset;

    dispatch(getNftList(params));
  }, [params, itemOffset]);

  return (
    <Loader loading={isLoading}>
      <Pagination
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        forcePage={selectedPage}
        pageCount={pageCount}
      />
      <div className={classes.page__list}>
        {list.map((item: any, idx) => {
          if (max && idx >= max) return;

          return (
            <div key={item.nftId} className={classes.item}>
              <Link
                to={GET_TARGET_PAGE(INDEX_URLS.NFT, item.id)}
                style={{ textDecoration: 'none' }}
              >
                <ProductCard
                  nftId={item.nftId}
                  author={item.author}
                  image={item.image}
                  name={item.name}
                  price={item.price}
                  status={item.status}
                  isOwner={item.is_owner}
                  withActions={false}
                  auction={item.auction}
                  withStatus
                />
              </Link>
            </div>
          );
        })}
      </div>
      <Pagination
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        forcePage={selectedPage}
        pageCount={pageCount}
      />
    </Loader>
  );
};
