import React /*, { useEffect } */ from 'react';
import cn from 'classnames';

import classes from './page-layout.module.scss';
import { BackButton } from '../back-button/back-button';

interface Props {
  title?: string;
  description?: string;
  children?: React.ReactNode;
  className?: string;
  backButton?: boolean;
  backButtonText?: string;
}

// TODO build title on all pages

export const PageLayout: React.FC<Props> = ({
  children,
  title,
  description,
  className,
  backButton,
  backButtonText,
}) => {
  /*useEffect(() => {
    document.title = `${ process.env.REACT_APP_NAME } | ${ title }`;
  }, []);*/

  return (
    <div className={cn(classes.page, className)}>
      {backButton && (
        <div className={classes.page__back_wrapper}>
          <BackButton text={backButtonText} />
        </div>
      )}

      <div className={classes.page__title}>{title}</div>

      {description && (
        <div className={classes.page__description}>{description}</div>
      )}

      {children}
    </div>
  );
};
