import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import classes from './wrapper.module.scss';
import { scrollToTop } from 'libs';

interface Props {
  children: React.ReactNode;
}

export const Wrapper: React.FC<Props> = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    scrollToTop();
  }, [pathname]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.app}>{children}</div>
    </div>
  );
};
