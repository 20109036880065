import React, { useState } from 'react';

import { FileUpload } from 'components/ui';
import { uploadProfileImg } from 'store/profile';

import { IProps } from './upload.interface';

export const Upload: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const {
    onHandleChange,
    image,
    aspectType,
    name,
    maxFileSize,
    isChanged,
    message,
  } = props;

  const onChange: any = async (file: File | Blob, key: string) => {
    setLoading(true);
    const formData = new FormData();

    formData.append('image', file as Blob);

    const data = await uploadProfileImg({ formData });

    if (!data) {
      return setLoading(false);
    }
    const { image } = data;

    onHandleChange({
      [key]: image,
    });

    setLoading(false);
  };

  const onDelete = (key: string) => {
    onHandleChange({
      [key]: null,
    });
  };

  return (
    <FileUpload
      id={name}
      maxFileSize={maxFileSize}
      aspectType={aspectType}
      onChange={onChange}
      isLoading={loading}
      image={image}
      onDelete={onDelete}
      isChanged={isChanged}
      message={message}
    />
  );
};
