import React from 'react';

export const UploadPic: React.FC<React.SVGAttributes<SVGElement>> = (props) => {
  return (
    <svg
      width='72'
      height='72'
      viewBox='0 0 72 72'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect width='72' height='72' rx='4' fill='#D3D2D4' />
      <mask
        id='mask0_790_5047'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='16'
        y='16'
        width='40'
        height='40'
      >
        <rect x='16' y='16' width='40' height='40' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_790_5047)'>
        <path
          d='M27.0417 44.6563H43.7083C44.0417 44.6563 44.2917 44.5036 44.4583 44.198C44.625 43.8924 44.5972 43.6008 44.375 43.323L39.7917 37.198C39.625 36.9758 39.4028 36.8647 39.125 36.8647C38.8472 36.8647 38.625 36.9758 38.4583 37.198L34.125 42.9897L31.0417 38.8647C30.875 38.6424 30.6528 38.5313 30.375 38.5313C30.0972 38.5313 29.875 38.6424 29.7083 38.8647L26.375 43.323C26.1528 43.6008 26.125 43.8924 26.2917 44.198C26.4583 44.5036 26.7083 44.6563 27.0417 44.6563ZM23.7083 51.323C22.7917 51.323 22.0067 50.9969 21.3533 50.3447C20.7011 49.6913 20.375 48.9063 20.375 47.9897V24.6563C20.375 23.7397 20.7011 22.9547 21.3533 22.3013C22.0067 21.6491 22.7917 21.323 23.7083 21.323H47.0417C47.9583 21.323 48.7433 21.6491 49.3967 22.3013C50.0489 22.9547 50.375 23.7397 50.375 24.6563V47.9897C50.375 48.9063 50.0489 49.6913 49.3967 50.3447C48.7433 50.9969 47.9583 51.323 47.0417 51.323H23.7083Z'
          fill='white'
        />
      </g>
    </svg>
  );
};
