import React from 'react';

import { Input } from 'components/ui';
// import { utils } from 'libs';

interface IProps {
  value: string | null;
  onChange: (value: string) => void;
  className?: any;
  label: string;
  placeholder: string;
  errorMessage?: string;
  // balance: number | null;
}

export const PriceInput = ({
  value,
  onChange,
  /*balance,*/ label,
  placeholder,
  ...props
}: IProps) => {
  const onHandleChange = ({ target }: any) => {
    const _value = target.value;
    const _check = /^[0-9]+[\.]{0,1}[0-9]{0,4}$/.test(_value);

    if ((!_check && _value) || _value === value) {
      return;
    }

    onChange(_value);
  };

  return (
    <Input
      onChange={onHandleChange}
      value={value ? `${value}` : ''}
      name='price'
      label={label}
      placeholder={
        /*`Your balance ${ utils.roundBalance(balance) } ETH`*/ placeholder
      }
      autoComplete='off'
      {...props}
    />
  );
};
