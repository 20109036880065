import React from 'react';
import classNames from 'classnames';

import styles from './nav-tab.module.scss';

export interface NavTabProps {
  active?: boolean;
  label: string;
  icon?: JSX.Element;
  onClick?: () => void
}

export const NavTab = ({ active, label, icon, onClick }: NavTabProps) => {
  const handleClick = () => {
    onClick && onClick()
  }

  return (
    <span className={classNames(styles.NavTab, active && styles['active'])} onClick={handleClick}>
      {icon}
      {label}
    </span>
  );
};
