import React from 'react';

export const ProfileIcon = () => {
  return (
    <svg
      width='17'
      height='17'
      viewBox='0 0 17 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.01074 8.53778C6.91074 8.53778 5.96908 8.14611 5.18574 7.36278C4.40241 6.57945 4.01074 5.63778 4.01074 4.53778C4.01074 3.43778 4.40241 2.49611 5.18574 1.71278C5.96908 0.929448 6.91074 0.537781 8.01074 0.537781C9.11074 0.537781 10.0524 0.929448 10.8357 1.71278C11.6191 2.49611 12.0107 3.43778 12.0107 4.53778C12.0107 5.63778 11.6191 6.57945 10.8357 7.36278C10.0524 8.14611 9.11074 8.53778 8.01074 8.53778ZM2.01074 16.5378C1.46074 16.5378 0.990075 16.3421 0.598742 15.9508C0.206742 15.5588 0.0107422 15.0878 0.0107422 14.5378V13.7378C0.0107422 13.1711 0.156742 12.6501 0.448742 12.1748C0.740076 11.7001 1.12741 11.3378 1.61074 11.0878C2.64408 10.5711 3.69408 10.1834 4.76074 9.92478C5.82741 9.66678 6.91074 9.53778 8.01074 9.53778C9.11074 9.53778 10.1941 9.66678 11.2607 9.92478C12.3274 10.1834 13.3774 10.5711 14.4107 11.0878C14.8941 11.3378 15.2814 11.7001 15.5727 12.1748C15.8647 12.6501 16.0107 13.1711 16.0107 13.7378V14.5378C16.0107 15.0878 15.8151 15.5588 15.4237 15.9508C15.0317 16.3421 14.5607 16.5378 14.0107 16.5378H2.01074Z'
        fill='white'
      />
    </svg>
  );
};
