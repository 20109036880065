import React from 'react';

export const Instagram: React.FC<React.SVGAttributes<SVGElement>> = (props) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M13.2 10C13.2 10.6329 13.0123 11.2516 12.6607 11.7778C12.3091 12.3041 11.8093 12.7142 11.2246 12.9564C10.6399 13.1986 9.99645 13.262 9.37571 13.1385C8.75497 13.015 8.18479 12.7103 7.73726 12.2627C7.28973 11.8152 6.98496 11.245 6.86149 10.6243C6.73801 10.0035 6.80138 9.36014 7.04359 8.77541C7.28579 8.19069 7.69594 7.69092 8.22218 7.3393C8.74841 6.98768 9.3671 6.8 10 6.8C10.8479 6.80264 11.6603 7.14063 12.2598 7.74017C12.8594 8.33972 13.1974 9.15212 13.2 10ZM20 5.6V14.4C20 15.8852 19.41 17.3096 18.3598 18.3598C17.3096 19.41 15.8852 20 14.4 20H5.6C4.11479 20 2.69041 19.41 1.6402 18.3598C0.589998 17.3096 0 15.8852 0 14.4V5.6C0 4.11479 0.589998 2.69041 1.6402 1.6402C2.69041 0.589998 4.11479 0 5.6 0H14.4C15.8852 0 17.3096 0.589998 18.3598 1.6402C19.41 2.69041 20 4.11479 20 5.6ZM14.8 10C14.8 9.05065 14.5185 8.12262 13.9911 7.33326C13.4636 6.54391 12.714 5.92868 11.8369 5.56538C10.9598 5.20208 9.99468 5.10702 9.06357 5.29223C8.13246 5.47744 7.27718 5.9346 6.60589 6.60589C5.9346 7.27718 5.47744 8.13246 5.29223 9.06357C5.10702 9.99468 5.20208 10.9598 5.56538 11.8369C5.92868 12.714 6.54391 13.4636 7.33326 13.9911C8.12262 14.5185 9.05065 14.8 10 14.8C11.273 14.8 12.4939 14.2943 13.3941 13.3941C14.2943 12.4939 14.8 11.273 14.8 10ZM16.4 4.8C16.4 4.56266 16.3296 4.33066 16.1978 4.13332C16.0659 3.93598 15.8785 3.78217 15.6592 3.69134C15.4399 3.60052 15.1987 3.57676 14.9659 3.62306C14.7331 3.66936 14.5193 3.78365 14.3515 3.95147C14.1837 4.11929 14.0694 4.33311 14.0231 4.56589C13.9768 4.79867 14.0005 5.03995 14.0913 5.25922C14.1822 5.47849 14.336 5.66591 14.5333 5.79776C14.7307 5.92962 14.9627 6 15.2 6C15.5183 6 15.8235 5.87357 16.0485 5.64853C16.2736 5.42348 16.4 5.11826 16.4 4.8Z'
        fill='#F9F9F9'
        fillOpacity='1'
      />
    </svg>
  );
};
