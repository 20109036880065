import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from 'components/ui';
import { INDEX_URLS, GET_TARGET_PAGE } from 'config';

import classes from './process-finished-buttons.module.scss';

interface IProps {
  nftId: string | number;
  address: string;
}

export const ProcessFinishedButtons = ({ nftId, address }: IProps) => {
  const navigate = useNavigate();

  const onHandleProfileLink = () => {
    navigate(GET_TARGET_PAGE(INDEX_URLS.PROFILE, address));
  }

  const onHandleNFTLink = () => {
    navigate(GET_TARGET_PAGE(INDEX_URLS.NFT, nftId));
  }

  return(
    <div className={ classes.container }>
      <Button
        type='primary'
        label='View NFT'
        size='small'
        className={ classes.button }
        onClick={ onHandleNFTLink }
      />
      <Button
        type='secondary'
        variant='outlined'
        size='small'
        label='Go to my profile'
        className={ classes.button }
        onClick={ onHandleProfileLink }
      />
    </div>
  );
}
