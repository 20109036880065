import React from 'react';

export const CopyIcon: React.FC<React.SVGAttributes<SVGElement>> = (props) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='#000'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <mask
        id='mask0_318_17063'
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='24'
        height='24'
      >
        <rect width='24' height='24' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_318_17063)' fill='inherit'>
        <path
          d='M9.01074 17.5376C8.46074 17.5376 7.99008 17.3419 7.59874 16.9506C7.20674 16.5586 7.01074 16.0876 7.01074 15.5376V3.5376C7.01074 2.9876 7.20674 2.5166 7.59874 2.1246C7.99008 1.73326 8.46074 1.5376 9.01074 1.5376H18.0107C18.5607 1.5376 19.0317 1.73326 19.4237 2.1246C19.8151 2.5166 20.0107 2.9876 20.0107 3.5376V15.5376C20.0107 16.0876 19.8151 16.5586 19.4237 16.9506C19.0317 17.3419 18.5607 17.5376 18.0107 17.5376H9.01074ZM5.01074 21.5376C4.46074 21.5376 3.98974 21.3419 3.59774 20.9506C3.20641 20.5586 3.01074 20.0876 3.01074 19.5376V6.5376C3.01074 6.25426 3.10674 6.0166 3.29874 5.8246C3.49008 5.63326 3.72741 5.5376 4.01074 5.5376C4.29408 5.5376 4.53174 5.63326 4.72374 5.8246C4.91508 6.0166 5.01074 6.25426 5.01074 6.5376V19.5376H15.0107C15.2941 19.5376 15.5317 19.6336 15.7237 19.8256C15.9151 20.0169 16.0107 20.2543 16.0107 20.5376C16.0107 20.8209 15.9151 21.0583 15.7237 21.2496C15.5317 21.4416 15.2941 21.5376 15.0107 21.5376H5.01074Z'
          fill='#F9F9F9'
        />
      </g>
    </svg>
  );
};
