import { isDev } from 'constants/constants';

interface INetwork {
  chainId: number;
  url: string | undefined;
  scan: string;
  nftAddress?: string;
  auctionAddress?: string;
  coinAddress?: string;
}

const networkName: string | undefined = process.env.REACT_APP_RPC_NETWORK_NAME;
const networkChainId: string | undefined =
  process.env.REACT_APP_RPC_NETWORK_CHAIN_ID;
const infuraProjectId: string | undefined =
  process.env.REACT_APP_INFURA_PROJECT_ID;
const nftAddress: string | undefined = process.env.REACT_APP_NFT_ADDRESS;
const auctionAddress: string | undefined =
  process.env.REACT_APP_AUCTION_ADDRESS;
const coinAddress: string | undefined = process.env.REACT_APP_COIN_ADDRESS;

export const POLLING_INTERVAL = 5000;
export const LAST_CONNECT_KEY = 'LAST_CONNECT';
export const CACHED_WALLET_KEY = 'CACHED_WALLET';

export const WALLETS = {
  metamask: 'metamask',
  walletconnect: 'walletconnect',
  coinbase: 'coinbase',
};

const NETWORKS = {
  bnb: {
    url: `https://mainnet.infura.io/v3/${infuraProjectId}`,
    scan: `https://bscscan.com`,
  },
  bnbTestnet: {
    url: `https://goerli.infura.io/v3/${infuraProjectId}`,
    scan: `https://testnet.bscscan.com/`,
  },
};

export const NETWORK: INetwork = {
  chainId: +networkChainId!,
  nftAddress,
  auctionAddress,
  coinAddress,
  ...NETWORKS[networkName as keyof typeof NETWORKS],
};

export const SIGN_MESSAGES = {
  connection: `Please sign this message to connect to ${process.env.REACT_APP_NAME}.`,
};

// export const paramChain = [
//   {
//     chainId: '0x61', //97 in hex
//     chainName: 'BNB Smart Chain - Testnet',
//     nativeCurrency: {
//       name: 'TBinance',
//       symbol: 'tBNB', // 2-6 characters long
//       decimals: 18,
//     },
//     blockExplorerUrls: ['https://testnet.bscscan.com'],
//     rpcUrls: ['https://bsc-testnet.publicnode.com'],
//   },
// ];

//prod
// : [
//     {
//       chainId: '0x38', //56 in hex
//       chainName: 'BNB Smart Chain Mainnet',
//       nativeCurrency: {
//         name: 'Binance',
//         symbol: 'BNB', // 2-6 characters long
//         decimals: 18,
//       },
//       blockExplorerUrls: ['https://bscscan.com/'],
//       rpcUrls: ['https://testnet.publicnode.com'],
//     },
//   ];
