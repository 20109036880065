export const ShareIcon = () => {
  return (
    <svg
      width='16'
      height='22'
      viewBox='0 0 16 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8 14.9998C7.71667 14.9998 7.47933 14.9038 7.288 14.7118C7.096 14.5205 7 14.2831 7 13.9998V3.8248L6.1 4.7248C5.91667 4.90814 5.68767 4.9998 5.413 4.9998C5.13767 4.9998 4.9 4.8998 4.7 4.6998C4.51667 4.4998 4.425 4.26214 4.425 3.9868C4.425 3.71214 4.51667 3.48314 4.7 3.2998L7.3 0.699805C7.38333 0.616471 7.48767 0.549805 7.613 0.499805C7.73767 0.449805 7.86667 0.424805 8 0.424805C8.13333 0.424805 8.26267 0.449805 8.388 0.499805C8.51267 0.549805 8.61667 0.616471 8.7 0.699805L11.3 3.2998C11.5 3.4998 11.6 3.74147 11.6 4.0248C11.6 4.30814 11.5 4.54147 11.3 4.7248C11.1 4.90814 10.8627 4.9998 10.588 4.9998C10.3127 4.9998 10.0833 4.90814 9.9 4.7248L9 3.8248V13.9998C9 14.2831 8.90433 14.5205 8.713 14.7118C8.521 14.9038 8.28333 14.9998 8 14.9998ZM2 21.9998C1.45 21.9998 0.979333 21.8041 0.588 21.4128C0.196 21.0208 0 20.5498 0 19.9998V8.9998C0 8.4498 0.196 7.9788 0.588 7.5868C0.979333 7.19547 1.45 6.9998 2 6.9998H4C4.28333 6.9998 4.521 7.09547 4.713 7.2868C4.90433 7.4788 5 7.71647 5 7.9998C5 8.28314 4.90433 8.52047 4.713 8.7118C4.521 8.9038 4.28333 8.9998 4 8.9998H2V19.9998H14V8.9998H12C11.7167 8.9998 11.4793 8.9038 11.288 8.7118C11.096 8.52047 11 8.28314 11 7.9998C11 7.71647 11.096 7.4788 11.288 7.2868C11.4793 7.09547 11.7167 6.9998 12 6.9998H14C14.55 6.9998 15.021 7.19547 15.413 7.5868C15.8043 7.9788 16 8.4498 16 8.9998V19.9998C16 20.5498 15.8043 21.0208 15.413 21.4128C15.021 21.8041 14.55 21.9998 14 21.9998H2Z'
        fill='#f9f9f9'
      />
    </svg>
  );
};
