import React from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch } from 'hooks';

import { Button, FooterWithButtons, Paper, ProductCard } from 'components/ui';
import { setHashLoaded } from 'store/nft-manager';

import classes from './unlist-nft.module.scss';
import { useGetNftQuery } from 'store/nft';
import { Content } from './content/content';
import { INft } from 'store/nft/interface';
import { clearState } from 'store/burn';

export const UnlistNFT = () => {
  const dispatch = useAppDispatch();
  const [skip, setSkip] = React.useState(true);
  const { id } = useParams();

  React.useEffect(() => {
    if (id) {
      setSkip(false);
    }
    return () => {
      dispatch(clearState());
    };
  }, [id]);

  const { data } = useGetNftQuery(id || '', {
    skip,
  });

  const {
    image: nftImage,
    author: { image, username } = {} as any,
    name: nftName,
  } = (data || {}) as any;

  return (
    <>
      <Paper className={classes.container}>
        <div className={classes.left}>
          <ProductCard
            author={{
              image: image,
              username: username,
            }}
            image={nftImage}
            name={nftName}
            setImageLoaded={() => dispatch(setHashLoaded())}
            className={classes.productCard}
            isOwner={false}
            withActions={false}
            price={data?.price}
            auction={data?.auction}
          />
        </div>
        <div className={classes.right}>
          <Content nft={data} />
        </div>
      </Paper>

      <FooterWithButtons />
    </>
  );
};
