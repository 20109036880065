import React from 'react';

export const ArrowUpload: React.FC<React.SVGAttributes<SVGElement>> = (props) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      { ...props }
    >
      <path
        d='M8 12C7.71667 12 7.47933 11.904 7.288 11.712C7.096 11.5207 7 11.2834 7 11V3.85005L5.125 5.72505C4.925 5.92505 4.69167 6.02505 4.425 6.02505C4.15833 6.02505 3.91667 5.91672 3.7 5.70005C3.5 5.50005 3.40433 5.26238 3.413 4.98705C3.421 4.71238 3.51667 4.48338 3.7 4.30005L7.3 0.700048C7.4 0.600048 7.50833 0.529049 7.625 0.487049C7.74167 0.445715 7.86667 0.425049 8 0.425049C8.13333 0.425049 8.25833 0.445715 8.375 0.487049C8.49167 0.529049 8.6 0.600048 8.7 0.700048L12.3 4.30005C12.5 4.50005 12.5957 4.73738 12.587 5.01205C12.579 5.28738 12.4833 5.51672 12.3 5.70005C12.1 5.90005 11.8627 6.00405 11.588 6.01205C11.3127 6.02072 11.075 5.92505 10.875 5.72505L9 3.85005V11C9 11.2834 8.90433 11.5207 8.713 11.712C8.521 11.904 8.28333 12 8 12ZM2 16C1.45 16 0.979333 15.8044 0.588 15.413C0.196 15.021 0 14.55 0 14V12C0 11.7167 0.0956668 11.479 0.287 11.287C0.479 11.0957 0.716667 11 1 11C1.28333 11 1.521 11.0957 1.713 11.287C1.90433 11.479 2 11.7167 2 12V14H14V12C14 11.7167 14.096 11.479 14.288 11.287C14.4793 11.0957 14.7167 11 15 11C15.2833 11 15.5207 11.0957 15.712 11.287C15.904 11.479 16 11.7167 16 12V14C16 14.55 15.8043 15.021 15.413 15.413C15.021 15.8044 14.55 16 14 16H2Z'
        fill='#D3D2D4'
      />
    </svg>
  );
};
