import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Search } from 'components/ui';
import { useDebounce } from 'hooks';
import { FILTER_PARAMS } from 'config';

import classes from './nft-filter-search.module.scss';

export const NFTFilterSearch: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState('');
  const debounced = useDebounce(search);

  useEffect(() => {
    if (!debounced || debounced.length < 3) {
      searchParams.delete(FILTER_PARAMS.SEARCH);
    } else {
      searchParams.set(FILTER_PARAMS.SEARCH, debounced);
    }

    setSearchParams(searchParams);
  }, [debounced]);

  return (
    <div className={classes.page__search}>
      <Search onChange={setSearch} value={search} />
    </div>
  );
};
