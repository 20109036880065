export const claimingSteps = [
  {
    title: 'Settle auction',
    description: 'The auction is over. You can now claim...',
  },
  {
    title: 'Transaction confirmation',
    description:
      'Confirm this transaction to proceed. The auction will be settled after confirmation.',
  },
  {
    title: 'The auction will soon be settled',
    description:
      'This auction is currently being settled on the BNB blockchain. It may take up to...',
  },
  {
    title: 'You just earned it!',
    description:
      'The auction has successfully been settled on the BNB blockchain. You can find ETH in your wallet.',
  },
];
