import React from 'react';

import classes from './process-text.module.scss';

interface IStep {
  title: string;
  description: string;
}

interface IProps {
  current: number | null;
  steps: IStep[];
}

export const ProcessText = ({ current, steps }: IProps) => {
  if (!current) {
    return null;
  }

  const step = steps[current - 1];
  const title = step.title;
  const description = step.description;

  return (
    <div className={classes.container}>
      <div className={classes.title}>{title}</div>
      <div className={classes.description}>{description}</div>
    </div>
  );
};
