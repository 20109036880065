import { createAsyncThunk } from '@reduxjs/toolkit';
import { NETWORK } from 'config';

import { getContract } from 'hooks';
import { logger } from 'libs';

import { setStep, setTxHash } from './reducer';
import { Currency } from 'store/currency/types';
import { formatAmountTo } from 'helpers';

export const placeBid = createAsyncThunk(
  'bidStore/placeBid',
  async ({ nftId, currency }: any, { getState, dispatch }) => {
    const { auctionContract, coinContract, units } = getContract();

    try {
      const {
        bidStore: { amount },
      }: any = getState();
      let bid;
      if (currency === Currency.BabyBNBTiger) {
        const _approved = await coinContract.approve(
          NETWORK.auctionAddress,
          units.parseUnits(amount, 9)
        );

        await _approved.wait();

        console.log('BID : nftId', nftId);
        console.log(
          'BID: units.parseUnits(amount)',
          formatAmountTo(amount, currency)
        );
        bid = await auctionContract.bid(
          nftId,
          formatAmountTo(amount, currency)
        );
      } else if (currency === Currency.BNB) {
        bid = await auctionContract.bidWithCoin(nftId, {
          value: formatAmountTo(amount, currency),
        });
      }
      console.log('create bid: ', bid);

      dispatch(setTxHash(bid.hash));
      dispatch(setStep(2));

      await bid.wait();

      logger.info(`[ BID IS DONE ]`);

      return {};
    } catch (error: any) {
      console.error(error);
      return {
        error: error.reason,
      };
    }
  }
);
