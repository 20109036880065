import React from 'react';

import { NFTFileUploader, IFile } from 'components/containers';
import { useAppSelector, useAppDispatch } from 'hooks';
import { setFile } from 'store/nft-manager';

export const UploadImage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { file } = useAppSelector((state) => state.NFTManagerStore);

  const onFileUploadHandler = (file: IFile | null) => {
    if (!file) {
      return;
    }

    dispatch(setFile(file));
  };

  const onFileUploadError = (text: string) => {};

  const onRemoveUploadFile = () => {
    dispatch(setFile(null));
  };

  return (
    <NFTFileUploader
      file={file}
      onUploaded={onFileUploadHandler}
      onError={onFileUploadError}
      onRemove={onRemoveUploadFile}
      mimeTypes='.jpg, .jpeg, .png'
      maxFileSize={50}
    />
  );
};
