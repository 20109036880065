import React from 'react';

export const TikTokIcon = () => {
  return (
    <svg 
			width="20" 
			height="20" 
			viewBox="0 0 20 20" 
			fill="none" 
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="20" height="20" rx="4" fill="#221E1E"/>
			<path d="M13.8335 4.85C13.2638 4.19969 12.9499 3.36453 12.9501 2.5H10.3751V12.8333C10.3553 13.3925 10.1192 13.9222 9.7166 14.3108C9.31401 14.6994 8.77634 14.9166 8.2168 14.9167C7.03346 14.9167 6.05013 13.95 6.05013 12.75C6.05013 11.3167 7.43346 10.2417 8.85846 10.6833V8.05C5.98346 7.66667 3.4668 9.9 3.4668 12.75C3.4668 15.525 5.7668 17.5 8.20846 17.5C10.8251 17.5 12.9501 15.375 12.9501 12.75V7.50833C13.9943 8.25821 15.2479 8.66054 16.5335 8.65833V6.08333C16.5335 6.08333 14.9668 6.15833 13.8335 4.85Z" fill="white"/>
		</svg>
	)
};
