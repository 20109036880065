import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import { ProfileCardMenu, Loader, Button, Timer } from 'components/ui';
import { NftStatuses, INDEX_URLS, GET_TARGET_PAGE } from 'config';
import { useContract } from 'hooks';

import styles from './product-card.module.scss';
import { BidIcon, Check } from 'components/icons';
import { CheckCircle } from 'components/icons/check_circle';
import { formatAmount } from 'helpers';
import defaultBoxImg from './../../../assets/img/temp-box2.png';
import { TCurrency } from 'store/currency/types';
import { IAuctionData } from 'store/auction';

interface IPropsMeta {
  IPFSHash: string | null;
  fullPath: string | null;
  name?: string;
  genre?: string;
  genreId?: number;
  description?: string;
}

export interface IProps {
  id?: number | string;
  nftId?: number | string;
  isOwner?: boolean;
  isMinted?: boolean;
  image: string | null;
  name?: string;
  author: Author;
  price?: string | null;
  status?: NftStatuses;
  setImageLoaded?: () => void;
  className?: string;
  type?: 'nfts' | 'collected';
  withActions: boolean;
  withStatus?: boolean;
  onHandleAction?: (url: string) => void;
  auction?: IAuction | IAuctionData | null;
  IPFSHash?: string | null;
  genre?: string;
  genreId?: number;
  description?: string;
  onHandlerAddDescription?: (obj: IPropsMeta) => void;
  canListOrOpen?: boolean;
  isOpenBox?: boolean;
  isCreator?: boolean;
  buttonsBottom?: boolean;
}

interface IAuction {
  status: boolean;
  endTimestamp: number;
  currency: TCurrency;
  //todo wtf
  lastBet: number;
}

interface Author {
  username: string;
  image: string;
}

export const ProductCard = ({
  id,
  nftId,
  image,
  name,
  author,
  price,
  setImageLoaded,
  className,
  isOwner,
  status,
  withActions,
  withStatus,
  onHandleAction,
  auction,
  IPFSHash,
  genre,
  genreId,
  description,
  isMinted,
  isOpenBox,
  canListOrOpen,
  onHandlerAddDescription,
  buttonsBottom,
  isCreator,
}: IProps) => {
  const { utils: etherUtils } = useContract();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isImageLoad, setLoading] = useState(true);

  const onLoading = () => {
    setLoading(false);

    if (setImageLoaded) {
      setImageLoaded();
    }
  };

  const onActionHandler = (url: string) => {
    if (onHandleAction && id) {
      onHandleAction(GET_TARGET_PAGE(url, id));
    }

    if (onHandleAction && status === 'draft' && id) {
      onHandleAction(GET_TARGET_PAGE(url, id));
    }

    return;
  };

  const onHandleRedirect = () => {
    if (!id) {
      return;
    }

    navigate(GET_TARGET_PAGE(INDEX_URLS.NFT, id));
  };

  const handlerAddDescription = () => {
    if (!onHandlerAddDescription) {
      return;
    }

    onHandlerAddDescription({
      IPFSHash: IPFSHash ? IPFSHash : '',
      fullPath: image,
      name: name,
      genre: genre,
      genreId: genreId,
      description: description,
    });
  };

  useEffect(() => {
    if (!image) {
      return;
    }

    const _image = new Image();

    _image.src = image;
    _image.addEventListener('load', onLoading);

    return () => {
      _image.removeEventListener('load', onLoading);
    };
  }, [image]);

  // const showActions =
  //   withActions && isOwner && status && status !== NftStatuses.claim;

  //если нет первой ставки берем из price, потом уже из lastBet
  const showPrice = auction?.lastBet || price || 0;

  return (
    <div className={cn(styles.CardWrapper, className)}>
      <div className={cn(styles.Card)} onClick={onHandleRedirect}>
        <Loader loading={isImageLoad} className={styles.imgWrapper}>
          <img src={image || ''} alt='' />
        </Loader>
        <div className={styles.flexRow}>
          <div className={styles.Name}>
            <div className={styles.NameText}>{name}</div>
            {/* {+showPrice !== 0 ? ( */}
            <div className={styles.SubnameText}>
              {formatAmount(+showPrice, auction?.currency)} <br />{' '}
              {auction?.currency}
            </div>
            {/* ) : null} */}
          </div>
          {/* при status === NftStatuses.sale, isOwner - адрес контракта   */}
          {/* по этому такое условие    */}
          {withActions && (isOwner || status === NftStatuses.sale) && (
            <ProfileCardMenu
              canListOrOpen={Boolean(canListOrOpen)}
              open={open}
              setOpen={(bool: boolean) => setOpen(bool)}
              onSetAction={onActionHandler}
              id={nftId}
              auctionBided={Boolean(auction?.lastBet)}
              auctionStarted={auction?.status}
              status={status}
              isOpenBox={isOpenBox}
            />
          )}
        </div>
      </div>
      {withStatus && (
        <div
          className={cn(styles.statusText, {
            [styles.statusTextGreen]: status === NftStatuses.sale,
            [styles.statusTextGold]:
              status !== NftStatuses.sale && auction?.lastBet && !isOwner,
            [styles.statusTextNone]:
              (status === NftStatuses.claim && isOwner) ||
              (status === NftStatuses.ready && isOwner && buttonsBottom), //когда claim и isOwner - скрывать блок
          })}
        >
          {status === NftStatuses.sale && <CheckCircle />}
          {status !== NftStatuses.sale && auction?.lastBet && !isOwner && (
            <BidIcon />
          )}

          {status !== NftStatuses.sale &&
            auction?.lastBet &&
            !isOwner &&
            'SOLD'}
          {status === NftStatuses.sale && 'ON SALE'}

          {!auction?.lastBet &&
            (status === NftStatuses.ready || status === NftStatuses.claim) &&
            'NOT ON SALE'}
          {auction?.lastBet &&
            status === NftStatuses.ready &&
            isOwner &&
            !buttonsBottom &&
            'COLLECTED'}
        </div>
      )}
      {buttonsBottom && isOwner && (
        <>
          {status === NftStatuses.ready && (canListOrOpen || isCreator) && (
            <div className={styles.Actions}>
              <Button
                label='List NFT'
                size='small'
                full
                onClick={() => onActionHandler(INDEX_URLS.LIST)}
              />
            </div>
          )}
          {status === NftStatuses.claim && auction && (
            <div className={styles.Actions}>
              <Button
                label='Claim NFT'
                size='small'
                full
                onClick={() => onActionHandler(INDEX_URLS.CLAIM)}
              />
            </div>
          )}
          {/* {status === NftStatuses.draft && (
            <Button
              label='Mint NFT'
              size='small'
              full
              onClick={handlerAddDescription}
            />
          )} */}
        </>
      )}
      {/* TODO RAZOBRATSYA KOGDA SHOW TEXT */}
    </div>
  );
};
