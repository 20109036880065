import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import { Status } from 'store/nft/interface';
import { ProfileNftTypes } from 'store/nft-list';

import { IProps } from './interface';
import classes from './tabs.module.scss';

export const Tabs: React.FC<IProps> = (props) => {
  const {
    items = [],
    onChange,
    disabled = false,
    isOwner = false,
    isCreator = false,
  } = props;

  const activeIndex = () => {
    if (isCreator && isOwner) return 0;

    return 1;
  };
  const [activeTab, setActiveTab] = useState<ProfileNftTypes>(
    items[activeIndex()].state
  );

  const handleClick = (state: ProfileNftTypes) => {
    if (disabled) {
      return;
    }

    setActiveTab(state);
  };

  useEffect(() => {
    if (disabled) {
      return;
    }

    onChange(activeTab);
  }, [activeTab]);

  return (
    <div className={classes.tabs}>
      {items.map(({ label, state }) => {
        if (state === Status.draft && (!isOwner || !isCreator)) {
          return null;
        }

        if (!isCreator && state === Status.created) {
          return null;
        }

        return (
          <div
            key={state}
            className={classNames(
              classes.tab,
              state === activeTab ? classes.active : '',
              disabled && classes.disabled
            )}
            onClick={() => handleClick(state)}
          >
            {label}
          </div>
        );
      })}
    </div>
  );
};
