import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IState, INft } from './interface';
import { burn as burnActions } from './actions';
import { DEFAULT_TX_ERROR_TEXT, transactionErrors } from 'config';

const initialState: IState = {
  step: null,
  error: null,
  txHash: null,
  isLoading: false,
  isBurnt: false,
};

export const burnSlice = createSlice({
  name: 'burn',
  initialState,
  reducers: {
    clearState: () => initialState,
    setStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    setTxHash: (state, action: PayloadAction<string>) => {
      state.txHash = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      burnActions.fulfilled,
      (state, action: PayloadAction<any>) => {
        if (action.payload.error) {
          state.isBurnt = false;
          state.step = 0;
          Object.keys(transactionErrors).forEach((i) => {
            if (!action.payload.error.includes(i)) {
              return (state.error = DEFAULT_TX_ERROR_TEXT);
            } else {
              return (state.error =
                transactionErrors[i as keyof typeof transactionErrors]);
            }
          });
        } else {
          state.isBurnt = true;
          state.step = 4;
        }
      }
    );
  },
});

export const { clearState, setStep, setTxHash } = burnSlice.actions;
