import React from 'react';
import logo from './../../../assets/img/logo.png';

import classes from './light-horizontal.module.scss';

export const LogoLightHorizontal: React.FC<
  React.SVGAttributes<SVGElement>
> = () => {
  return (
    <div className={classes.wrapper}>
      <img src={logo} alt='Logo' />
      <span>BABYBNBTIGER</span>
    </div>
  );
};
