import React, { useState } from 'react';
import classes from 'components/containers/nft-history/nft-history.module.scss';
import { Arrow } from 'components/icons';
import cn from 'classnames';
import styles from 'components/ui/drop-down/drop-down.module.scss';

type Props = {
  title: string;
  children: any;
  defaultOpen?: boolean;
};

export const Collapse = ({ title, children, defaultOpen = false }: Props) => {
  const [open, setOpen] = useState(defaultOpen);
  return (
    <div className={classes.accordion}>
      <div className={classes.accordion__header} onClick={() => setOpen(!open)}>
        {title}
        <Arrow className={cn(styles.ArrowDown, open && classes.rotate)} />
      </div>
      <div
        className={cn(
          classes.accordion__content,
          open && classes.accordion__active
        )}
      >
        {children}
      </div>
    </div>
  );
};
