import React from 'react';

import { NETWORK } from 'config';
import { Redirect } from 'components/icons';

import classes from './etherscan-link.module.scss';

interface IProps {
  txHash: string | null;
}

export const EtherscanLink = ({ txHash }: IProps) => {
  if (!txHash) {
    return null;
  }

  return (
    <a
      href={`${NETWORK.scan}/tx/${txHash}`}
      className={classes.link}
      target='_blank'
    >
      <Redirect fill='#8101eb' />
      View on Bscscan
    </a>
  );
};
