//component in progress
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { Input } from '../input/input';
import cl from './date-input.module.scss';

type Props = {
  onUpdate: (v: number) => void;
};

export const DateTimeInput = ({ onUpdate }: Props) => {
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [hours, setHours] = useState('');
  const [minutes, setMinutes] = useState('');

  useEffect(() => {
    const inputDate = `${month}.${day}.${year} ${hours}:${minutes}`;
    // Создаем объект Date с учетом временной зоны CET
    const dateWithCET = new Date(inputDate + ' GMT+0000');
    // Получаем время в формате Unix timestamp (в миллисекундах)
    const unixTimestamp = dateWithCET.getTime();
    // Преобразуем в секунды, разделив на 1000 и округлив вниз
    const unixTimestampInSeconds = Math.floor(unixTimestamp / 1000);
    // return unixTimestampInSeconds;
    onUpdate(unixTimestampInSeconds);
  }, [day, month, year, hours, minutes]);

  const handleUpdateTime = (
    type: 'day' | 'month' | 'year' | 'hour' | 'minute',
    value: string
  ) => {
    if (type === 'day') {
      if (value.length > 2) return;
      setDay(value);
    }
    if (type === 'month') {
      if (value.length > 2) return;
      setMonth(value);
    }
    if (type === 'year') {
      if (value.length > 4) return;
      setYear(value);
    }
    if (type === 'hour') {
      if (value.length > 2) return;
      setHours(value);
    }
    if (type === 'minute') {
      if (value.length > 2) return;
      setMinutes(value);
    }
  };

  return (
    <div>
      <div className={cl.label}>Start at:</div>
      <div className={cl.wrap}>
        <input
          type='text'
          value={day}
          onChange={(e) => {
            handleUpdateTime('day', e.target.value);
          }}
          placeholder='DD'
          className={cn(cl.input, cl.inputSm)}
          style={{ minWidth: 35, maxWidth: 35 }}
        />
        <div className={cl.separator}>.</div>
        <input
          type='text'
          value={month}
          onChange={(e) => {
            handleUpdateTime('month', e.target.value);
          }}
          placeholder='MM'
          className={cn(cl.input, cl.inputSm)}
          style={{ minWidth: 40, maxWidth: 40 }}
        />
        <div className={cl.separator}>.</div>
        <input
          type='text'
          value={year}
          onChange={(e) => {
            handleUpdateTime('year', e.target.value);
          }}
          placeholder='YYYY'
          className={cn(cl.input, cl.inputMd)}
        />

        <input
          type='text'
          value={hours}
          onChange={(e) => {
            handleUpdateTime('hour', e.target.value);
          }}
          placeholder='00'
          className={cn(cl.input, cl.inputSm)}
        />
        <div className={cl.separator}>:</div>
        <input
          type='text'
          value={minutes}
          onChange={(e) => handleUpdateTime('minute', e.target.value)}
          placeholder='00'
          className={cn(cl.input, cl.inputSm)}
        />
      </div>
    </div>
  );
};
