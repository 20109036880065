import React, { useState } from 'react';
import cn from 'classnames';
import { Link, useNavigate } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import {
  Burger,
  CloseIcon,
  InfoIcon,
  LeftChevron,
  ExitIcon,
  ProfileIcon,
} from 'components/icons';
import { Button, DropDown, NavTab } from 'components/ui';
import { ConnectButton, ReportButton } from 'components/containers';
import {
  HEADER_URLS,
  INDEX_URLS,
  GET_TARGET_PAGE,
  URLS,
  CACHED_WALLET_KEY,
  LAST_CONNECT_KEY,
} from 'config';
import { useAppSelector, useAppDispatch } from 'hooks';
import {
  ConnectionType,
  getConnection,
  getFromLocalStorage,
  removeFromLocalStorage,
  tryDeactivateConnector,
  utils,
} from 'libs';
import { clearProfile } from 'store/profile';

import classes from './burger-menu.module.scss';

export const BurgerMenu: React.FC = () => {
  const navigation = useNavigate();
  const dispatch = useAppDispatch();

  const [isBurgerMenu, setIsBurgerMenu] = useState(false);
  const [isSubMenu, setIsSubMenu] = useState(false);
  const { address, userData = {} } = useAppSelector(
    (state) => state.profileStore
  );

  const onHandleClick = () => {
    setIsSubMenu(false);
    setIsBurgerMenu(false);
  };

  const goToProfile = () => {
    if (!address) {
      return;
    }

    navigation(GET_TARGET_PAGE(INDEX_URLS.PROFILE, address));
    onHandleClick();
  };

  const goToCreateNFT = () => {
    navigation(URLS.CREATE_NFT_PAGE);
    onHandleClick();
  };

  const onLogout = async () => {
    const deactivation = await tryDeactivateConnector(
      getConnection(getFromLocalStorage(LAST_CONNECT_KEY).connector).connector
    );
    // undefined means the deactivation failed
    if (deactivation === undefined) {
      return;
    }
    removeFromLocalStorage(LAST_CONNECT_KEY);
    dispatch(clearProfile());
    setIsSubMenu(false);
    return;
  };

  return (
    <div className={classes.header__burger}>
      {isBurgerMenu ? (
        <CloseIcon
          size={30}
          onClick={() => {
            setIsBurgerMenu(false);
            setIsSubMenu(false);
          }}
        />
      ) : (
        <Burger
          onClick={() => {
            setIsBurgerMenu(true);
          }}
        />
      )}
      <div
        className={cn(
          classes.header__burger_menu,
          isBurgerMenu
            ? classes.header__burger_open
            : classes.header__burger_close
        )}
      >
        <div className={classes.header__burger_tabs}>
          <div>
            {HEADER_URLS.map(({ url, label }) => (
              <Link
                to={url}
                key={url}
                className={classes.header__link}
                onClick={onHandleClick}
              >
                <NavTab active={false} label={label} />
              </Link>
            ))}
          </div>
        </div>
        {isSubMenu && (
          <div className={classes.header__burger_submenu}>
            <div
              onClick={() => setIsSubMenu(false)}
              className={cn(classes.header__burger_submenu_item, classes.back)}
            >
              <LeftChevron />
              Back
            </div>

            <div
              className={classes.header__burger_submenu_item}
              onClick={goToProfile}
            >
              <ProfileIcon />
              View your profile
            </div>

            <div
              className={classes.header__burger_submenu_item}
              onClick={onLogout}
            >
              <ExitIcon />
              Disconnect wallet
            </div>

            {/* {userData.is_creator && (
              <div
                className={classes.header__burger_submenu_item}
                onClick={goToCreateNFT}
              >
                <ExitIcon />
                Create NFT
              </div>
            )} */}

            <div className={classes.create__nft_warning}>
              <InfoIcon />
              Create NFT is available on desktop <br /> only
            </div>
          </div>
        )}
        <div className={classes.header__burger_connect}>
          <div className={classes.header__burger_action_wrapper}>
            <Button
              label='Get BABYBNBTIGER'
              size='small'
              variant='outlined'
              className={classes.get__button}
            />
            {!address && <ConnectButton size='small' full={false} />}
            {address && isBurgerMenu && (
              <div onClick={() => setIsSubMenu(true)}>
                <DropDown
                  open={false}
                  setOpen={(_) => {}}
                  label={utils.shorter(address)}
                  image={userData && userData.image}
                  options={[]}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
